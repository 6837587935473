import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from "react-animate-on-scroll";
import {Container, Row, Col} from "react-bootstrap";
import "./Gallery.scss";
import NewsCategoryList from "./NewsCategoryList"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import DateFormat from "../../format-date"
import {CaseStudyBaseURL} from '../../utils'
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform";
import { isMobile } from "react-device-detect";

const CASESTUDY_LIST=  gql`
query caseStuidesListing {   
    caseStuides (sort:"Case_Study_Date:desc") {
    id
    Title
    URL
    Tile_Image {
      url
      alternativeText
    }
    Case_Study_Date
    imagetransforms
  } 
}
`;
const Gallery = (props) => {
  const { loading, error, data } = useQuery(CASESTUDY_LIST);
  if (loading) return <p className="realtime_preloading">
  <div className="logo-loader">
    <span>Loading ...</span>
  </div>
</p>;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }; 

  //const refLink = useRef(null);
  //const [list,setList] = useState(GalleryItem);
  
  return (
    <React.Fragment>
    <section className="news-listing-wrapper gallery-blog case-stuides-listing">      
      <section className="filter-gallery-section">
      <Container>
      <div className="filter-item-block">
      <Row>
            {data?.caseStuides?.map((news, index) => {
              var incekey =  index+1;
              var delaying = incekey+'00';
              let processedImages = JSON.stringify({});
              if (news?.imagetransforms?.Tile_Image_Transforms) {
              processedImages = news.imagetransforms.Tile_Image_Transforms;
              }	
              return <>

            <Col xl={4} md={6} xs={12} className="mbot" key={index}>
            <div className="item">
            <ScrollAnimation offset={!isMobile?1000:''} animateIn="fadeInUp" animateOnce={true}>                                  
                <Link to={`/${CaseStudyBaseURL}/${news.URL}`}>                  
                <div className="filter-img-block">
                <ImageTransform imagesources={news.Tile_Image.url} renderer="srcSet" imagename="case-stuides.Tile_Image.commontile" attr={{ alt: news.Tile_Image.alternativeText?news.Tile_Image.alternativeText:news.Title+' -  Location Location'}} imagetransformresult={processedImages} id={news.id}/>
                  </div>
                </Link>
                <div className="react-multi-carousel-list">
                <div className="blog-content">
                  <p className="blog-date"><DateFormat date={news.Case_Study_Date}/></p>
                  <p className="blog-description">
                   <Link to={`/${CaseStudyBaseURL}/${news.URL}`}>                  
                    {news.Title}
                    </Link>
                  </p>
                  </div>
                </div>
                </ScrollAnimation>
              </div>
              </Col>
              </>
              })}
        </Row>
          </div>
      </Container>              
      </section>
      </section>
    </React.Fragment>
  );
};
export default Gallery;
