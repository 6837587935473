import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./WhyUse.scss";
import ReactMarkdown from "react-markdown/with-html";


const WhyUse = (props) => {
  return (
    <React.Fragment>
      <section className={`why-use-location ${props.template.toLowerCase().replace(/\s/g, '')}`}>
        <Container>
          <Row>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
            <Col md={12}><span className="small-heading">{props.topheading}</span></Col>
            <Col md={12}><h2>{props.title}</h2></Col>
          </ScrollAnimation>
          </Row>
          <Row className="why-use-cols">
          {props.data.map((service, index) => {
          return<>
            <Col lg={4}  md={6} className={`order-${index+1}-md`}>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
            <div className="why-use-block">
              <h6>{service.Services_Title}</h6>
              <ReactMarkdown source={service.Services_Content} escapeHtml={false}/>              
            </div>
            </ScrollAnimation>  
            </Col>
            </>
            })} 
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default WhyUse;
