import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./PropertyManagementSection.scss"
import ReactMarkdown from "react-markdown/with-html"
import $ from "jquery"
import { navigate, useMatch } from "@reach/router"
import HTMLReactParser from "html-react-parser"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  easeFunc,
  delayItemsFromBottom,
  containerVariants,
  fadeInFromRight,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import Content from "../../PropertyDetails/KeyFeatures/Content"

const contentVariantsleft = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}

const contentVariantsright = {
  hidden: {
    opacity: 0,
    y: -50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
const MotionCol = motion(Col)
const PropertyManagementSection = props => {
  const match = useMatch("/purpose/work-with-us")
  useEffect(() => {
    if (match == null) {
      $("button.btn.register").click(function () {
        navigate("/contact")
      })
    }
    $(".career-landing .project-management-content .register").click(
      function () {
        $("html, body").animate(
          {
            scrollTop: $(".quiz-questions").offset().top - 100,
          },
          2000
        )
      }
    )
  })

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <section
            className={`property-management two-column-intro ${props.template
              ?.toLowerCase()
              .replace(/\s/g, "")}`}
          >
            <Container>
              <Row>
                <MotionCol variants={contentVariantsleft} lg={5}>
                  {/* <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInUp'> */}
                  {/* <ReactMarkdown source={props.Leftcontent} escapeHtml={false}/> */}
                  {props.Leftcontent
                    ? HTMLReactParser(props.Leftcontent)
                    : null}
                  {/* </ScrollAnimation> */}
                </MotionCol>
                <MotionCol variants={contentVariantsright} lg={7}>
                  {/* <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInUp'> */}
                  <div className="project-management-content careers-content">
                    {props.Rightcontent ? (
                      <motion.div
                        className="animation-bk"
                        variants={contentItem}
                      >
                        <Content Content={props.Rightcontent} />
                      </motion.div>
                    ) : //  HTMLReactParser(props.Rightcontent)
                    null}
                    {/* <ReactMarkdown source={props.Rightcontent} escapeHtml={false}/>               */}
                  </div>
                  {/* </ScrollAnimation> */}
                </MotionCol>
              </Row>
            </Container>
          </section>
        </motion.div>
      )}
    </InView>
  )
}

export default PropertyManagementSection
