import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import GuideBanner from "../Components/Static/StaticBanner/CommunityBanner"
import StaticContent from "../Components/Static/StaticBanner/StaticContent"
import ContentAccordion from "../Components/Static/StaticBanner/ContentAccordion"
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom"
import TwoColImages from "../Components/Static/StaticBanner/TwoColImages"
import FeaturedProperties from "../Components/PropertyManagement/RelatedPropertyManagement/Featured-Properties"
import BottomTeamModule from "../Components/Static/ContactTeam/ContactTeam"
import AreaguideMap from "../Components/detail-map"
import SEO from "../Components/Seo/seo"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Logo from "../images/locationl-logo.svg"
import NotFoundPage from "../pages/404"
const NEWS_DETAIL = gql`
  query NewsListing($URL: String!) {
    communities(where: { URL: $URL }) {
      id
      Title
      Page_Heading
      Page_Caption
      URL
      Meta_Title
      Meta_Description
      Intro_Content
      Banner_Image {
        url
        alternativeText
      }
      Select_Communties_Category {
        URL
        Name
      }
      Two_Column_Images {
        Column_Image {
          url
          alternativeText
        }
      }
      Select_Team_Member {
        id
      }
      Youtube_Video_URL
      Accordion_List {
        id
        Accordion_Title
        Accordion_Content
      }
      Content_Below_Two_Column_Images
      imagetransforms
    }
  }
`

const StaticPage = props => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])

  const { loading, error, data } = useQuery(NEWS_DETAIL, {
    variables: { URL: props.URL },
  })
  if (loading)
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <div className="new-loader-anime"></div>
            <img
              className="logo-white loader-logo"
              src={Logo}
              alt="logo"
            />
          </div>
          <div class="loader-section section-left"></div>
          <div class="loader-section section-right"></div>
        </div>
      </section>
    )

  return (
    // data.communities?.length > 0 ?
    <>
      {data.communities.map((item, index) => {
        return (
          <>
            <React.Fragment>
              <SEO
                title={`${item.Meta_Title ? item.Meta_Title : item.Title}`}
                description={
                  "Read about " +
                  item.Title +
                  " here and subscribe to stay in the loop with our weekly newsletter."
                }
              />
              <div
                className={
                  scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"
                }
              >
                <Header />
              </div>
              <GuideBanner
                Title={item.Area_Guide_Name}
                memberid={item.Select_Team_Member && item.Select_Team_Member.id}
                data={item}
                category={item.Select_Communties_Category}
              />
              <StaticContent FullContent={item.Intro_Content} />
              {item.Accordion_List !== null && (
                <ContentAccordion data={item.Accordion_List} />
              )}
              <TwoColImages
                Title={item.Page_Heading}
                images={item.Two_Column_Images}
                Belowcontent={item.Content_Below_Two_Column_Images}
              />

              <BottomTeamModule communityDetails={true} />

              <div className="valuation-steps-footer">
                <Footer
                  popularSearch="Popular_Search_Static_Common"
                  alias="communities"
                  baseurl="purpose/communities"
                  catalias={item.Select_Communties_Category.URL}
                  catlabel={item.Select_Communties_Category.Name}
                  menulabel={item.Title}
                />
              </div>
            </React.Fragment>
          </>
        )
      })}
    </>
    //   :
    // <BrowserRouter>
    //                 <Switch>
    //                     {/* {/* <Route exact path='/' component={HomePage} /> */}
    //                     {/* <Route path="/404" component={NotFoundPage} />  */}
    //                     <Redirect to="/404" />
    //                 </Switch>
    //             </BrowserRouter>
  )
}
export default StaticPage
