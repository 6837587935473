import React from "react"
import { Container } from "react-bootstrap"
import TableBody from "./TableBody"

const Table = props => {
  return (
    <Container>
      <TableBody item={props.tbodyData} headerItem={props.theadData} />
    </Container>
  )
}

export default Table
