import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Col, Row } from "react-bootstrap"
import "./Gallery.scss"
import NewsCategoryList from "./NewsCategoryList"
import { useQuery } from "@apollo/client"
import { BlogList } from "./NewsListing"
import gql from "graphql-tag"
import DateFormat from "../../format-date"
import { NewsBaseURL } from "../../utils"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"
import { isMobile } from "react-device-detect"
import Logo from "../../../images/locationl-logo.svg"

import { Redirect, navigate } from "@reach/router"

const NEWS_LIST = gql`
  query NewsListing($URL: String!) {
    ourNews(
      sort: "News_Published_Date:desc"
      where: { Select_News_Category: { URL: $URL } }
    ) {
      id
      Title
      URL
      Tile_Image {
        url
        alternativeText
      }
      News_Published_Date
      Select_News_Category {
        Name
        URL
      }
      imagetransforms
    }
  }
`
const Gallery = props => {
  const { loading, error, data } = useQuery(NEWS_LIST, {
    variables: { URL: props.NewsCategoryPage },
  })
  const ImageRender = ({ alt, image_url, node, processedImages }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="communities.Tile_Image.commontile"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }
  //   if (loading) return (
  //     <section className={"loader-wrapper"}>
  //       <div id="loader-wrapper">
  //         <div id="loader" class="new-loader">
  //           <div className="new-loader-anime"></div>
  //           <img className="logo-white" src={Logo} className="loader-logo" alt="logo"/>
  //         </div>
  //         <div class="loader-section section-left"></div>
  //         <div class="loader-section section-right"></div>
  //       </div>
  //     </section>
  // );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }

  //const refLink = useRef(null);
  //const [list,setList] = useState(GalleryItem);

  return (
    <>
      {data?.ourNews?.length !== 0 ? (
        <React.Fragment>
          <section className="news-listing-wrapper gallery-blog">
            <NewsCategoryList />

            <section className="filter-gallery-section">
              <Container className="d-xl-block">
                <div className="filter-item-block react-multi-carousel-list">
                  <Row>
                    {data?.ourNews?.map((news, index) => {
                      var incekey = index + 1
                      var delaying = incekey + "00"
                      let processedImages = JSON.stringify({})
                      if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages =
                          news.imagetransforms.Tile_Image_Transforms
                      }
                      return (
                        <>
                          <Col
                            xl={4}
                            md={6}
                            xs={12}
                            className="mbot"
                            key={index}
                          >
                            <div className="item">
                              <ScrollAnimation
                                offset={!isMobile ? 1000 : ""}
                                animateIn="fadeInUp"
                                animateOnce={true}
                              >
                                <Link
                                  to={`/${NewsBaseURL}/${news.Select_News_Category.URL}/${news.URL}`}
                                >
                                  <div className="filter-img-block">
                                    <ImageRender
                                      alt={
                                        news.Tile_Image.alternativeText
                                          ? news.Tile_Image.alternativeText
                                          : news.Title + " -  Location Location"
                                      }
                                      processedImages={processedImages}
                                      image_url={news.Tile_Image.url}
                                      node={news}
                                    />
                                    {/* <ImageTransform imagesources={news.Tile_Image.url} renderer="srcSet" imagename="our-news.Tile_Image.commontile" attr={{ alt: news.Tile_Image.alternativeText?news.Tile_Image.alternativeText:news.Title+' -  Location Location'}} imagetransformresult={processedImages} id={news.id}/>       */}
                                  </div>
                                </Link>
                                <div class="cat-name">
                                  <span>{news.Select_News_Category.Name}</span>
                                </div>
                                <div className="blog-content">
                                  <p className="blog-date">
                                    <DateFormat
                                      date={news.News_Published_Date}
                                    />
                                  </p>
                                  <p className="blog-description">
                                    <Link
                                      to={`/${NewsBaseURL}/${news.Select_News_Category.URL}/${news.URL}`}
                                    >
                                      {news.Title}
                                    </Link>
                                  </p>
                                </div>
                              </ScrollAnimation>
                            </div>
                          </Col>
                        </>
                      )
                    })}
                  </Row>
                </div>
              </Container>
            </section>
          </section>
        </React.Fragment>
      ) : (
        <Redirect to="/blog/" />
        // navigate("/blog/")
      )}
    </>
  )
}
export default Gallery
