import { Link } from "gatsby"
import HTMLReactParser from "html-react-parser"
import React from "react"
import { Container } from "react-bootstrap"
import bgImg from "../../images/triangle-card.svg"
import "./ContentSlider.scss"
import Slider from "react-slick"

const ContentSlider = ({ Module }) => {
  console.log(
    "🚀 ~ file: ContentSlider.js:10 ~ ContentSlider ~ Module:",
    Module
  )
  if (!Module) return null
  const { title, description, blocks } = Module

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="content-sections-wrap">
      <Container className="content-sections-container">
        <div className="content-section">
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
        </div>
        <div className="contents-section">
          <Slider {...sliderSettings}>
            {blocks?.map((block, index) => (
              <div className="content-section">
                <p className="block-title">{block?.title}</p>
                <div className="block-description">
                  {HTMLReactParser(block?.description)}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default ContentSlider
