import React, { useEffect, useState } from "react"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import NewsBanner from "../Components/Static/StaticBanner/NewsBanner"
import Othernews from "../Components/Static/Gallery/Othernews"
import ContactTeam from "../Components/Static/ContactTeam/ContactTeam"
import SEO from "../Components/Seo/seo"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Logo from "../images/locationl-logo.svg"
import { Redirect } from "@reach/router"
import NotFoundPage from "../pages/404"

const NEWS_DETAIL = gql`
  query NewsListing($URL: String!) {
    ourNews(where: { URL: $URL }) {
      id
      Title
      Meta_Title
      Meta_Description
      URL
      Tile_Image {
        url
      }
      News_Published_Date
      Select_News_Category {
        Name
        URL
      }
      News_Banner_Image {
        url
        alternativeText
      }
      News_Video_URL
      News_Content_Intro
      News_Mid_Image {
        url
        alternativeText
      }
      News_Content_Full
      Select_Team_Member {
        id
      }
      imagetransforms
    }
  }
`
const StaticPage = props => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  const { loading, error, data } = useQuery(NEWS_DETAIL, {
    variables: { URL: props.URL },
  })
  if (loading)
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <div className="new-loader-anime"></div>
            <img className="logo-white loader-logo" src={Logo} alt="logo" />
          </div>
          <div class="loader-section section-left"></div>
          <div class="loader-section section-right"></div>
        </div>
      </section>
    )

  return data.ourNews.length !== 0 ? (
    <>
      {data?.ourNews?.map((item, index) => {
        return (
          <>
            <SEO
              title={item.Title}
              description={`Read about "${item.Title.toLowerCase()}" here and subscribe to stay in the loop with our weekly newsletter.`}
              image={item.News_Banner_Image?.url}
            />
            <React.Fragment>
              <div className="news-details-wrapper">
                <div
                  className={
                    scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"
                  }
                >
                  <Header />
                </div>
                <NewsBanner
                  imagename="our-news.News_Banner_Image.commontile"
                  memberid={
                    item.Select_Team_Member && item.Select_Team_Member.id
                  }
                  Heading={item.Title}
                  BannerImage={item.News_Banner_Image}
                  VideoURL={item.News_Video_URL}
                  NewsDate={item.News_Published_Date}
                  IntroContent={item.News_Content_Intro}
                  FullContent={item.News_Content_Full}
                  MidImage={item.News_Mid_Image}
                  newsid={item.id}
                  imagetransforms={item.imagetransforms}
                />
                <Othernews URL={props.URL} newscat={props.newsCat} />
                <div className="footer-property-management">
                  <Footer
                    popularSearch="Popular_Search_Static_Common"
                    alias="blog"
                    baseurl="blog"
                    catalias={item.Select_News_Category.URL}
                    catlabel={item.Select_News_Category.Name}
                    menulabel={item.Title}
                  />
                </div>
              </div>
            </React.Fragment>
          </>
        )
      })}
    </>
  ) : (
    <>
      <NotFoundPage />
    </>
  )
}

export default StaticPage
