// import { Link } from "gatsby";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./HomeVideo.scss";
// import Logo from "../../../images/locationl-logo.svg";
import PlayVideo from "../../../Components/Play/PlayVideo";
import ReactMarkdown from "react-markdown/with-html";
import getVideoId from 'get-video-id';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from "gatsby"
import { inViewOptions, easeFunc, containerVariants, fadeInFromRight } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
const contentVariants = {
  hidden: {
      opacity: 0,
      x: -50,
  },
  visible: {
      opacity: 1,
      x: 0,
      transition: {
          ...easeFunc,
          duration: 0.6
      }
  }
}
const HomeVideo = (props) => {
  const [isPlay, setPlay] = useState(false);
  const videoid = props.VideoURL && getVideoId(props.VideoURL);
  const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        globalConfig {
          Home_Banner_Preview_Video {
            url
          }
        }
      }
    }
  `)
  var home_preview_video = data.glstrapi.globalConfig;
  //console.log('aaaaaaaaaaaa',home_preview_video.Home_Banner_Preview_Video.url)
  return (
    <React.Fragment>
      <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="video-sec"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
        <div className="video-img-block">
          {home_preview_video ?
          <div className="home-preview-video">
            <video data-autoplay playsinline="true" autoplay="true" muted="true" loop="true" preload="auto">
            <source src= {home_preview_video.Home_Banner_Preview_Video.url} type="video/mp4"/>
            </video> 
          </div>
          :
          <Img fluid={props.BannerImage.url_sharp.childImageSharp.fluid} alt={props.BannerImage.alternativeText?props.BannerImage.alternativeText:"Why Location Location is different Banner -  Location Location"} />          
          }
        </div>
        <Container className="pad-lt-rt">
          <div className="homevideo-content">
          <motion.div
            variants={contentVariants}
        >
            {props.VideoURL !=null && props.VideoURL != '' &&
                <a
                  href="javascript:;"
                  className="play-btn"
                  onClick={(e) => {
                    setPlay(true);
                  }}
                >
                  <i className="icon-video"></i>
                </a>
            }
            </motion.div>
            <motion.div className="content"
                      variants={contentVariants}
                    >
              <ReactMarkdown source={props.BannerContent} escapeHtml={false}/>              
              </motion.div>
          </div>
        </Container>
        {props.VideoURL !=null && videoid && props.VideoURL != '' &&
        <PlayVideo
          isOpen={isPlay}
          isCloseFunction={setPlay}
          videoId={props.VideoURL}
          isAutoPlay={1}
        />
        }
      </motion.section>
      )}
    </InView>
    </React.Fragment>
  );
};

export default HomeVideo;
