import React from "react"
import { Container } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import ScrollAnimation from "react-animate-on-scroll"
import ImageTransform from "../Common/ggfx-client/module/components/image-transform"
import Slider from "react-slick"
import "./InternalServiceBLock.scss"

const InternalServiceBlock = props => {

  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 4,
        },
      },
    ],
  }
  const serviceBlockDetails = props.Module.Internal_Services
  return (
    <div className={`${props.Module.service_body_color == "dark" ? "services_body_wrapper" : ""} services_block_wrapper`}>
      <Container className={`${props.Module.service_body_color == "dark" ? "service_body_container" : ""} service_block_container`}>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
          {props.Module.Title_Content ?  <h3 className="p-0">{props.Module.Title}</h3> : <h3>{props.Module.Title}</h3>}
          <div className="title_content">
            {props.Module.Title_Content
                ? HTMLReactParser(props.Module.Title_Content)
                : null}
            </div>
            <Slider {...settings}>
              {serviceBlockDetails && serviceBlockDetails.map((list, i) => {
                return (
                  <div className={`${i == 0 ? "first" : i == 1 ? "second" : i==2 ? "third" : ""} service`}>
                    <img src={list.Tile_Image.url} alt="img" />
                    <div className={props.Module.service_body_color == "dark" ? "service_body_content" : "service_body"}>
                      <p>{list.Block_Title}</p>
                      <div className="content ">
                        <p className="dull-color">{list.Block_Content ? HTMLReactParser(list.Block_Content) : null}</p>
                      
                        {<a href={`/property-services/sell/${list?.CTA_Link?.Primary_URL}`}>{list.CTA_Label}</a>}
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </ScrollAnimation>
      </Container>
    </div>
  )
}

export default InternalServiceBlock
{/* <Card className="service">
<Card.Img src={list.Tile_Image.url} alt="img"></Card.Img>
<Card.Body>
  <p>{list.Block_Title}</p>
  <p className="dull-color">{list.Block_Content ? HTMLReactParser(list.Block_Content) : null}</p>
  <div className="content">
    {list.CTA_Link ? <a href={list.CTA_Link}>{list.CTA_Label}</a> : <a href={list.CTA_Link}>{list.CTA_Label}</a>}
  </div>
</Card.Body>

</Card> */}