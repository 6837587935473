import React, { useEffect, useState } from "react"
import { Link } from "@StarberryUtils"
import minilogo from "../../../images/logo-location.svg"
import minilogo1 from "../../../images/logo-location-yellow.svg"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import { IconPhone } from "../../icons"
import { BookaValuationURL } from "../../utils"
import {
  ScrollingProvider,
  useScrollSections,
  useScrollSection,
  Section,
} from "react-scroll-section"
const MarketingHeader = props => {
  // const [sections, setSections] = React.useState([])
  const GQLModules = props.GQLModules
  const homeSection = useScrollSection("section-banner")
  // console.log(sectionsArr, document.getElementById("section-frequently-asked-questions"))
  // React.useEffect(() => {
  //   setSections(sectionsArr)
  // }, [props.path])
  var arr = []
  props.GQLModules.map((list, index) => {
    if (list.__typename === "GLSTRAPI_ComponentComponentsFullpageSlider") {
      for (let i = 0; i < list.Fullpage_Slide?.length; i++) {
        if (i === 0) {
          arr.push("section-banner")
        }
        arr.push(
          "section-" +
            list.Fullpage_Slide[i].Slide_Label?.replace(
              /\s+/g,
              "-"
            ).toLowerCase()
        )
      }
    }
  })
  var arr2 = []
  arr.map((item, id) => {
    props.sections
      .filter(list => list.id === item)
      .map(item => {
        arr2.push(item)
      })

    // arr2 = props.sections.filter(list => list.id === item).map(item)
  })

  const [activeindex, setactiveindex] = useState(null)
  return (
    <div className={`marketing-wrapper for-header-only ${props.Template}`}>
      <header className={"open-navigation header-fix"}>
        <Container className="header-padding-mob container-header">
          <div className="menudesktop">
            <Link to="/">
              <img
                src={minilogo}
                onMouseOver={e => (e.currentTarget.src = minilogo1)}
                onMouseOut={e => (e.currentTarget.src = minilogo)}
                alt={"Logo"}
              />
            </Link>
            <div className={`menubar-desktop ${props.Template}`}>
              <ul
                id={
                  props.Template === "fullpage"
                    ? "sellingmenu"
                    : "marketingmenu"
                }
              >
                {props.Template === "fullpage" ? (
                  <li
                    onClick={homeSection.onClick}
                    selected={homeSection.selected}
                  >
                    <a>
                      <span className="closeslide-top">
                        <i className="arrow up"></i>
                        <br></br>Top
                      </span>
                    </a>
                  </li>
                ) : (
                  <li data-menuanchor={`section-banner`}>
                    <a href={`#section-banner`}>
                      <span className="closeslide-top">
                        <i className="arrow up"></i>
                        <br></br>Top
                      </span>
                    </a>
                  </li>
                )}
                {GQLModules.map((Modules, i) => {
                  return (
                    <>
                      {Modules.__typename ===
                        "GLSTRAPI_ComponentComponentsMarketingSlides" && (
                        <>
                          {Modules.Marketing_Slider.map((slide, index) => {
                            var navindex = index + 1
                            var triggerindex = navindex + 1
                            return (
                              <>
                                <li
                                  className={index === 0 ? "active" : ""}
                                  data-menuanchor={`section-${slide?.Marketing_Slide_Title?.replace(
                                    /\s+/g,
                                    "-"
                                  ).toLowerCase()}`}
                                >
                                  <a
                                    href={`#section-${slide?.Marketing_Slide_Title?.replace(
                                      /\s+/g,
                                      "-"
                                    ).toLowerCase()}/`}
                                  >
                                    {slide?.Marketing_Slide_Title}
                                  </a>
                                </li>
                              </>
                            )
                          })}
                        </>
                      )}
                      {Modules.__typename ===
                        "GLSTRAPI_ComponentComponentsFullpageSlider" && (
                        <>
                          {/* {JSON.stringify(Modules.Fullpage_Slide)}
{JSON.stringify(sections)} */}
                          {arr2.map(({ id, onClick, selected }, index) =>
                            index === 0 ? null : (
                              <li
                                key={id}
                                onClick={onClick}
                                selected={selected}
                                className={selected ? "active" : ""}
                              >
                                <a>
                                  {id
                                    ?.toUpperCase()
                                    .replace(/-/g, " ")
                                    .replace("SECTION", " ")}
                                </a>
                              </li>
                            )
                          )}
                          {/* {Modules.Fullpage_Slide.map((slide, index) => (
                    <li className={index === activeindex ? "active" : ""}>
                      <a onClick={() => setactiveindex(index)} href={"#section-"+slide.Slide_Label?.replace(/\s+/g, '-').toLowerCase()}>{slide.Slide_Label.toUpperCase()}</a>
                    </li>
                  ))} */}
                          {/* {Modules.Fullpage_Slide.map((slide, index) => {
                var navindex = index+1;
                var triggerindex = navindex+1;
                return<>
                <li className={index===0?'active':""} data-menuanchor={`section-${slide?.Slide_Label?.replace(/\s+/g, '-').toLowerCase()}`}>
                  <a href={`#section-${slide?.Slide_Label?.replace(/\s+/g, '-').toLowerCase()}`}>{slide?.Slide_Label}</a>
                </li>    
                </>
                })}    */}
                        </>
                      )}
                    </>
                  )
                })}
              </ul>
            </div>
            {props.Template !== "fullpage" && (
              <div className="marketing-top-actions">
                {/* <div className="phn-market">
                  <IconPhone />
                  <a href="tel:0207 923 9222">0207 923 9222</a>
                </div> */}
                <Link to="/contact" class="button-black">
                  Contact
                </Link>
                <Link to={BookaValuationURL} class="btn btn-yellow">
                  Book a valuation
                </Link>
              </div>
            )}
          </div>
        </Container>
      </header>
    </div>
  )
}
export default MarketingHeader
