import React,{useEffect, useState} from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import NewsBanner from "../Components/Static/StaticBanner/NewsBanner";
import OtherCaseStudy from "../Components/Static/Gallery/Othercasestudies";
import ContactTeam from "../Components/Static/ContactTeam/ContactTeam";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import SEO from "../Components/Seo/seo";
import {Helmet} from "react-helmet";

const CASESTUDY_DETAIL=  gql`
query CasestudyListing ($URL: String!){   
    caseStuides  (where:{URL: $URL}){
    id
    Title
    Meta_Title
    Meta_Description
    URL
    Tile_Image {
      url
    }
    Case_Study_Date
    Banner_Image {
        url
        alternativeText
      }
      Youtube_Video_URL
      Intro_Content
      Statistics {
        Value
        Description
      }
      Tile_Blocks_Section {
        Tile_Block_Image {
          url
        }
      }
      Static_Content_Below_Tiles
      Static_Content_Above_Tiles
      Select_Team_Member {
        id
      }
      imagetransforms
  } 
}
`;
const StaticPage = (props) => {
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 100)
      })
    }, [])
  const { loading, error, data } = useQuery(CASESTUDY_DETAIL, {variables: { URL: props.URL }});
  if (loading) return <p>Loading ...</p>;

  
  return (
      <>
    {data.caseStuides.map((item, index) => {
        return  <>	
    <SEO title={item.Meta_Title} description={item.Meta_Description} />			
    <Helmet>
  <meta property="og:image" content={item.Banner_Image && item.Banner_Image.url} />
  <meta property="og:image:width" content="400" />
  <meta property="og:image:width" content="50" />
</Helmet>

    <React.Fragment>
      <div className={scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"}><Header/></div>
        <NewsBanner imagename="case-stuides.Banner_Image.commontile" newsid={item.id} memberid ={item.Select_Team_Member && item.Select_Team_Member.id} Heading={item.Title} BannerImage={item.Banner_Image} VideoURL={item.Youtube_Video_URL} NewsDate={item.Case_Study_Date} IntroContent={item.Intro_Content} stats={item.Statistics} FullContent={item.Static_Content_Above_Tiles} Belowcontent={item.Static_Content_Below_Tiles} tileblocks={item.Tile_Blocks_Section} imagetransforms={item.imagetransforms}/>        
        <OtherCaseStudy URL={props.URL}/>
      <div className="footer-property-management"><Footer popularSearch="Popular_Search_Static_Common" alias="case-studies" baseurl="property-services/new-homes/case-studies" menulabel={item.Title}/></div>
     </React.Fragment>
    </>
    	})}
    </> 
    );
};

export default StaticPage;
