import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./KeyFeatures.scss"
import PlayVideo from "../../Play/PlayVideo"
import ReactMarkdown from "react-markdown/with-html"
import getVideoId from "get-video-id"
import { IconPhone } from "../../icons"
import { BookaValuationURL } from "../../utils"
import $ from "jquery"
import Img from "gatsby-image"
import { isIOS, isMobile, isMobileOnly } from "react-device-detect"
import { Scrollbars } from "react-custom-scrollbars-2"
import LoadExternalScript from "../../../Components/utils/load-external-script"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  easeFunc,
  delayItemsFromBottom,
  containerVariants,
  fadeInFromRight,
  fadeInFromTop,
  fadeInFromLeft,
  fadeInFromRight1,
  fadeInFromTop1,
  fadeInFromLeft1,
  delayItems,
  imageZoom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import Footer from "../../../Components/Footer/Footer"
const youtubeScript = "https://apps.elfsight.com/p/platform.js"

const KeyFeatures = props => {
  const [open, setOpen] = useState(false)
  const [isPlay, setPlay] = useState(false)
  const [videoID, SetvideoID] = useState(false)
  const [ShowSlide, setShowSlide] = useState(false)
  const [Effect, setEffect] = useState("fadeInDown")
  const videoClick = value => {
    props.handleClickVideo(value)
  }
  useEffect(() => {
    setShowSlide(props.Slide1)
    $("html, body").scrollTop(0)
  }, [props.Slide1])

  const HanldeSlideClick = value => {
    setShowSlide(value)
    $("html, body").scrollTop(0)
  }
  const SlideClose = value => {
    props.closeClick(value)
  }

  const footer = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    })
  }

  const header = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  function gotohash(event) {
    var thishash = event
    $("html, body").animate(
      {
        scrollTop: $(thishash).offset().top - 120,
      },
      1000
    )
  }

  return (
    <React.Fragment>
      {props.slides.map((slide, index) => {
        var triggerindex = index + 1
        var nextclick = triggerindex + 1
        var prevclick = triggerindex - 1
        return (
          <>
            {ShowSlide || isMobile ? (
              <InView {...inViewOptions}>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="section"
                  >
                    <section
                      className={
                        index % 2 == 0
                          ? "prop-details-keyfetures property-det"
                          : "prop-details-keyfetures odd property-det"
                      }
                    >
                      <div
                        className={
                          index % 3 == 2
                            ? "key-wrap-slide-mark odd-a"
                            : index % 2 == 0
                            ? "key-wrap-slide-mark"
                            : "key-wrap-slide-mark odd-a"
                        }
                      >
                        <div
                          className="keyfetures"
                          id={`section-side${triggerindex}`}
                        >
                          {/* {slide.Marketing_Slide_Caption !== "origin" && ( */}
                          <div className="throw-text">
                            <motion.span
                              variants={
                                index % 3 == 2
                                  ? fadeInFromTop
                                  : index % 2 == 0
                                  ? fadeInFromLeft
                                  : fadeInFromRight
                              }
                              className="small-font-heading"
                            >
                              {slide.Marketing_Slide_Caption}
                            </motion.span>
                          </div>
                          {/* )} */}
                          <div className="throw-text">
                            <div>
                              {slide.Marketing_Slide_Subheading && (
                                <motion.h2
                                  variants={
                                    index % 3 == 2
                                      ? fadeInFromTop
                                      : index % 2 == 0
                                      ? fadeInFromLeft
                                      : fadeInFromRight
                                  }
                                  className="sub-heading-keyfeatures"
                                >
                                  {slide.Marketing_Slide_Subheading}
                                </motion.h2>
                              )}
                            </div>
                            <div className="right-f">
                              {slide.Marketing_Slide_Listing &&
                                slide.Marketing_Slide_Listing.length > 0 && (
                                  <>
                                    {/* {props.ClientWidth ? "true" : "false"} */}
                                    {props.ClientWidth ? (
                                      <motion.ul
                                        variants={
                                          index % 3 == 2
                                            ? fadeInFromTop
                                            : index % 2 == 0
                                            ? fadeInFromLeft
                                            : fadeInFromRight
                                        }
                                        className="customscrollbar"
                                      >
                                        {slide.Marketing_Slide_Listing.map(
                                          (slidelist, nin) => {
                                            return (
                                              <>
                                                <li>
                                                  <i className="icon-check-big d-md-none d-xl-block"></i>
                                                  <i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i>{" "}
                                                  <span>
                                                    {
                                                      slidelist.Marketing_Slide_List_Title
                                                    }
                                                  </span>
                                                </li>
                                              </>
                                            )
                                          }
                                        )}
                                      </motion.ul>
                                    ) : (
                                      <Scrollbars
                                        style={{
                                          width: "",
                                          height: isMobile ? 460 : 250,
                                        }}
                                      >
                                        <motion.ul
                                          variants={
                                            index % 3 == 2
                                              ? fadeInFromTop
                                              : index % 2 == 0
                                              ? fadeInFromLeft
                                              : fadeInFromRight
                                          }
                                          className="customscrollbar"
                                        >
                                          {slide.Marketing_Slide_Listing.map(
                                            (slidelist, nin) => {
                                              return (
                                                <>
                                                  <li>
                                                    <i className="icon-check-big d-md-none d-xl-block"></i>
                                                    <i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i>{" "}
                                                    <span>
                                                      {
                                                        slidelist.Marketing_Slide_List_Title
                                                      }
                                                    </span>
                                                  </li>
                                                </>
                                              )
                                            }
                                          )}
                                        </motion.ul>
                                      </Scrollbars>
                                    )}
                                  </>
                                )}
                              {slide.Marketing_Slide_Content && (
                                <motion.div
                                  variants={
                                    index % 3 == 2
                                      ? fadeInFromTop
                                      : index % 2 == 0
                                      ? fadeInFromLeft
                                      : fadeInFromRight
                                  }
                                >
                                  <ReactMarkdown
                                    source={slide.Marketing_Slide_Content}
                                    escapeHtml={false}
                                  />
                                </motion.div>
                              )}
                            </div>
                          </div>
                          {props.isLandscapeMode && (
                            <motion.div
                              variants={
                                index % 3 == 2
                                  ? fadeInFromTop
                                  : index % 2 == 0
                                  ? fadeInFromLeft
                                  : fadeInFromRight
                              }
                              className="button-group-scroll-buttons"
                            >
                              <div className="button-scroll-inner">
                                {slide.Prev_Slide_Heading == "Introduction" ? (
                                  <a href="#section-banner">
                                    <strong className="btn-round prev-click">
                                      <i className="icon-angle-round-up"></i>
                                      {slide.Prev_Slide_Heading}
                                    </strong>
                                  </a>
                                ) : (
                                  <a
                                    href={`#section-${
                                      slide.Prev_Slide_Heading &&
                                      slide.Prev_Slide_Heading.replace(
                                        /\s+/g,
                                        "-"
                                      ).toLowerCase()
                                    }`}
                                  >
                                    {slide.Next_Slide_Heading ==
                                    "Book a Valuation" ? (
                                      <strong className="btn-round">
                                        <i className="icon-angle-round-up"></i>
                                        {slide.Prev_Slide_Heading}
                                      </strong>
                                    ) : (
                                      <strong className="btn-round prev-click">
                                        <i className="icon-angle-round-up"></i>
                                        {slide.Prev_Slide_Heading}
                                      </strong>
                                    )}
                                  </a>
                                )}
                                {slide.Next_Slide_Heading ==
                                "Book a Valuation" ? (
                                  <Link
                                    className="book-val"
                                    to={BookaValuationURL}
                                  >
                                    {slide.Next_Slide_Heading}
                                  </Link>
                                ) : (
                                  <a
                                    href={`#section-${
                                      slide.Next_Slide_Heading &&
                                      slide.Next_Slide_Heading.replace(
                                        /\s+/g,
                                        "-"
                                      ).toLowerCase()
                                    }`}
                                  >
                                    <strong className="btn-round">
                                      <i className="icon-angle-round-down"></i>
                                      {slide.Next_Slide_Heading}
                                    </strong>
                                  </a>
                                )}
                              </div>
                            </motion.div>
                          )}
                          {!isMobileOnly && (
                            <motion.div
                              variants={
                                index % 3 == 2
                                  ? fadeInFromTop
                                  : index % 2 == 0
                                  ? fadeInFromLeft
                                  : fadeInFromRight
                              }
                              className="button-group-scroll-buttons"
                            >
                              <div className="button-scroll-inner">
                                {slide.Prev_Slide_Heading == "Introduction" ? (
                                  <a href="#section-banner">
                                    <strong className="btn-round prev-click">
                                      <i className="icon-angle-round-up"></i>
                                      {slide.Prev_Slide_Heading}
                                    </strong>
                                  </a>
                                ) : (
                                  <a
                                    href={`#section-${
                                      slide.Prev_Slide_Heading &&
                                      slide.Prev_Slide_Heading.replace(
                                        /\s+/g,
                                        "-"
                                      ).toLowerCase()
                                    }`}
                                  >
                                    {slide.Next_Slide_Heading ==
                                    "Book a Valuation" ? (
                                      <strong className="btn-round">
                                        <i className="icon-angle-round-up"></i>
                                        {slide.Prev_Slide_Heading}
                                      </strong>
                                    ) : (
                                      <strong className="btn-round prev-click">
                                        <i className="icon-angle-round-up"></i>
                                        {slide.Prev_Slide_Heading}
                                      </strong>
                                    )}
                                  </a>
                                )}
                                {slide.Next_Slide_Heading ==
                                "Book a Valuation" ? (
                                  <Link
                                    className="book-val"
                                    to={BookaValuationURL}
                                  >
                                    {slide.Next_Slide_Heading}
                                  </Link>
                                ) : (
                                  <a
                                    href={`#section-${
                                      slide.Next_Slide_Heading &&
                                      slide.Next_Slide_Heading.replace(
                                        /\s+/g,
                                        "-"
                                      ).toLowerCase()
                                    }`}
                                  >
                                    <strong className="btn-round">
                                      <i className="icon-angle-round-down"></i>
                                      {slide.Next_Slide_Heading}
                                    </strong>
                                  </a>
                                )}
                              </div>
                            </motion.div>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          index % 3 == 2
                            ? "key-banner-wrap odd-b"
                            : index % 2 == 0
                            ? "key-banner-wrap"
                            : "key-banner-wrap odd-b"
                        }
                      >
                        <div
                          className="keyfeatures-banner animated animatedFadeInUp fadeInUp"
                          id={`section-side-big${triggerindex}`}
                        >
                          {slide?.Marketing_Slide_Video_URL && (
                            <strong
                              href="#"
                              className="video-arrow"
                              onClick={e => {
                                setPlay(true)
                                videoClick("video-open")
                                SetvideoID(
                                  getVideoId(slide.Marketing_Slide_Video_URL)
                                )
                              }}
                            >
                              <i className="icon-video-white"></i>
                            </strong>
                          )}
                          {slide?.Bg_Video?.url ? (
                            <div className="home-preview-video">
                              <video
                                data-autoplay
                                playsinline="true"
                                autoplay="true"
                                muted="true"
                                loop="true"
                                preload="auto"
                              >
                                <source
                                  src={slide?.Bg_Video?.url}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          ) : slide?.Marketing_Slide_Image ? (
                            // <motion.div variants={index % 3 == 2 ? fadeInFromTop1 : index % 2 == 0 ?  fadeInFromRight1 : fadeInFromLeft1}>
                            <Img
                              fluid={
                                slide?.Marketing_Slide_Image?.url_sharp
                                  .childImageSharp.fluid
                              }
                              alt={
                                slide?.Marketing_Slide_Image?.alternativeText
                                  ? slide.Marketing_Slide_Image?.alternativeText
                                  : slide.Marketing_Slide_Caption +
                                    " -  Location Location"
                              }
                            />
                          ) : (
                            // </motion.div>
                            <div className="marketing-page-widget">
                              <LoadExternalScript
                                src={youtubeScript}
                                defer="true"
                              />

                              <div class="elfsight-app-a70de060-3a0e-490e-bed6-402cc63a5114"></div>
                            </div>
                          )}
                        </div>
                      </div>
                      {slide?.Marketing_Slide_Video_URL && (
                        <PlayVideo
                          isOpen={isPlay}
                          isCloseFunction={e => {
                            setPlay()
                            videoClick("")
                          }}
                          videoId={slide.Marketing_Slide_Video_URL}
                          isAutoPlay={1}
                        />
                      )}
                    </section>
                  </motion.div>
                )}
              </InView>
            ) : (
              ""
            )}
            {!isMobile && props.slides?.length === index + 1 && (
              <div className="section fp-auto-height fp-auto-height-responsive">
                {
                  <>
                    <div className="marketing-page-widget">
                      <LoadExternalScript src={youtubeScript} defer="true" />

                      <div class="elfsight-app-a70de060-3a0e-490e-bed6-402cc63a5114"></div>
                    </div>
                    <div className="valuation-steps-footer fullpage">
                      <Footer popularSearch="Popular_Search_Static_Common" />
                    </div>
                  </>
                  // null
                }
              </div>
            )}
          </>
        )
      })}
    </React.Fragment>
  )
}

export default KeyFeatures
