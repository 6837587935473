import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
} from "react-bootstrap"
import "./StaticBanner.scss"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import DateFormat from "../../format-date"
import ReactMarkdown from "react-markdown/with-html"
import { useLocation } from "@reach/router"
import PlayVideo from "../../Play/PlayVideo"
import getVideoId from "get-video-id"
import NewsletterForm from "../../../Components/forms/newsletter"
import GetMember from "../../getmember"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import LoadExternalScript from "../../utils/load-external-script"

const StaticBanner = props => {
  const location = useLocation()
  const shareUrl = location.href
  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareUrl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  const videoid = props.VideoURL && getVideoId(props.VideoURL)
  const [isPlay, setPlay] = useState(false)

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.News_Banner_Image_Transforms) {
    processedImages = props.imagetransforms.News_Banner_Image_Transforms
  }
  if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms
  }
  let processedImages2 = JSON.stringify({})
  if (props?.imagetransforms?.News_Mid_Image_Transforms) {
    processedImages2 = props.imagetransforms.News_Mid_Image_Transforms
  }
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={
          isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
        }
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={
            isCurrentEventKey ? "icon-minus-accordion " : "icon-plus-accordion"
          }
        ></i>
      </button>
    )
  }

  return (
    <React.Fragment>
      <section className="static-page-Banner news-page-banner">
        <Container>
          <ScrollAnimation
            animateOnce={true}
            className="banner-animated"
            animateIn="fadeInUp"
          >
            <div className="static-banner-content">
              <div className="inner-container">
                <h4>
                  <DateFormat date={props.NewsDate} />
                </h4>
                <h1>{props.Heading}</h1>
                <div className="user-data">
                  <GetMember memberid={props.memberid} />
                  <div
                    className={
                      props.memberid
                        ? "social-media-banner"
                        : "social-media-banner no-banner-user"
                    }
                  >
                    <span>Share:</span>
                    <ul className="share-trans">
                      <li>
                        <FacebookShareButton
                          onClick={() => trackerShare("FacebookShareButton")}
                          url={shareUrl}
                          className="my-share-button facebook-share"
                        >
                          <FacebookIcon
                            iconFillColor="white"
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          onClick={() => trackerShare("TwitterShareButton")}
                          url={shareUrl}
                          className="my-share-button twitter-share"
                        >
                          <TwitterIcon
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton
                          onClick={() => trackerShare("LinkedinShareButton")}
                          url={shareUrl}
                          className="my-share-button linked-share"
                        >
                          <LinkedinIcon
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </LinkedinShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </Container>
      </section>
      <section className="static-article-data news-banner">
        <Container>
          <Row>
            <Col lg={12}>
              <ScrollAnimation
                animateOnce={true}
                className="banner-animated"
                animateIn="fadeIn"
              >
                <div className="video-banner">
                  {props.BannerImage && (
                    <picture>
                      <ImageTransform
                        imagesources={props.BannerImage.url}
                        renderer="srcSet"
                        imagename={props.imagename}
                        attr={{
                          alt: props.BannerImage.alternativeText
                            ? props.BannerImage.alternativeText
                            : props.Heading + " -  Location Location",
                        }}
                        imagetransformresult={processedImages}
                        id={props.newsid}
                      />
                    </picture>
                  )}
                  {props.VideoURL != null && props.VideoURL != "" && (
                    <a
                      href="javascript:;"
                      className="video-arrow"
                      onClick={e => {
                        setPlay(true)
                      }}
                    >
                      <i class="icon-video-white"></i>
                    </a>
                  )}
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
          <div className="inner-container-blk">
            {props.IntroContent && (
              <Row>
                <Col lg={12}>
                  <div className="">
                    <div className="static-article-content news-details news-title">
                      <div className="article-text">
                        <ReactMarkdown
                          source={props.IntroContent}
                          escapeHtml={false}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {props.stats && (
              <Row className="col-grid3">
                {props.stats.map((stat, index) => {
                  return (
                    <>
                      <Col md={4}>
                        <ScrollAnimation
                          animateOnce={true}
                          animateIn="fadeInUp"
                          duration={0.5}
                        >
                          <div className="grid-box-content">
                            <h3>{stat.Value}</h3>
                            <p>{stat.Description}</p>
                          </div>
                        </ScrollAnimation>
                      </Col>
                    </>
                  )
                })}
              </Row>
            )}
            {props.MidImage && (
              <Row>
                <Col lg={12}>
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeIn"
                  >
                    <div className="article-img news-midside-img">
                      <picture>
                        <ImageTransform
                          imagesources={props.MidImage.url}
                          renderer="srcSet"
                          imagename="our-news.News_Mid_Image.commontile"
                          attr={{ alt: props.MidImage.alternativeText }}
                          imagetransformresult={processedImages2}
                          id={props.newsid}
                        />
                      </picture>
                    </div>
                  </ScrollAnimation>
                </Col>
              </Row>
            )}
            {props.FullContent && (
              <Row>
                <Col lg={12}>
                  <div className="">
                    <div className="static-article-content news-details news-full-content">
                      <div className="article-text">
                        <ReactMarkdown
                          source={props.FullContent}
                          escapeHtml={false}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Container>
        {props.tileblocks && (
          <Container className="additional-safety-img-box tiles-blocks-imgs">
            <Row>
              {props.tileblocks.map((tile, index) => {
                return (
                  <>
                    <Col md={6} className="pr-xl-4">
                      <ScrollAnimation
                        animateOnce={true}
                        duration={0.5}
                        animateIn="fadeIn"
                      >
                        <div className="grid-images">
                          <picture>
                            <source
                              media="(min-width:992px)"
                              srcSet={tile.Tile_Block_Image.url}
                            />
                            <source
                              media="(min-width:768px)"
                              srcSet={tile.Tile_Block_Image.url}
                            />
                            <img src={tile.Tile_Block_Image.url} alt="" />
                          </picture>
                        </div>
                      </ScrollAnimation>
                    </Col>
                  </>
                )
              })}
            </Row>
          </Container>
        )}

        {props.Belowcontent && (
          <Container>
            <Row>
              <Col lg={12}>
                <div className="inner-container">
                  <div className="article-text">
                    <ReactMarkdown
                      source={props.Belowcontent}
                      escapeHtml={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}

        <div className="stay-updated-form news">
          <Container>
            <Row>
              <Col md={12}>
                <div className="form-section">
                  <div className="form-head">
                    <ScrollAnimation
                      animateOnce={true}
                      animateIn="fadeInLeft"
                      duration={0.5}
                    >
                      <h2>Get all the words straight to your inbox.</h2>
                    </ScrollAnimation>
                  </div>

                  <div className="article-form">
                    <ScrollAnimation
                      animateOnce={true}
                      animateIn="fadeInRight"
                      duration={0.5}
                    >
                      {/* <NewsletterForm /> */}
                      <LoadExternalScript
                      src="https://locationlocation34980.activehosted.com/f/embed.php?id=18"
                      // type="text/javascript"
                      // charset="utf-8"
                      defer="true"
                      async="true"
                    />
                    <div class="_form_18"></div>
                    </ScrollAnimation>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {props.VideoURL != null && videoid && props.VideoURL != "" && (
          <PlayVideo
            isOpen={isPlay}
            isCloseFunction={setPlay}
            videoId={props.VideoURL}
            isAutoPlay={1}
          />
        )}
      </section>
    </React.Fragment>
  )
}
export default StaticBanner
