import React, { useState, useEffect } from "react";
import {Link} from '@StarberryUtils';
import "./HomeGoogleReview.scss";
import googleReviewImg from "../../../images/Home/google-review.jpg";
import googleReviewImgTablet from "../../../images/Home/googl-review-tablet.jpg";
import googleReviewImgDesktop from "../../../images/Home/google-review-desktop.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import googleReviewIcon from "../../../images/Home/google-reviews-dark.png";
import googleReviewIconTablet from "../../../images/Home/google-reviews-dark-tablets.png";
import googleReviewIconDesktop from "../../../images/Home/google-reviews-dark-desktop.png";
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import ShowMoreText from 'react-show-more-text';
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import { Col, Row, Container } from 'react-bootstrap';


const HomeGoogleReview = (props) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
        }
    ]
  };
  const formatter = buildFormatter(frenchStrings)
  const [testimonials, setTestimonials] = useState([])
  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {

    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url);
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;
  if(testimonials.length> 0) {
    var recentreview = testimonials[0];
  return (
    <React.Fragment>
      <section className={`homegoogle-reviews ${props.class}`}>
      <Container>
      <Row>
      <Col lg={6} md={12} className="rev-wrap">
        <ScrollAnimation
          animateIn="fadeInDown"
          className="google-review-img-block"
          animateOnce={true}
        duration={0.5}
        >
          <div className="google-review-img">
            <picture>
              <source
                media="(min-width:992px)"
                srcSet={googleReviewImgDesktop}
              />
              <source
                media="(min-width:768px)"
                srcSet={googleReviewImgTablet}
              />
              <img src={googleReviewImg} alt="Google reviews -  Location Location" />
            </picture>
          </div>
        </ScrollAnimation>
        </Col>
        <Col lg={6} md={12} className="rev-wrap">
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp" className="review-content-block">
          <div className="google-review-content">
            <div className="google-review-head">
              <span className="google-review-text">{parseFloat(rating_avg).toFixed(1)}/5</span>
              <span className="google-review-icon">
                <picture>
                  <source
                    media="(min-width:992px)"
                    srcSet={googleReviewIconDesktop}
                  />
                  <source
                    media="(min-width:768px)"
                    srcSet={googleReviewIconTablet}
                  />
                  <img src={googleReviewIcon} alt="" />
                </picture>
              </span>
            </div>
            <div className="slider-holder">
                <Slider {...settings}>
            {testimonials.map((review, index) => {
            if(index <=10 && review.comment !== undefined) {
            return<>
            <p>
              <ShowMoreText
                /* Default options */
                lines={4}
                more='More'
                less='Less'
                className='content-css'
                anchorClass='btn-text-link'
                expanded={false}
                width={500}
              >

                {review.comment}
              </ShowMoreText>
            </p>
            <ul className="ratings">
            {review.starRating == "ONE" &&
                <li>
                    <i className="icon-star-dark"></i>
                </li>
            }
            {review.starRating == "TWO" &&
                <>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                </>
            }
            {review.starRating == "THREE" &&
                <>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                </>
            }

            {review.starRating == "FOUR" &&
                <>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                </>
            }
            {review.starRating == "FIVE" &&
                <>

                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                    <li>
                        <i className="icon-star-dark"></i>
                    </li>
                </>
            }
            </ul>
            <div className="review-detail">
              <p className="name">{review.reviewer.displayName}</p>
              <p className="review-date">
                <TimeAgo date={review.createTime} formatter={formatter} />
              </p>
              
            </div>
            
            </>
            }
            })}
            </Slider>
            <Link to={"/purpose/reviews/"} className="btn btn-outline view_more_btn">View More</Link>
            </div>
          </div>
        </ScrollAnimation>
        </Col>
        </Row>
</Container>
      </section>
    </React.Fragment>
  );
} else {
  return (
    <></>
  )
}
};

export default HomeGoogleReview;
