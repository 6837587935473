import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import GETCTA from "./../../../Components/getcta"
import FeaturedPropertiesLanding from "./../../../Components/PropertyManagement/RelatedPropertyManagement/Featured-Properties-Landing-FullPage"
import MortgageCalculator from "./../../../Components/calculators/mortgagecalc/index"
import SectionTwoColumn from "./../../../Components/PropertyManagement/PropertyManagementSection/SectionTwoColumn"
import Plans from "./../../../Components/PropertyManagement/PropertyManagementSection/Plans"
import MarketingHeader from "./../../../Components/PropertyDetails/KeyFeatures/marketingheader"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import LoadExternalScript from "../../../Components/utils/load-external-script"


import {
  ScrollingProvider,
  useScrollSections,
  Section,
} from "react-scroll-section"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  Card,
  dark,
} from "react-bootstrap"
import { useContext } from "react"
import "./KeyFeatures.scss"
import "./FullpageSlide.scss"

// import {
//   CarouselProvider,
//   Slider,
//   Slide,
//   ButtonBack,
//   ButtonNext,
//   DotGroup,
// } from "pure-react-carousel"
import {
  isMobileOnly,
  isTablet,
  isMobile,
  // browserName
} from "react-device-detect"
import "pure-react-carousel/dist/react-carousel.es.css"
import PlayVideo from "../../Play/PlayVideo"
import ReactMarkdown from "react-markdown/with-html"
import Content from "./Content"
import getVideoId from "get-video-id"
import { IconPhone } from "../../icons"
import { BookaValuationURL } from "../../utils"
import $ from "jquery"
import Img from "gatsby-image"
import { Scrollbars } from "react-custom-scrollbars-2"
import GoogleReviewsSlider from "../../PropertyManagement/PropertyManagementGoogleReview/PropertyManagementGoogleReviewService"
import BuyerReport from "../../Static/ContactTeam/CtaBlock"
import Footer from "../../../Components/Footer/Footer"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  easeFunc,
  delayItemsFromBottom,
  containerVariants,
  fadeInFromRight,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import SellingGuideForm from "../../../Components/forms/selling-guide"
// import InputField from "../../forms/elements/input"
import Slider from "react-slick"
import Modal from "react-bootstrap/Modal"



const MotionCol = motion(Col)
const contentVariantsleft = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}

const contentVariantsright = {
  hidden: {
    opacity: 0,
    y: -50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
export const DynamicMenu = () => {
  const sections = useScrollSections()

  return (
    <ul>
      {sections.map(({ id, onClick, selected }) => (
        <li key={id} onClick={onClick} selected={selected}>
          {id.toUpperCase()}
        </li>
      ))}
    </ul>
  )
}
const FullpageSlide = props => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const [open, setOpen] = useState(false)
  const [isPlay, setPlay] = useState(false)
  const [videoID, SetvideoID] = useState(false)
  const [ShowSlide, setShowSlide] = useState(false)
  const [Effect, setEffect] = useState("fadeInDown")
  const [showModal, setShowModal] = useState(false)

  // const [name, setName] = useState("")
  // const [email, setEmail] = useState("")
  const videoClick = value => {
    props.handleClickVideo(value)
  }
  useEffect(() => {
    setShowSlide(props.Slide1)
    $("html, body").scrollTop(0)
  }, [props.Slide1])

  const HanldeSlideClick = value => {
    setShowSlide(value)
    $("html, body").scrollTop(0)
  }
  const SlideClose = value => {
    props.closeClick(value)
  }

  const footer = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    })
  }

  const header = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  function gotohash(event) {
    var thishash = event
    $("html, body").animate(
      {
        scrollTop: $(thishash).offset().top - 120,
      },
      1000
    )
  }

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey
    return (
      <button
        type="button"
        className={
          isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
        }
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={isCurrentEventKey ? "icon-angle-up " : "icon-angle-down"}
        ></i>
      </button>
    )
  }

  const closeModal = () => {
    setShowModal(false)
  }

  // const handleSubmit = event => {
  //   event.preventDefault()
  //   window.open(
  //     "https://ggfx-locationl.s3.eu-west-2.amazonaws.com/i.dev/Guide_To_Selling_c636ed7f5b.pdf"
  //   )
  //   setName("")
  //   setEmail("")
  //   setShowModal(false)
  // }

  // const handleName = e => {
  //   setName(e.target.value)
  // }

  // const handleEmail = e => {
  //   setEmail(e.target.value)
  // }

  if(showModal){
    setTimeout(() => {
      const element = document.getElementsByClassName('_close')[0];
      console.log(element, "prabu")
      element?.addEventListener("click", () => setShowModal(false));
    }, 200);
  }

  
  const handleModal = () => {
    setShowModal(true)
    
  }

  
  return (
    <React.Fragment>
      {/* <ScrollingProvider> */}
      {/* <MarketingHeader Template={'fullpage'} GQLModules={props.GQLModules}/> */}
      {/* {props.slides?.length} */}
      {props.slides.map((slide, index) => {
        var triggerindex = index + 1
        var nextclick = triggerindex + 1
        var prevclick = triggerindex - 1
        return (
          <>
            {ShowSlide || isMobile ? (
              <div
                className="
            
            fp-auto-height fp-auto-height-responsive
            "
              >
                {/*fp-auto-height fp-auto-height-responsive */}
                {/* {JSON.stringify(slide)} */}
                {slide.Type === "Plan_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <SectionTwoColumn
                      Leftcontent={props.Leftcontent}
                      Rightcontent={props.Rightcontent}
                      template={props.template}
                    />
                    <Plans data={props.data} template={props.template} />
                  </Section>
                )}
                {slide.Type === "Calculater_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <section
                      className={"calculator-bk prop-details-keyfetures full"}
                      style={{ backgroundColor: "#000" }}
                    >
                      <div className="bg-overtext">
                        <Container>
                          <Row>
                            <Col lg={8}>
                              <InView {...inViewOptions}>
                                {({ ref, inView }) => (
                                  <motion.div
                                    ref={ref}
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                  >
                                    {slide.Small_Heading && (
                                      <motion.span
                                        variants={contentItem}
                                        className="small-font-heading"
                                      >
                                        {slide.Small_Heading}
                                      </motion.span>
                                    )}
                                    {slide.Title && (
                                      <motion.h2 variants={contentItem}>
                                        {slide.Title}
                                      </motion.h2>
                                    )}
                                    <motion.div
                                      className="animation-bk"
                                      variants={contentItem}
                                    >
                                      <Content Content={slide.Content} />
                                    </motion.div>
                                  </motion.div>
                                )}
                              </InView>
                            </Col>
                          </Row>
                        </Container>
                        <Container>
                          <Row>
                            <Col lg={12}>
                              <div className="calculate-stamp-duty">
                                <div class="calc-wrapper">
                                  <MortgageCalculator />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </section>
                  </Section>
                )}
                {slide.Type === "Toogle_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <section
                      className={`toggle-blk frequently-asked-questions`}
                    >
                      <InView {...inViewOptions}>
                        {({ ref, inView }) => (
                          <motion.div
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                          >
                            <Container>
                              <Row>
                                <Col lg={8}>
                                  {slide.Small_Heading && (
                                    <motion.span
                                      variants={contentItem}
                                      className="small-font-heading"
                                    >
                                      {slide.Small_Heading}
                                    </motion.span>
                                  )}
                                  {slide.Title && (
                                    <motion.h2 variants={contentItem}>
                                      {slide.Title}
                                    </motion.h2>
                                  )}
                                </Col>
                                <Col lg={12}>
                                  <div className="accordion-block">
                                    <Accordion>
                                      {slide.Tails.map((tail, index) => {
                                        return (
                                          <motion.div
                                            className="item"
                                            key={index}
                                            custom={index}
                                            variants={delayItemsFromBottom}
                                          >
                                            <Card>
                                              <div className="accordion-header">
                                                <ContextAwareToggle
                                                  eventKey={index + 1}
                                                  className="accordion-header"
                                                >
                                                  <span>{tail.Title}</span>
                                                </ContextAwareToggle>
                                              </div>

                                              <Accordion.Collapse
                                                eventKey={index + 1}
                                              >
                                                <div className="accordion-card accordion-card-first">
                                                  <div className="content">
                                                    <ReactMarkdown
                                                      source={tail.Content}
                                                      escapeHtml={false}
                                                    />
                                                  </div>
                                                </div>
                                              </Accordion.Collapse>
                                            </Card>
                                          </motion.div>
                                        )
                                      })}
                                    </Accordion>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </motion.div>
                        )}
                      </InView>
                    </section>
                  </Section>
                )}
                {slide.Type === "Featured_Properties_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <section className={`feature-fullpage`}>
                      <FeaturedPropertiesLanding
                        Small_Heading={slide.Small_Heading}
                        Title={slide.Title}
                        // type={props.Properties_Type}
                      />
                    </section>
                  </Section>
                )}

                {slide.Type === "Two_Column_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <section className={`two-col-block-full-page`}>
                      <InView {...inViewOptions}>
                        {({ ref, inView }) => (
                          <motion.div
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                          >
                            <Container>
                              <Row>
                                <Col lg={12}>
                                  {slide.Small_Heading && (
                                    <motion.span
                                      variants={contentItem}
                                      className="small-font-heading"
                                    >
                                      {slide.Small_Heading}
                                    </motion.span>
                                  )}
                                </Col>
                                <Col lg={5}>
                                  <motion.h2
                                    variants={contentItem}
                                    className="margin-right-70"
                                  >
                                    {slide.Title}
                                  </motion.h2>
                                </Col>
                                <Col lg={7}>
                                  <motion.div
                                    variants={contentItem}
                                    className="animation-bk"
                                  >
                                    <Content Content={slide.Content} />
                                  </motion.div>
                                  {/* <ReactMarkdown source={slide.Content} escapeHtml={false} /> */}
                                </Col>
                              </Row>
                            </Container>
                          </motion.div>
                        )}
                      </InView>
                    </section>
                  </Section>
                )}

                {slide.Type === "Black_Two_Column_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <section className={`two-col-block-full-page black`}>
                      <InView {...inViewOptions}>
                        {({ ref, inView }) => (
                          <motion.div
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                          >
                            <Container>
                              <Row>
                                <Col lg={12}>
                                  {slide.Small_Heading && (
                                    <motion.span
                                      variants={contentItem}
                                      className="small-font-heading"
                                    >
                                      {slide.Small_Heading}
                                    </motion.span>
                                  )}
                                </Col>
                                <Col lg={5}>
                                  <motion.h2 variants={contentItem}>
                                    {slide.Title}
                                  </motion.h2>
                                  {slide.Cta_Label === "Book a Valuation"
                                    ? slide.Cta_Url?.Primary_URL &&
                                      slide.Cta_Label && (
                                        <motion.div variants={contentItem}>
                                          <GETCTA
                                            link={slide.Cta_Url?.Primary_URL}
                                            class="btn-valuation"
                                            Label={slide.Cta_Label}
                                          />
                                        </motion.div>
                                      )
                                    : slide.Cta_Url?.Primary_URL &&
                                      slide.Cta_Label && (
                                        <motion.div variants={contentItem}>
                                          <GETCTA
                                            link={slide.Cta_Url?.Primary_URL}
                                            class="btn btn-yellow"
                                            Label={slide.Cta_Label}
                                          />
                                        </motion.div>
                                      )}
                                </Col>
                                <Col lg={7}>
                                  <motion.div
                                    className="animation-bk"
                                    variants={contentItem}
                                  >
                                    <Content Content={slide.Content} />
                                  </motion.div>
                                  {/* <ReactMarkdown source={slide.Content} escapeHtml={false} /> */}
                                </Col>
                              </Row>
                            </Container>
                          </motion.div>
                        )}
                      </InView>
                    </section>
                  </Section>
                )}
                {slide.Type === "Bg_Banner_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <InView {...inViewOptions}>
                      {({ ref, inView }) => (
                        <motion.section
                          ref={ref}
                          className={"prop-details-keyfetures full bg-img-tild"}
                          style={{
                            backgroundImage: `url(${slide?.Image?.url})`,
                          }}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={containerVariants}
                        >
                          <div className={"key-banner-wrap Banner-block"}>
                            <div
                              className="keyfeatures-banner animated animatedFadeInUp fadeInUp"
                              id={`section-side-big${triggerindex}`}
                            >
                              <div className="tild-bg" />
                              {slide.Upload_Video?.url && (
                                <InView {...inViewOptions}>
                                  {({ ref, inView }) => (
                                    <motion.div
                                      ref={ref}
                                      className="home-preview-video notilt"
                                      initial="hidden"
                                      animate={inView ? "visible" : "hidden"}
                                      variants={containerVariants}
                                    >
                                      <video
                                        data-autoplay
                                        playsinline="true"
                                        autoplay="true"
                                        muted="true"
                                        loop="true"
                                        preload="auto"
                                      >
                                        <source
                                          src={slide.Upload_Video?.url}
                                          type="video/mp4"
                                        />
                                      </video>
                                    </motion.div>
                                  )}
                                </InView>
                              )}
                              <InView {...inViewOptions}>
                                {({ ref, inView }) => (
                                  <motion.div
                                    ref={ref}
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={containerVariants}
                                  >
                                    <Container>
                                      <Row>
                                        <Col lg={8}>
                                          {slide.Video_Url && (
                                            <strong
                                              href="#"
                                              className="video-arrow"
                                              onClick={e => {
                                                setPlay(true)
                                                videoClick("video-open")
                                              }}
                                            >
                                              <i className="icon-video-white"></i>
                                            </strong>
                                          )}
                                          {props.Page_CSS_Class !==
                                            "sellpage" && (
                                            <div className="bg-overtext">
                                              <div>
                                                {slide.Small_Heading && (
                                                  <motion.span
                                                    variants={contentItem}
                                                    className="small-font-heading"
                                                  >
                                                    {slide.Small_Heading}
                                                  </motion.span>
                                                )}
                                                {slide.Title && (
                                                  <motion.h2
                                                    variants={contentItem}
                                                  >
                                                    {slide.Title}
                                                  </motion.h2>
                                                )}
                                                <motion.div
                                                  className="animation-bk"
                                                  variants={contentItem}
                                                >
                                                  <Content
                                                    isScroll={true}
                                                    Content={slide.Content}
                                                  />
                                                </motion.div>
                                                {slide.Cta_Url?.Primary_URL &&
                                                  slide.Cta_Label && (
                                                    <motion.div
                                                      variants={contentItem}
                                                    >
                                                      <GETCTA
                                                        link={
                                                          slide.Cta_Url
                                                            ?.Primary_URL
                                                        }
                                                        class="btn btn-yellow"
                                                        Label={slide.Cta_Label}
                                                      />
                                                    </motion.div>
                                                  )}
                                              </div>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </Container>
                                  </motion.div>
                                )}
                              </InView>
                            </div>
                          </div>
                          {slide.Video_Url && (
                            <PlayVideo
                              isOpen={isPlay}
                              isCloseFunction={e => {
                                setPlay()
                                videoClick("")
                              }}
                              videoId={slide.Video_Url}
                              isAutoPlay={1}
                            />
                          )}
                        </motion.section>
                      )}
                    </InView>
                  </Section>
                )}

                {slide.Type === "Bg_Banner_Block" &&
                  props.Page_CSS_Class === "sellpage" && (
                    <section className={`two-col-block-full-page`}>
                      <InView {...inViewOptions}>
                        {({ ref, inView }) => (
                          <motion.div
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                          >
                            <Container>
                              <Row>
                                <Col lg={12}>
                                  {slide.Small_Heading && (
                                    <motion.span
                                      variants={contentItem}
                                      className="small-font-heading"
                                    >
                                      {slide.Small_Heading}
                                    </motion.span>
                                  )}
                                </Col>
                                <Col lg={5}>
                                  <motion.h2 variants={contentItem}>
                                    {slide.Title}
                                  </motion.h2>
                                  {slide.Cta_Label === "Book a Valuation"
                                    ? slide.Cta_Url?.Primary_URL &&
                                      slide.Cta_Label && (
                                        <motion.div variants={contentItem}>
                                          <GETCTA
                                            link={slide.Cta_Url?.Primary_URL}
                                            class="btn-valuation"
                                            Label={slide.Cta_Label}
                                          />
                                        </motion.div>
                                      )
                                    : slide.Cta_Url?.Primary_URL &&
                                      slide.Cta_Label && (
                                        <motion.div variants={contentItem}>
                                          <GETCTA
                                            link={slide.Cta_Url?.Primary_URL}
                                            class="btn btn-yellow"
                                            Label={slide.Cta_Label}
                                          />
                                        </motion.div>
                                      )}
                                </Col>
                                <Col lg={7}>
                                  <motion.div
                                    className="animation-bk"
                                    variants={contentItem}
                                  >
                                    <Content Content={slide.Content} />
                                  </motion.div>
                                  {/* <ReactMarkdown source={slide.Content} escapeHtml={false} /> */}
                                </Col>
                              </Row>
                            </Container>
                          </motion.div>
                        )}
                      </InView>
                    </section>
                  )}
                {slide.Type === "Reviews_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <GoogleReviewsSlider />
                  </Section>
                )}
                {slide.Type === "CTA_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <BuyerReport
                      Title={slide.Title}
                      Content={slide.Content}
                      Image={slide.Image}
                      CtaLabel={slide.Cta_Label}
                      CtaUrl={slide.Cta_Url}
                      Page_CSS_Class={props.Page_CSS_Class}
                    />
                  </Section>
                )}

                {slide.Type === "Guide_Block" && (
                  <>
                    <Section
                      id={`section-${slide?.Slide_Label?.replace(
                        /\s+/g,
                        "-"
                      ).toLowerCase()}`}
                    >
                      <section
                        className={`guide-grid-block ${slide.Grid_Type}`}
                      >
                        <InView {...inViewOptions}>
                          {({ ref, inView }) => (
                            <motion.div
                              ref={ref}
                              initial="hidden"
                              animate={inView ? "visible" : "hidden"}
                              variants={containerVariants}
                            >
                              <Container>
                                <Row>
                                  <Col lg={8}>
                                    {slide.Small_Heading && (
                                      <motion.span
                                        variants={contentItem}
                                        className="small-font-heading"
                                      >
                                        {slide.Small_Heading}
                                      </motion.span>
                                    )}
                                    {slide.Title &&
                                    slide.Slide_Label !== "Guide to Selling" ? (
                                      <motion.h2 variants={contentItem}>
                                        {slide.Title}
                                      </motion.h2>
                                    ) : (
                                      <motion.h2 variants={contentItem}>
                                        Here’s snippets from our concise step by
                                        step guide to selling your home,
                                        happy.&nbsp;
                                        <span
                                          class="download-guides"
                                          onClick={() => handleModal()}
                                        >
                                          Download our Free guide
                                        </span>{" "}
                                        and follow the expert advice within to
                                        secure 3% +.
                                      </motion.h2>
                                    )}
                                    {slide.Content && (
                                      <motion.div
                                        className="para"
                                        variants={contentItem}
                                      >
                                        <ReactMarkdown
                                          source={slide.Content}
                                          escapeHtml={false}
                                        />
                                      </motion.div>
                                    )}
                                  </Col>
                                  <Col lg={12}>
                                    <div class="flex-container">
                                      {slide.Tails.map((tail, index) => {
                                        return (
                                          <motion.div
                                            variants={delayItemsFromBottom}
                                            key={index}
                                            custom={index}
                                            className="tail-bk"
                                          >
                                            {slide.Grid_Type ===
                                              "Black_Grid_Two_Colum" ||
                                              (slide.Grid_Type ===
                                                "Black_Grid" && (
                                                <div className="number">
                                                  {index + 1}
                                                </div>
                                              ))}
                                            <div className="tail-content-bk">
                                              <h6>{tail.Title}</h6>
                                              <motion.div
                                                className="animation-bk"
                                                variants={contentItem}
                                              >
                                                <Content
                                                  Content={tail.Content}
                                                />
                                              </motion.div>
                                              {/* <ReactMarkdown source={tail.Content} escapeHtml={false} /> */}
                                            </div>
                                          </motion.div>
                                        )
                                      })}
                                    </div>
                                  </Col>
                                  {/* {slide.Slide_Label === "Guide to Selling" && (
                                    <Col lg={8}>
                                      <button
                                        className="download-pdf"
                                        onClick={() => displayModal()}
                                      >
                                        Download
                                      </button>
                                    </Col>
                                  )} */}
                                </Row>
                              </Container>
                            </motion.div>
                          )}
                        </InView>
                      </section>
                      {/* } */}
                    </Section>
                  </>
                )}
                {slide.Type === "Slide_Tails_Block" && (
                  <Section
                    id={`section-${slide?.Slide_Label?.replace(
                      /\s+/g,
                      "-"
                    ).toLowerCase()}`}
                  >
                    <section class="Slide-tail-block">
                      <InView {...inViewOptions}>
                        {({ ref, inView }) => (
                          <motion.div
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                          >
                            <Container>
                              <Row>
                                <Col lg={8}>
                                  {slide.Small_Heading && (
                                    <motion.span
                                      variants={contentItem}
                                      className="small-font-heading"
                                    >
                                      {slide.Small_Heading}
                                    </motion.span>
                                  )}
                                  {slide.Title && (
                                    <motion.h2 variants={contentItem}>
                                      {slide.Title}
                                    </motion.h2>
                                  )}
                                </Col>
                                {/* {props.isLandscapeMode ? 100 : 120} */}
                                <MotionCol
                                  className="home-page-slider"
                                  variants={contentItem}
                                  lg={12}
                                >
                                  {/* <CarouselProvider
                                    naturalSlideWidth={100}
                                    naturalSlideHeight={
                                      props.isLandscapeMode
                                        ? 140
                                        : isMobileOnly
                                        ? 170
                                        : isTablet
                                        ? 130
                                        : props.ClientWidth
                                        ? 110
                                        : 135
                                    }
                                    totalSlides={slide.Tails?.length}
                                    infinite={true}
                                    isPlaying={true}
                                    visibleSlides={
                                      props.isLandscapeMode
                                        ? 3.2
                                        : isMobileOnly
                                        ? 1
                                        : isTablet
                                        ? 2.2
                                        : props.LargeBigMonitor
                                        ? 4.5
                                        : props.LargeMonitor
                                        ? 3.5
                                        : props.ClientWidth
                                        ? 3.5
                                        : 3.5
                                    }
                                  >
                                    <Slider className="home-page-slider">
                                      {slide.Tails.map((tail, index) => {
                                        return (
                                          <Slide
                                            key={index}
                                            index={index}
                                            className="home-page-slider-item"
                                          >
                                            <div className="item">
                                              <img
                                                src={tail.Image.url}
                                                alt={tail.Image.alternativeText}
                                              />
                                              <h6>{tail.Title}</h6>
                                              <ReactMarkdown
                                                source={tail.Content}
                                                escapeHtml={false}
                                              />
                                            </div>
                                          </Slide>
                                        )
                                      })}
                                    </Slider>
                                    {isMobile && (
                                      <DotGroup
                                        dotNumbers={false}
                                        disableActiveDots={true}
                                        showAsSelectedForCurrentSlideOnly={true}
                                      />
                                    )}
                                  </CarouselProvider> */}
                                  <Slider
                                    className="home-page-slider-item"
                                    {...settings}
                                  >
                                    {slide.Tails.map((tail, index) => {
                                      return (
                                        <div className="item">
                                          <img
                                            src={tail.Image.url}
                                            alt={tail.Image.alternativeText}
                                          />
                                          <h6>{tail.Title}</h6>
                                          <ReactMarkdown
                                            source={tail.Content}
                                            escapeHtml={false}
                                          />
                                        </div>
                                      )
                                    })}
                                  </Slider>
                                </MotionCol>
                              </Row>
                            </Container>
                          </motion.div>
                        )}
                      </InView>
                    </section>
                  </Section>
                )}

                {/* <section className={"prop-details-keyfetures"}>

        <div className={"key-wrap-slide-mark"}>
          </div>
          <div className={"key-banner-wrap"}>

          </div>
          </section> */}
              </div>
            ) : (
              ""
            )}
          </>
        )
      })}
      <div className="section fp-auto-height fp-auto-height-responsive">
        {
          props.Select_Footer === "Dark_Footer" ? (
            <div className="valuation-steps-footer fullpage">
              <Footer fullpage={true} />
            </div>
          ) : (
            <Footer isServicePage={true} popularSearch={props.popularSearch} />
          )
          // null
        }
      </div>
      {/* </ScrollingProvider> */}

      {/* modal to display on clicking download button */}
      {/* {
        showModal && 
          <div className="ourFreeGuide">
            <LoadExternalScript
                src="https://locationlocation34980.activehosted.com/f/embed.php?id=16"
                type="text/javascript"
                charset="utf-8"
              />
          </div>
      }  */}

      <Modal
        show={showModal}
        centered={true}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form-buy-sell-rent modal-form reviewPopup"
      >
        <Modal.Header closeButton className="contact-close-btn">
          <Modal.Title className="w-100">Guides To Selling</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p></p>
          <SellingGuideForm />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default FullpageSlide
