import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./AreaGuides.scss"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const AreaGuides = props => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 3,
    },
  }
  return (
    <React.Fragment>
      <section className="our-clients-wrapper">
        <section className="gallery">
          <Container>
            <Row>
              <Col lg={12}>
                <ScrollAnimation
                  animateOnce={true}
                  duration={0.5}
                  animateIn="fadeInDown"
                >
                  <h2>A few of our clients</h2>
                  <p>
                    We’ve worked with a number of established developers across
                    London.
                  </p>
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="area-guides">
          <div className="area-guide-video-carousel">
            <Carousel
              responsive={responsive}
              showDots={true}
              draggable={true}
              autoPlay={true}
              swipeable={true}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {props.data.map((client, index) => (
                <>
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeInUp"
                  >
                    <div className="item our-clients">
                      <div className="video-img-box">
                        <div className="video-img-wrapper client-logo">
                          <picture>
                            <source
                              media="(min-width:992px)"
                              srcSet={client.Client_Logo[0].url}
                            />
                            <source
                              media="(min-width:768px)"
                              srcSet={client.Client_Logo[0].url}
                            />
                            <a
                              href={client.Client_URL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={client.Client_Logo[0].url}
                                alt="our-clients"
                              />
                            </a>
                          </picture>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </>
              ))}
            </Carousel>
          </div>
        </section>
      </section>
    </React.Fragment>
  )
}

export default AreaGuides
