import React from "react"
import { Container } from "react-bootstrap"
import "./GetStarted.scss"
import GetCTA from "../getcta"
import ScrollAnimation from "react-animate-on-scroll"
import HTMLReactParser from "html-react-parser"

const GetStarted = props => {
  return (
    <div className="getStarted_wrapper">
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
        <Container>
          <div className="content">
            {props.Module.Content
              ? HTMLReactParser(props.Module.Content)
              : null}
          </div>
          <div className="getStart_btn">
            <GetCTA
              link={props.Module.Cta_Link && props.Module.Cta_Link.Primary_URL}
              class="btn btn-outline yellow-btn"
              Label={props.Module.Cta_Label}
            />
          </div>
        </Container>
      </ScrollAnimation>
    </div>
  )
}

export default GetStarted
