import { Link } from "@StarberryUtils"
import React from "react"
import { Container } from "react-bootstrap"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import DateFormat from "../../format-date"
import ScrollAnimation from "react-animate-on-scroll"
import "./HomeBlog.scss"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { NewsBaseURL } from "../../utils"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"
import {
  inViewOptions,
  containerVariants,
  delayItemsFromBottom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel"
import {
  isMobileOnly,
  isTablet,
  isMobile,
  // browserName
} from "react-device-detect"
import { CommuntiesBaseURL } from "../../utils"
import "pure-react-carousel/dist/react-carousel.es.css"
import LoadExternalScript from "../../../Components/utils/load-external-script"
const locRatingScriptsJs = "https://apps.elfsight.com/p/platform.js"
const youtubeScript = "https://apps.elfsight.com/p/platform.js"
const instagramScript = "https://apps.elfsight.com/p/platform.js"

// const NEWS_CAROUSEL =  gql`
// query NewsCarousel {
//   ourNews (sort:"News_Published_Date:desc") {
//     id
//     Title
//     URL
//     Tile_Image {
//       url
//       alternativeText
//     }
//     News_Published_Date
//     Select_News_Category {
//       Name
//       URL
//     }
//     imagetransforms
//   }
// }
// `;

const NEWS_LIST = gql`
  query NewsListing {
    communities(sort: "id:asc") {
      id
      Title
      URL
      Tile_Image {
        url
        alternativeText
      }
      Select_Communties_Category {
        Name
        URL
      }
      imagetransforms
    }
  }
`

function NewsCarousel(props) {
  const { loading, error, data } = useQuery(NEWS_LIST)
  if (loading) return <p className="d-none">Loading ...</p>
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3.2,
      partialVisibilityGutter: 50,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }
  // const handleLink = (e, news) => {
  //   e.stopPropagation();
  //   let link = `/${NewsBaseURL}/${news.Select_News_Category.URL}/${news.URL}`
  //   navigate(link)
  // }
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className={props.isHomepage ? "youtube-block" : "homeblog"}
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden "}
          variants={containerVariants}
        >
          <Container style={{ maxWidth: "90vw" }}>
            {props.isHomepage ? (
              <>
                {/* <LoadExternalScript 
              src={locRatingScriptsJs}
              // async="true"
              defer="true"
              // loadScript={renderMap}
              // appendScriptTo="head"
            />
            <div class="elfsight-app-b6ccb263-5d3c-420e-a7a6-20327334607f"></div> */}

                <div style={{ marginTop: "0px", marginBottom: "70px" }}>
                  <LoadExternalScript
                    // async="true"
                    src={instagramScript}
                    defer="true"
                    // loadScript={renderMap}
                    // appendScriptTo="head"
                  />
                  <div class="elfsight-app-21b89d57-c206-4fb3-8d59-d6daa5137198"></div>
                </div>
                <div>
                  <LoadExternalScript
                    src={youtubeScript}
                    // async="true"
                    defer="true"
                    // loadScript={renderMap}
                    // appendScriptTo="head"
                  />

                  <div class="elfsight-app-a70de060-3a0e-490e-bed6-402cc63a5114"></div>
                </div>
              </>
            ) : (
              <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={isTablet ? 100 : 150}
                isIntrinsicHeight={true}
                totalSlides={
                  isMobileOnly || isTablet ? 6 : data?.communities?.length
                }
                infinite={true}
                isPlaying={true}
                visibleSlides={
                  isMobileOnly
                    ? 1
                    : isTablet
                    ? 2
                    : props.LargeBigMonitor
                    ? 5.5
                    : props.LargeMonitor
                    ? 3.5
                    : props.ClientWidth
                    ? 4.5
                    : 3.5
                }
                className="home-page-slider-blk"
              >
                <motion.div variants={delayItemsFromBottom}>
                  <Slider className="home-page-slider">
                    {data?.communities
                      ?.slice(
                        0,
                        isMobileOnly || isTablet ? 6 : data?.communities?.length
                      )
                      .map((news, index) => {
                        let processedImages = JSON.stringify({})
                        if (news?.imagetransforms?.Tile_Image_Transforms) {
                          processedImages =
                            news.imagetransforms.Tile_Image_Transforms
                        }
                        return (
                          <Slide
                            key={index}
                            index={index}
                            className="home-page-slider-item"
                          >
                            <div className="item">
                              <Link
                                to={`/${CommuntiesBaseURL}/${news.Select_Communties_Category.URL}/${news.URL}`}
                                // onClick={(e) => handleLink(e, news)}
                              >
                                <ImageTransform
                                  imagesources={news?.Tile_Image?.url}
                                  renderer="srcSet"
                                  imagename="our-news.Tile_Image.commontile"
                                  attr={{
                                    alt: news?.Tile_Image?.alternativeText
                                      ? news?.Tile_Image?.alternativeText
                                      : news?.Title + " -  Location Location",
                                  }}
                                  imagetransformresult={processedImages}
                                  id={news?.id}
                                />
                              </Link>
                              {/* {news.Select_Communties_Category &&
                <Link to={'blog/'+news.Select_Communties_Category?.Name?.toLowerCase().replace(/ /g, '-')} class="cat-name"> */}
                              <div className="cat-name">
                                {" "}
                                <span>
                                  {news?.Select_Communties_Category?.Name}
                                </span>
                              </div>
                              {/* </Link>
              } */}
                              <div className="blog-content">
                                {/* <p className="blog-date"><DateFormat date={news.News_Published_Date}/></p> */}
                                <p className="blog-description">
                                  <Link
                                    to={`/${CommuntiesBaseURL}/${news.Select_Communties_Category.URL}/${news.URL}`}
                                  >
                                    {news.Title}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </Slide>
                        )
                      })}
                  </Slider>
                </motion.div>
                {isMobile && (
                  <DotGroup
                    dotNumbers={false}
                    disableActiveDots={true}
                    showAsSelectedForCurrentSlideOnly={true}
                  />
                )}
              </CarouselProvider>
            )}
            {/* <Carousel
              responsive={responsive}
              showDots={true}
              partialVisible={true}
              draggable={true}
              autoPlay={isMobile ? false : true}
              transitionDuration={500}
              keyBoardControl={true}
              autoPlaySpeed={2000}
              swipeable={false}
              freeDrag={false}
              infinite={true}
              // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {data.ourNews.map((news, index) => {	
              let processedImages = JSON.stringify({});
              if (news?.imagetransforms?.Tile_Image_Transforms) {
              processedImages = news.imagetransforms.Tile_Image_Transforms;
              }	                                       
              return<>
              <div className="item">
              <a 
              // onClick={(e) => handleLink(e, news)}
              >    

              <ImageTransform imagesources={news.Tile_Image.url} renderer="srcSet" imagename="our-news.Tile_Image.commontile" attr={{ alt: news.Tile_Image.alternativeText?news.Tile_Image.alternativeText:news.Title+' -  Location Location'}} imagetransformresult={processedImages} id={news.id}/>      
              </a>
              {news.Select_News_Category &&
                <div class="cat-name">
                  <span>{news.Select_News_Category.Name}</span>
                </div>
              }
                <div className="blog-content">
                  <p className="blog-date"><DateFormat date={news.News_Published_Date}/></p>
                  <p className="blog-description">
                    <a href="#">
                      {news.Title}
                    </a>
                  </p>
                </div>
              </div>
              </>
              })}
              
            </Carousel> */}
            {/* </ScrollAnimation> */}
          </Container>
        </motion.section>
      )}
    </InView>
  )
}
export default NewsCarousel
