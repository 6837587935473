/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Button } from "react-bootstrap"
import { filterNumber, numberFormat, pmt } from "./util"
import { defaultValues } from "./config"
import stampImgDesk from "../../../images/PropertyManagement/stamp-img.png"
import stampImgTab from "../../../images/PropertyManagement/stamp-img.png"
import stampImgMob from "../../../images/PropertyManagement/stamp-img.png"
import { Link } from "@StarberryUtils"
import $ from "jquery"
import Modal from "react-bootstrap/Modal"
import MemberContact from "../../forms/member-contact"
import { calculateMonthlyPayment } from "./mortgage"
import MortageAdvisorForm from "../../../Components/forms/mortgage-advisor-form-new"

import { useStaticQuery, graphql } from "gatsby"

// use project specific import here

// User project specific const
const durationOptions = [10, 15, 20, 25, 30, 35]

// DO NOT DO ANY CHNAGES - START
const MortgageCalc = props => {
  // console.log(props, "pppppp")
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        mortgageAdvice(publicationState: LIVE) {
          Content
          Image {
            alternativeText
            url
          }
          Image1 {
            alternativeText
            url
          }
        }
      }
    }
  `)

  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  // DO NOT DO ANY CHNAGES - START
  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    defaultResult,
    pricePrefix,
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
  } = props

  const prefix = ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const [deposit, setDeposit] = useState(prefix + numberFormat(depositAmount))
  const [duration, setDuration] = useState(loadDuration)
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")
  const [depositError, setDepError] = useState(false)
  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    if (
      event.target.value !== "£" &&
      event.target.value !== "£0" &&
      event.target.value !== "0" &&
      event.target.value !== ""
    ) {
      // if(parseInt(filterNumber(deposit)) > parseInt(filterNumber(event.target.value))){
      //   setMonthlyPayment(0)
      // }
      // else {
      setPurchasePrice(prefix + numberFormat(val))
      if (parseInt(filterNumber(deposit)) && parseInt(val)) {
        let loan = parseInt(val) - parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      } else {
        setLoan(prefix + 0)
      }
      $(".btn-calculate").click()
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(event.target.value)),
          parseFloat(interest),
          parseInt(filterNumber(deposit)),
          duration
        )
      )
      setShowerror(false)
      // }
    } else {
      setPurchasePrice("")
      setLoan()
      setMonthlyPayment(0)
    }
  }

  const handleDeposit = event => {
    let d = filterNumber(event.target.value)
    if (
      event.target.value !== "£" &&
      event.target.value !== "£0" &&
      event.target.value !== "0" &&
      event.target.value !== ""
    ) {
      // if(parseInt(filterNumber(purchasePrice)) === parseInt(filterNumber(event.target.value)) || parseInt(filterNumber(purchasePrice)) < parseInt(filterNumber(event.target.value))){
      //   setShowerror(true)
      //   setDeposit(prefix + numberFormat(d))
      // }
      // else {
      setDeposit(prefix + numberFormat(d))

      if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
        let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
        setLoan(prefix + numberFormat(loan2))
      } else {
        setLoan(prefix + 0)
      }
      $(".btn-calculate").click()
      var par = purchasePrice.replace("£", "").replace(/,/g, "")
      var dep = event.target.value.replace("£", "").replace(/,/g, "")
      var par1 = parseInt(par)
      var dep1 = parseInt(dep)
      if (par1 < dep1 || par1 === dep1) {
        setDepError(true)
        setMonthlyPayment(0)
      } else {
        setDepError(false)
        setMonthlyPayment(
          calculateMonthlyPayment(
            parseInt(filterNumber(purchasePrice)),
            parseFloat(interest),
            parseInt(filterNumber(event.target.value)),
            duration
          )
        )
      }
      setShowerror(false)

      // }
    } else {
      setLoan("")
      setDeposit("")
      setMonthlyPayment(0)
    }
  }

  const handleDuration = event => {
    setDuration(filterNumber(event.target.value))
    $(".btn-calculate").click()
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        event.target.value
      )
    )
  }

  const handleInterest = event => {
    setInterest(event.target.value.replace(/[^\d.]/g, ""))
    $(".btn-calculate").click()
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(event.target.value),
        parseInt(filterNumber(deposit)),
        duration
      )
    )
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
    $(".btn-calculate").click()
  }

  const getResult = (interest, duration, loan) => {
    let result = -pmt(
      interest / 100 / 12,
      filterNumber(duration) * 12,
      filterNumber(loan),
      0,
      1
    )
    setResult(numberFormat(Math.round(result)))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
        getResult(interest, duration, loan)
      }
    }
  }

  useEffect(() => {
    $(".btn-calculate").click()
    if (defaultResult) {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      }
      getResult(interest, duration, loan)
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(purchasePrice)),
          parseFloat(interest),
          parseInt(filterNumber(deposit)),
          duration
        )
      )
    }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  useEffect(() => {
    if (
      parseInt(filterNumber(purchasePrice)) &&
      parseInt(filterNumber(deposit))
    ) {
      let loan =
        parseInt(filterNumber(purchasePrice)) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    }
    getResult(interest, duration, loan)
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        duration
      )
    )
  }, [])
  // DO NOT DO ANY CHNAGES - END
  var monthly_payment = Math.round(monthlyPayment)

  const handleModal = () => {
    setShowModal(true)
  }
  const handleclosing = () => {
    setShowModal(false)
  }

  return (
    <div className="calculate-section">
      <div className="form mortgate-form">
        {showerror && (
          <div className="alert-error">
            <p>Highlighted fields are required | invalid</p>
          </div>
        )}
        {depositError && (
          <div className="alert-error">
            <p>Please enter deposit amount less than property amount.</p>
          </div>
        )}
        <Form
          name="MortgageCalc"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="stamp-duty-form">
            <input type="hidden" name="bot-field" />
            <Form.Group controlId="purchase_price">
              <Form.Label>Property Purchase Price</Form.Label>
              <div className="form-addon-input-left form-border-input">
                <Form.Control
                  required
                  type="text"
                  name="purchase_price"
                  value={purchasePrice}
                  placeholder="Purchase Price"
                  onChange={handlePrice}
                  maxLength={13}
                />
              </div>
            </Form.Group>
            <Form.Group controlId="deposit">
              <Form.Label>Deposit</Form.Label>
              <div className="form-addon-input-left form-border-input">
                <Form.Control
                  required
                  type="text"
                  name="deposit_available"
                  value={deposit}
                  placeholder="Deposit Available"
                  onChange={handleDeposit}
                  maxLength={13}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="duration">
              <Form.Label>Duration</Form.Label>
              <div className="form-addon-input-left form-border-input">
                <Form.Control
                  as="select"
                  required
                  name="duration"
                  value={duration}
                  placeholder="Duration (Years)"
                  onChange={handleDuration}
                >
                  {durationOptions.map((value, key) => {
                    return (
                      <option value={value} key={key}>
                        {value} Years
                      </option>
                    )
                  })}
                </Form.Control>
              </div>
            </Form.Group>
            <Form.Group className="intrest-rate" controlId="interest_rate">
              <Form.Label>Interest Rate</Form.Label>
              <div className="form-addon-input-left form-border-input">
                <Form.Control
                  required
                  type="text"
                  name="interest_rate"
                  value={interest}
                  maxLength={4}
                  placeholder="Interest Rate (%)"
                  onChange={handleInterest}
                />
              </div>
            </Form.Group>
            <Form.Group controlId="loan_amount" className="d-none">
              <Form.Label>Loan Amount</Form.Label>
              <div className="form-addon-input-left form-border-input readonly-loan">
                <Form.Control
                  required
                  type="text"
                  name="loan_amount"
                  value={loan}
                  placeholder="Loan Amount"
                  onChange={handleLoan}
                  disabled="disabled"
                />
              </div>
            </Form.Group>
          </div>
          <div className="monthly-repayment-form">
            <div className="monthly-repayment-text">
              <label>Monthly Repayments</label>
              <p className="mrp-amount">
                {currency}
                {numberFormat(monthly_payment)}
                <span> / month</span>
              </p>
            </div>
            {/* <div className="find-property-block">
              <div className="find-property-block-dt">
                <span>Ready to find a property?</span>
                <p className="description">start your journey here.</p>
              </div>
              <Link
                to="/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london/"
                className="mtbntn"
              >
                <div className="find-property-btn">
                  <Button variant="yellow" type="submit">
                    Property Search
                  </Button>
                </div>
              </Link>
            </div> */}
            <div className="mort-advice-block mortage">
              {/* <div>
                <div className="img-bk">
                  <img
                    src={data.glstrapi?.mortgageAdvice?.Image1?.url}
                    alt="Mortgage calculator - Location Location"
                  />
                </div>
              </div> */}
              <p className="description">
                Numbers not quite adding up? Why not speak to an impartial
                mortgage advisor for free initial advice?
              </p>
            </div>
            <a onClick={() => handleModal()} className="button-get-advice">
              Help Me
            </a>
            <div className="stamp-enquiry-img d-none">
              <div className="img-thumb">
                <picture>
                  <source media="(min-width:992px)" srcSet={stampImgDesk} />
                  <source media="(min-width:768px)" srcSet={stampImgTab} />
                  <img src={stampImgMob} alt="" />
                </picture>
              </div>
              <div className="img-thumb-data">
                <p className="name">Laura White</p>
                <p className="contact-dt">
                  <Link href="tel:02079239222" className=" btn-text-link mt-0">
                    0207 923 9222
                  </Link>{" "}
                  /{" "}
                  <a
                    onClick={() => handleShow()}
                    href="javascript:void(0);"
                    className=" btn-text-link mt-0"
                  >
                    Email
                  </a>
                </p>
              </div>
              <Modal
                show={show}
                centered={true}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
              >
                <Modal.Header closeButton className="contact-close-btn">
                  <Modal.Title className="w-100">
                    Contact - Laura White
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p></p>
                  <MemberContact to_email_id="" />
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <Button type="submit" className="d-none btn btn-pink btn-calculate">
            Calculate
          </Button>
        </Form>
        {result && (
          <div className="alert-success d-none">
            <p>
              Monthly Repayment: {currency}
              {numberFormat(monthly_payment)}
            </p>
          </div>
        )}
      </div>
      {
        <>
          <Modal
            show={showModal}
            centered={true}
            onHide={handleclosing}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
          >
            <Modal.Header closeButton className="contact-close-btn">
              <Modal.Title className="w-100">Mortage Advice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p></p>
              <MortageAdvisorForm
                isBuying="true"
                propertyaddress={props.propertyaddress}
              />
            </Modal.Body>
          </Modal>
        </>
      }
    </div>
  )
}

MortgageCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIT,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

MortgageCalc.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default MortgageCalc
