import React, { useEffect, useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import ReactMarkdown from "react-markdown/with-html"
import GETCTA from "../Components/getcta"
import { getSrc } from "gatsby-plugin-image"
import Footer from "../Components/Footer/Footer"
import { Link } from "@StarberryUtils"

import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import { isMobileOnly } from "react-device-detect"
const ValueSlider = props => {
  const {  state } = props
  const { destination, initialized } = state
  return (
    <>
      {props.slides.map((item, index) => {
        return (
          <>
            <div class="section">
              <section className="section-ourvalues">
                <div className="property-banner-wrapper">
                  {isMobileOnly && item.Our_Values_Slide_Mobile_Image ? (
                    <picture>
                      <img
                        src={getSrc(
                          item.Our_Values_Slide_Mobile_Image.url_sharp
                        )}
                        alt={
                          item.Our_Values_Slide_Mobile_Image.alternativeText
                            ? item.Our_Values_Slide_Mobile_Image.alternativeText
                            : item.Our_Values_Slide_Title +
                              " -  Location Location"
                        }
                      />
                    </picture>
                  ) : (
                    <picture>
                      <img
                        src={getSrc(item.Our_Values_Slide_Image.url_sharp)}
                        alt={
                          item.Our_Values_Slide_Image.alternativeText
                            ? item.Our_Values_Slide_Image.alternativeText
                            : item.Our_Values_Slide_Title +
                              " -  Location Location"
                        }
                      />
                    </picture>
                  )}
                  <div className="marketing-overlay"></div>
                </div>
                <div className="property-banner-content">
                  <Container>
                    <h4>{item.Our_Values_Slide_Caption}</h4>
                    <h1>{item.Our_Values_Slide_Title}</h1>
                    <ReactMarkdown
                      source={item.Our_Values_Slide_Description}
                      escapeHtml={false}
                    />
                    <div className="prop-banner-btn-group ">
                      <GETCTA
                        link={item?.Our_Values_CTA1_Link?.Primary_URL}
                        class="btn btn-yellow"
                        Label={item?.Our_Values_CTA1_Label}
                      />
                      <GETCTA
                        link={item?.Our_Values_CTA2_Link?.Primary_URL}
                        class={
                          index === 3 ? "btn btn-outline " : "btn btn-yellow"
                        }
                        Label={item?.Our_Values_CTA2_Label}
                      />
                      <Link
                        to="/property-services/sell/property-valuation/"
                        className="btn btn-outline"
                      >
                        Book a Valuation
                      </Link>
                    </div>
                  </Container>
                </div>
              </section>
            </div>
          </>
        )
      })}
      <div className="section fp-auto-height fp-auto-height-responsive">
        {
          <div className="valuation-steps-footer fullpage">
            <Footer
              isServicePage={true}
              popularSearch={"Popular_Search_Static_Common"}
            />
          </div>

          // null
        }
      </div>
    </>
  )
}
export default ValueSlider
