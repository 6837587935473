import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import {Link}  from "@StarberryUtils"
import {IconArrow} from './icons'

const GetCTA = (props) => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Link_Type
          Label
          Primary_URL
          Secondary_URL
          Topmenu
          Template
          Main_Parent {
            id
            Label
            Primary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
          }
        }
      }
    }
  `)
  var menu_data = data.glstrapi.allMenus;
  return (
    <>
        {menu_data.map((menuitem) => {         
        return<>
            {menuitem.Main_Parent === null && menuitem.Sub_Parent === null && props.link === menuitem.Primary_URL &&
            <Link to={`/${menuitem.Primary_URL}`} className={props.class}>
                {props.Label}{props.arrow=="show"?<IconArrow/>:''}
            </Link>
            }
            {menuitem.Main_Parent != null && menuitem.Sub_Parent === null && props.link === menuitem.Primary_URL &&
            <Link to={`/${menuitem.Main_Parent.Primary_URL}/${menuitem.Primary_URL}`} className={props.class}>
                {props.Label}{props.arrow=="show" && props.Label!=="Landlords"?<IconArrow/>:''}
            </Link>
            }
            {menuitem.Main_Parent != null && menuitem.Sub_Parent != null && props.link === menuitem.Primary_URL &&
            <Link to={`/${menuitem.Main_Parent.Primary_URL}/${menuitem.Sub_Parent.Primary_URL}/${menuitem.Primary_URL}`} className={props.class}>
                {props.Label}{props.arrow=="show"?<IconArrow/>:''}
            </Link>
            }
        </>
        })}
    </>
  )
}
export default GetCTA;
