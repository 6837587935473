import React, {} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Container} from "react-bootstrap";
import ReactMarkdown from "react-markdown/with-html";
import { Link } from "@StarberryUtils";
import GETCTA from '../../getcta'
import "../../Search/SearchBanner/SearchBanner.scss";
const SearchBanner = (props) => {  
  return (
    <React.Fragment>
      <section className={`search-banner ${props.template.toLowerCase()}`}>
        <Container>
          <ScrollAnimation animateOnce={true} duration={0.5} className="banner-animated" animateIn="fadeInUp">
            <div className="search-banner-content">
              <h4>{props.Caption}</h4>
              <h1>{props.Heading}</h1>
              <ReactMarkdown source={props.Content} escapeHtml={false}/>
              {props.Ctas?.length > 0 &&
              <div className="btn-groups">
              {props.Ctas?.map((Cta) => {
                return<>
                  {Cta.Custom_Link?
                  <Link href={Cta.Custom_Link} class="btn btn-outline btnproperty">
                  {Cta.CTA_Label}
                  </Link>:
                  <GETCTA link={Cta.CTA_Link && Cta.CTA_Link.Primary_URL} class="btn btn-outline btnproperty" Label={Cta.CTA_Label}/>
                  }
                </>
              })}                
              </div>
              }
            </div>

          </ScrollAnimation>
        </Container>
      </section>

    </React.Fragment>
  );
};

export default SearchBanner;
