import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card} from "react-bootstrap";
import "./FrequentlyAskedQuestions.scss";
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import ReactMarkdown from "react-markdown/with-html";


const FrequentlyAskedQuestions = (props) => {
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
        type="button"
        className={isCurrentEventKey ? 'active_card inactive_card': 'inactive_card'}
        onClick={decoratedOnClick}
      >
        {children}
        <i 
         className={isCurrentEventKey ? 'icon-angle-up ': 'icon-angle-down'}
        ></i>
      </button>
    );
  }
  return (
    <React.Fragment>
      <section className="frequently-asked-questions">
        <Container>
          <Row>
            <Col lg={12}> 
            <ScrollAnimation animateOnce={true} duration={0.5} className="banner-animated" animateIn="fadeInDown">
              <span className="small-font-heading">{props.Heading}</span>
            </ScrollAnimation>
            </Col>
            <Col lg={12}>
            <ScrollAnimation animateOnce={true} duration={0.5} className="banner-animated" animateIn="fadeInDown">
              <ReactMarkdown source={props.Description} escapeHtml={false}/>              
            </ScrollAnimation>
              </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="accordion-block">
              <Accordion>
              <ScrollAnimation animateOnce={true} duration={0.5} className="banner-animated" animateIn="fadeIn">
              {props.FAQListing.map((faq) => {
              return<>
                <Card >
                  <div className="accordion-header" >
                  <ContextAwareToggle eventKey={faq.id} className="accordion-header">
                  <span>{faq.FAQ_Title}</span> 
                  </ContextAwareToggle>
                  </div>
               
                  <Accordion.Collapse eventKey={faq.id}>
                   <div className="accordion-card accordion-card-first">
                    <div className="content">
                      <ReactMarkdown source={faq.FAQ_Description} escapeHtml={false}/>              
                      </div>
                     </div>
                  </Accordion.Collapse>
                </Card>
                </>
                })}                
                
            </ScrollAnimation>

                </Accordion>
           
             </div>
        
            </Col>
          </Row>
        </Container>
        
        </section>
    </React.Fragment>
  );
};
export default FrequentlyAskedQuestions;
