import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
} from "react-bootstrap"
import "./StaticBanner.scss"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import { useLocation } from "@reach/router"
import PlayVideo from "../../Play/PlayVideo"
import getVideoId from "get-video-id"
import GetTeamMember from "../../getmember"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"

const StaticBanner = props => {
  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareUrl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  let processedImages = JSON.stringify({})
  if (props.data?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.data.imagetransforms.Banner_Image_Transforms
  }
  const location = useLocation()
  const shareUrl = location.href
  const [isPlay, setPlay] = useState(false)
  const videoid =
    props.data.Youtube_Video_URL && getVideoId(props.data.Youtube_Video_URL)
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={
          isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
        }
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={
            isCurrentEventKey ? "icon-minus-accordion " : "icon-plus-accordion"
          }
        ></i>
      </button>
    )
  }

  return (
    <React.Fragment>
      <section className="static-page-Banner community-page-banner">
        <Container>
          <ScrollAnimation
            animateOnce={true}
            className="banner-animated"
            animateIn="fadeInUp"
          >
            <div className="static-banner-content">
              <div className="inner-container">
                <h4>{props.data.Page_Heading}</h4>
                <h1>{props.data.Page_Caption}</h1>
                <div className="user-data">
                  <GetTeamMember
                    memberid={props.memberid}
                    category={props.category}
                  />

                  <div
                    className={
                      props.memberid
                        ? "social-media-banner"
                        : "social-media-banner no-banner-user"
                    }
                  >
                    <span>Share:</span>
                    <ul className="share-trans">
                      <li>
                        <FacebookShareButton
                          onClick={() => trackerShare("FacebookShareButton")}
                          url={shareUrl}
                          className="my-share-button facebook-share"
                        >
                          <FacebookIcon
                            iconFillColor="white"
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          onClick={() => trackerShare("TwitterShareButton")}
                          url={shareUrl}
                          className="my-share-button twitter-share"
                        >
                          <TwitterIcon
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton
                          onClick={() => trackerShare("LinkedinShareButton")}
                          url={shareUrl}
                          className="my-share-button linked-share"
                        >
                          <LinkedinIcon
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </LinkedinShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </Container>
      </section>
      <section className="static-article-data">
        <Container>
          <Row>
            <Col lg={12}>
              <ScrollAnimation
                animateOnce={true}
                className="banner-animated"
                animateIn="fadeIn"
              >
                {props.data.Banner_Image && (
                  <div class="image-hover column">
                    <div>
                      <figure>
                        <img
                          src={props.data.Banner_Image.url}
                          alt="people"
                          className="community-height-banner"
                          style={{ height: "750px", width: "90%" }}
                        />
                      </figure>
                      {/* <ImageTransform imagesources={props.data.Banner_Image.url} renderer="srcSet" imagename="communities.Banner_Image.commontile" attr={{ alt: props.data.Banner_Image.alternativeText?props.data.Banner_Image.alternativeText:props.Title+' -  Location Location', class:"community-height-banner"}} imagetransformresult={processedImages} id={props.data.id}/>         */}

                      {props.data.Youtube_Video_URL && (
                        <a
                          href="javascript:;"
                          className="video-arrow"
                          onClick={e => {
                            setPlay(true)
                          }}
                        >
                          <i class="icon-video-white"></i>
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
        {props.data.Youtube_Video_URL && videoid && (
          <PlayVideo
            isOpen={isPlay}
            isCloseFunction={setPlay}
            videoId={props?.data?.Youtube_Video_URL}
            isAutoPlay={1}
          />
        )}
      </section>
    </React.Fragment>
  )
}
export default StaticBanner
