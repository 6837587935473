import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react"
import GETCTA from "./../../../Components/getcta"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./ContactTeam.scss"

import Modal from "react-bootstrap/Modal"
import RegisterForm from "./../../../Components/forms/register-with-us"
import RegisterFormSell from "./../../../Components/forms/register-with-sell"
import RegisterFormRent from "./../../../Components/forms/register-with-rent"
import RegisterFormLandlord from "./../../../Components/forms/register-with-landlord"
//search-bottom-image
import SearchBottomImgDesktop from "../../../images/Static/contact-team-bottom-d.jpg"
import SearchBottomImgTablet from "../../../images/Static/contact-team-bottom-t.jpg"
import SearchBottomImgMobile from "../../../images/Static/contact-team-bottom-m.jpg"
import ReactMarkdown from "react-markdown/with-html"
import {
  inViewOptions,
  easeFunc,
  containerVariants,
  delayItemsFromBottom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const contentItemText = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
const ContactTeam = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="contact-team bottom-team-module full"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <div className="container-custom">
              <div className="search-bottom-block">
                <motion.div
                  className="search-bottom-image"
                  variants={delayItemsFromBottom}
                >
                  <div>
                    <picture>
                      <source
                        media="(min-width:992px)"
                        srcSet={props.Image.url}
                      />
                      <source
                        media="(min-width:768px)"
                        srcSet={props.Image.url}
                      />
                      <img
                        src={props.Image.url}
                        alt="Take the stress out of moving home -  Location Location"
                      />
                    </picture>
                  </div>
                </motion.div>
                <div
                  // animateIn="fadeInUp"
                  className="search-bottom-content"
                >
                  <div>
                    <motion.h3 variants={contentItemText}>
                      {props.Title}
                    </motion.h3>
                    <motion.div variants={contentItemText}>
                      <ReactMarkdown
                        source={props.Content}
                        escapeHtml={false}
                      />
                    </motion.div>

                    <motion.div variants={contentItemText}>
                      {props.Page_CSS_Class === "landlordpage" ||
                      props.Page_CSS_Class === "rentpage" ? (
                        <a
                          href="javascript:;"
                          class="btn btn-yellow"
                          onClick={() => handleShow()}
                        >
                          Let's Talk
                        </a>
                      ) : (
                        <GETCTA
                          link={props.CtaUrl?.Primary_URL}
                          class="btn btn-yellow"
                          Label={props.CtaLabel}
                        />
                      )}
                    </motion.div>
                  </div>
                  {/* <Link className="btn btn-yellow" to="/purpose/our-people">
                {props.CtaLabel}
              </Link> */}
                </div>
              </div>
            </div>
            <Modal
              show={show}
              centered={true}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
            >
              <Modal.Header closeButton className="contact-close-btn">
                <Modal.Title className="w-100">Hello you...</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {props.Page_CSS_Class === "buypage" ? (
                  <p>
                    Tired of scrolling through property listings?
                    <br />
                    Fill in the form below and we’ll help you find your next
                    dream home.
                  </p>
                ) : props.Page_CSS_Class === "rentpage" ? (
                  <p>
                    Tired of scrolling through property listings?
                    <br />
                    Fill in the form below and we’ll help you find your next
                    dream home.
                  </p>
                ) : props.Page_CSS_Class === "sellpage" ? (
                  <p>
                    Not quite sold on our services yet?
                    <br />
                    Fill in the form below and speak to one of our rockstars.
                  </p>
                ) : props.Page_CSS_Class === "landlordpage" ? (
                  <p>
                    Need to hire a hero to protect your asset?
                    <br />
                    Fill in the form below and a member of the team will be in
                    touch to discuss your needs and requirements.
                  </p>
                ) : null}
                {props.Page_CSS_Class === "buypage" ? (
                  <RegisterForm Page_CSS_Class={props.Page_CSS_Class} />
                ) : props.Page_CSS_Class === "rentpage" ? (
                  <RegisterFormRent Page_CSS_Class={props.Page_CSS_Class} />
                ) : props.Page_CSS_Class === "sellpage" ? (
                  <RegisterFormSell Page_CSS_Class={props.Page_CSS_Class} />
                ) : props.Page_CSS_Class === "landlordpage" ? (
                  <RegisterFormLandlord Page_CSS_Class={props.Page_CSS_Class} />
                ) : null}
              </Modal.Body>
            </Modal>
          </motion.section>
        )}
      </InView>
    </React.Fragment>
  )
}
export default ContactTeam
