import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./AreaGuides.scss";
import "react-multi-carousel/lib/styles.css";
import PlayVideo from '../../Play/PlayVideo';
import getVideoId from 'get-video-id';
import {OfficeBaseURL} from '../../utils'
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform";
import { isIOS, isMobile } from "react-device-detect";
import ReactPlayer from 'react-player';
import {Close} from '../../icons';
const AreaGuides = (props) => {
  const [isPlay,setPlay] = useState(true);
  const [videoID,SetvideoID] = useState(false);
  const [isPlaying,setPlaying] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const vidRef = useRef(null);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }
  return (
    <React.Fragment>
      <section className="area-guides  areaguide-listing-wrapper">
                <div className="area-guide-video-carousel">
    <Container>
      <Row>
          {props.data && props.data.map((office, index) => {
            var incekey =  index+1;
            var delaying = incekey+'00';
            let processedImages = JSON.stringify({});
            if (office?.imagetransforms?.Office_Tile_Image_Transforms) {
            processedImages = office.imagetransforms.Office_Tile_Image_Transforms;
            }	
            return<>
            <Col xl={6} md={6} xs={12} className="mbot" key={index}>
              <div className="item">
                <ScrollAnimation offset={!isMobile?1000:''} duration={0.5} animateIn="fadeInUp" animateOnce={true}>                                  
                <div className="video-img-box">
                  <div className="video-img-wrapper inline-video-wrapper">
                  <Link to={`${OfficeBaseURL}/${office.URL}`}>
                  <ImageTransform imagesources={office.Office_Tile_Image && office.Office_Tile_Image.url} renderer="srcSet" imagename="our-offices.Office_Tile_Image.commontile" attr={{ class:"ofc-img", alt: office.Office_Tile_Image.alternativeText?office.Office_Tile_Image.alternativeText:office.Name+' -  Location Location'}} imagetransformresult={processedImages} id={office.id}/>  
                  </Link>
    
                  {isPlay && office.Youtube_Video_URL &&  videoID === office.id &&
                  <div className="inline-video">
                  <div className="close-inline-video">
                    <a onClick={e => {setPlay(false);}}>
                      <Close/>
                    </a>
                  </div>
                  <ReactPlayer onPlay={trackerVideo(office.Name)}  ref={vidRef} autoplay url={office.Youtube_Video_URL} controls={true} autoplay={true} muted={isMobile && isIOS ? true : false} playsinline={true} playing={play} width='100%' height='100%' onEnded={() => { setPlay(false); }} />
                  </div>
                  }
                  </div>
                  {office.Youtube_Video_URL &&
                  <a href="javascript:;" className="video-arrow" onClick = { e => {setPlay(true);SetvideoID(office.id);}}><i class="icon-video-white"></i></a>
                  }
                </div>
                <div className="video-carouse-content">
                  <p className="name">{office.Name}</p>
                  <p className="code"><a href={`tel:${office.Office_Phone}`}>{office.Office_Phone}</a></p>
                  <p className="description"><Link to={`/contact-location-location-london/?oid=${office.id}`}>{office.Office_Email}</Link></p>
                </div> 
                </ScrollAnimation>
              </div>
              </Col>
              </>
              })}                                                      
            </Row>
        </Container>  
          </div>
      </section>
    </React.Fragment>
  );
};

export default AreaGuides;
