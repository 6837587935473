import React, { useEffect, useState, useCallback } from "react"
import { graphql } from "gatsby"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import MarketingBanner from "../Components/PropertyDetails/PropertyDetailsBanner/MarketingBannerFullpage"
import MarketingSlides from "../Components/PropertyDetails/KeyFeatures/MarketingSlides"
import {
  isIOS,
  isMobile,
  withOrientationChange,
  isTablet,
  isMobileOnly,
} from "react-device-detect"
import ReactFullpage from "@fullpage/react-fullpage"
import MarketingHeader from "../Components/PropertyDetails/KeyFeatures/marketingheader"
import FullpageSlide from "../Components/PropertyDetails/KeyFeatures/FullpageSlide"
import StickyCTAFooter from "../Components/PropertyDetails/ButtonGroupFooter/FooterStikcyCTA"
import SectionTwoColumn from "../Components/PropertyManagement/PropertyManagementSection/SectionTwoColumn"
import Plans from "../Components/PropertyManagement/PropertyManagementSection/Plans"
import $ from "jquery"
import {
  ScrollingProvider,
  useScrollSection,
  useScrollSections,
  Section,
} from "react-scroll-section"
import TransparentHeaderBanner from "../Components/PropertyManagement/PropertyManagementBanner/PropertyManagementBanner"
const FormTemplate = props => {
  const [yy, setYy] = useState(false)
  const [y, setY] = useState(0)

  const handleNavigation = useCallback(
    e => {
      if (typeof window !== "undefined") {
        const window = e.currentTarget
        if (y > window.scrollY) {
          setYy(true)
        } else if (y < window.scrollY) {
          setYy(false)
        }
        setY(window.scrollY)
      }
    },
    [y]
  )

  useEffect(() => {
    if (typeof window !== "undefined") {
      setY(window.scrollY)
    }

    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [handleNavigation])
  const DynamicMenu = props => {
    const sections = useScrollSections()

    return (
      <MarketingHeader
        sections={sections}
        Template={"fullpage"}
        GQLModules={props.GQLModules}
        path={props.path}
      />
    )
  }
  const [scrollfooter, setScrollfooter] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollfooter(window.scrollY > 150)
    })
    if (typeof window !== "undefined") {
      var idelement = window.location.hash
    }
    setTimeout(function () {
      var elmnt = document.getElementById(idelement.replace("#", ""))
      if (elmnt) {
        elmnt.scrollIntoView()
      }
    }, 10)
  }, [])
  const { isLandscape, isPortrait } = props
  const [IpadLandscape, setIpadLandscape] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [Slide1, setSlide1] = useState("DefaultMarketing")
  const [firstSlide, setFirstSlide] = useState(false)
  const [sectionIndex, setsectionIndex] = useState(0)
  const [ClientWidth, setClientWidth] = useState(false)
  const [videoOpen, setVideoOpen] = useState("")
  const [isLandscapeMode, setisLandscapeMode] = useState(false)
  const handleClickVideo = value => setVideoOpen(value)
  useEffect(() => {
    $("body").addClass("switchtofadein")
    if (isLandscape && isTablet && $(window).width() > 1200) {
      setIpadLandscape(true)
    } else {
      setIpadLandscape(false)
    }
    if (
      $(window).width() < 1367 &&
      $(window).width() > 1023 &&
      $(window).height() < 1025
    ) {
      setisLandscapeMode(true)
    } else {
      setisLandscapeMode(false)
    }
    //console.log('aaaaaaaaaaa',isLandscape,IpadLandscape);
    if ($(window).width() > 1600) {
      setClientWidth(true)
    }
  })

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules

  const handleClick = value => {
    setSlide1(value)
  }

  const closeClick = value => {
    setSlide1(value)
  }
  const FullpageSlideTitles = GQLModules.filter(
    GQLModules => GQLModules.Fullpage_Slide != null
  ).map(({ Fullpage_Slide }) => ({ Fullpage_Slide }))

  var slide_list = ["section-banner"]
  FullpageSlideTitles &&
    FullpageSlideTitles[0]?.Fullpage_Slide.map(fullpageslide =>
      slide_list.push(
        "section-" +
          fullpageslide.Slide_Label?.replace(/\s+/g, "-").toLowerCase()
      )
    )
  slide_list.push("section-footer")
  //console.log('aaaaaaaaa',slide_list)
  // const MenuRender = ({ GQLModules }) => {
  //     return (
  //       <MarketingHeader Template={'fullpage'} GQLModules={GQLModules} />
  //     )
  //   }
  return (
    <React.Fragment>
      <ScrollingProvider>
        <div
          className={`page-marketing-main ${
            GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ""
          }`}
        >
          {(scroll && !isMobile) || (scroll && IpadLandscape) ? (
            <DynamicMenu
              Template={"fullpage"}
              GQLModules={GQLModules}
              path={props.path}
            />
          ) : (scroll && isMobile) || (scroll && IpadLandscape) ? (
            <div
              className={
                scroll ? "menu-fixed menu-scroll " + Slide1 + "" : "menu-fixed"
              }
            >
              <Header />
            </div>
          ) : (scroll && !isMobile) || (scroll && IpadLandscape) ? null : (
            <div
              className={
                scroll ? "menu-fixed menu-scroll " + Slide1 + "" : "menu-fixed"
              }
            >
              <Header />
            </div>
          )}
          <div className={`marketing-wrapper fullpage-wrapper ${videoOpen}`}>
            {/* <ReactFullpage
                //fullpage options
                fitToSection={true}
                scrollBar={true}
                // verticalCentered={true}
                licenseKey={`${process.env.GATSBY_FULLPAGEJS}`}
                menu = {'#sellingmenu'}
                anchors = {slide_list}
                bigSectionsDestination={'top'}
                lockAnchors= {isMobileOnly?true:false}
                scrollingSpeed = {1000} 
                normalScrollElementTouchThreshold={250}
                fadingEffect={true}
                autoScrolling={true}
                normalScrollElements={'.customscrollbar'}
                // setResponsive={1000}
                
                scrollOverflow={true}
                responsiveWidth={1200}
                responsiveHeight={6000}
                onLeave={(origin,destination) => {
                    // console.log("zzzzz", destination)
                    // setTimeout(function(){ 
                    //     window.location.hash = '';
                    //     window.history.pushState("", document.title, window.location.pathname);  
                    // }, 200);
                    // setsectionIndex(destination.index)
                    if(destination.isFirst === false) {
                        setTimeout(function(){
                            setFirstSlide(true);
                        }, 100);
                        setTimeout(function(){
                            $('body').addClass('switchtofadein');
                        }, 500);
                    } else {
                        setFirstSlide(false);    
                        //$('body').removeClass('switchtofadein');                    
                    }
                    
                }}
                render={({ state, fullpageApi }) => {
                return ( */}
            <ReactFullpage.Wrapper>
              {GQLModules.map((Modules, i) => {
                // if(Modules.__typename === "GLSTRAPI_ComponentComponentsPlans"){
                //     var data = []
                //     var template;
                //     data = Modules.Plan_Details
                //     template = GQLPage.Select_Template
                // }
                // else if(Modules.__typename === "GLSTRAPI_ComponentComponentsTwoCloumnContent") {
                //     var Leftcontent;
                //     var Rightcontent;
                //     var template1;
                //     Leftcontent = Modules.Left_Column_Content
                //     Rightcontent = Modules.Right_Column_Content
                //     template1 = GQLPage.Select_Template
                // }
                return (
                  <>
                    <>
                      {Modules.__typename ===
                        "GLSTRAPI_ComponentComponentsBanner" && (
                        <div class="section">
                          {GQLPage.Title === "Property Management" ? (
                            <TransparentHeaderBanner
                              id={"section-banner"}
                              Title={GQLPage.Title}
                              BannerImage={Modules.Banner_Image}
                              VideoURL={Modules.Youtube_Video_URL}
                              bannerclass={
                                Modules.Banner_Class ? Modules.Banner_Class : ""
                              }
                              playtext={Modules.Banner_Play_Text}
                            />
                          ) : (
                            <MarketingBanner
                              id={"section-banner"}
                              Caption={GQLPage.Page_Caption}
                              handleClick={handleClick}
                              BannerImage={Modules.Banner_Image}
                              BannerContent={Modules.Banner_Content}
                              Banner_Upload_Video={Modules.Banner_Upload_Video}
                              Banner_Cta_Urls={Modules.Banner_Cta_Urls}
                              Youtube_Video_URL={Modules.Youtube_Video_URL}
                              slide_list={slide_list}
                              Page_CSS_Class={GQLPage.Page_CSS_Class}
                              Mobile_Banner={Modules.Mobile_Banner}
                            />
                          )}
                        </div>
                      )}
                      {/* {Modules.__typename === "GLSTRAPI_ComponentComponentsPlans" &&
                            <Plans data={Modules.Plan_Details} template={GQLPage.Select_Template}/>
                        } */}
                      {/* {Modules.__typename === "GLSTRAPI_ComponentComponentsTwoCloumnContent" &&
                            <SectionTwoColumn Leftcontent={Modules.Left_Column_Content} Rightcontent={Modules.Right_Column_Content} template={GQLPage.Select_Template}/>
                        } */}
                    </>
                    {Modules.__typename ===
                      "GLSTRAPI_ComponentComponentsFullpageSlider" && (
                      <>
                        <FullpageSlide
                          GQLModules={GQLModules}
                          Leftcontent={
                            GQLModules && GQLModules[1]?.Left_Column_Content
                          }
                          Rightcontent={
                            GQLModules && GQLModules[1]?.Right_Column_Content
                          }
                          data={GQLModules && GQLModules[2]?.Plan_Details}
                          template={GQLPage.Select_Template}
                          Properties_Type={GQLPage.Properties_Type}
                          menulabel={GQLPage.Title}
                          alias={GQLPage.Alias}
                          Page_CSS_Class={GQLPage.Page_CSS_Class}
                          isLandscapeMode={isLandscapeMode}
                          Select_Footer={GQLPage.Select_Footer}
                          popularSearch={GQLPage?.Select_Popular_Search}
                          handleClickVideo={handleClickVideo}
                          ClientWidth={ClientWidth}
                          closeClick={closeClick}
                          slides={Modules?.Fullpage_Slide}
                          Slide1={Slide1}
                        />
                      </>
                    )}
                  </>
                )
              })}
            </ReactFullpage.Wrapper>
            {/* );
}}
/> */}
          </div>
        </div>
        {/* <div className="valuation-steps-footer fullpage">
                <Footer 
            menulabel={GQLPage.Title}
            alias={GQLPage.Alias}
            /></div>      */}
        {isMobileOnly && scrollfooter && <StickyCTAFooter />}
      </ScrollingProvider>
    </React.Fragment>
  )
}
//App = withOrientationChange(App)

export default withOrientationChange(FormTemplate)
