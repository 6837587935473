// import {Link}  from "gatsby"
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import $ from "jquery"
const ValuationMapping = props => {
  //console.log('aaaaaaaaaaaaaa', props.addressPostalCode && props.addressPostalCode.split(" ")[0])
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig {
          Valuation_Email_Mapping_SN
          Valuation_Email_Mapping_TH
          Valuation_Email_SN
          Valuation_Email_TH
        }
      }
    }
  `)

  var valuationmapping = data.glstrapi.globalConfig
  var PostcodeSN = valuationmapping.Valuation_Email_Mapping_SN
  var PostcodeTH = valuationmapping.Valuation_Email_Mapping_TH
  var EmailSN = valuationmapping.Valuation_Email_SN
  var EmailTH = valuationmapping.Valuation_Email_TH
  var pcode = props.addressPostalCode && props.addressPostalCode.split(" ")[0]

  var matchSN = PostcodeSN
  var matchTH = PostcodeTH

  let matchValueSN = false
  let matchValueTH = false; 
 
  if (pcode) {
    var PostcodeSNArray = matchSN.split(",")
    for (var i = 0; i < PostcodeSNArray.length; i++) {
      if (PostcodeSNArray[i].match(pcode)) {
        matchValueSN = true
        $('.valuationform input[name="to_email_id"]').val(EmailSN) 
      }   
    } 
    var PostcodeTHArray = matchTH.split(",")
    for (var i = 0; i < PostcodeTHArray.length; i++) {
      if (PostcodeTHArray[i].match(pcode)) {
        $('.valuationform input[name="to_email_id"]').val(EmailTH) 
        matchValueTH = true; 
      } 
    }
  }   
  
  if(props.enqtype === "letting" && matchValueSN){ 
    $('.valuationform input[name="to_email_id"]').val("lettings@locationlocation.com") 
  }
  if(props.enqtype === "letting" && matchValueTH){ 
    $('.valuationform input[name="to_email_id"]').val(EmailTH) 
  }
  if (!matchValueTH && !matchValueSN) {
    $('.valuationform input[name="to_email_id"]').val(EmailSN) 
  }

  return (
    <>
    <input type="hidden" name="london_match" className="match_status" value={matchValueSN} />
    <input type="hidden" name="thetford_match" className="match_status" value={matchValueTH} /> 
      <empty></empty>
    </>
  )
}
export default ValuationMapping
