import React, { useEffect, useState } from "react"
import Header from "../../Components/Header/Header"
import Footer from "../../Components/Footer/Footer"
import ValuationPageComponent from "../../Components/ValuationSteps/ValuationPageComponent"
import $ from "jquery"

const ValuationSteps = props => {
  const [scroll, setScroll] = useState(false)
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules

  useEffect(() => {
    window.addEventListener("scroll", () => {
      //setScroll(window.scrollY > 30)
      if (window.scrollY > 30) {
        $(".menu-fixed").addClass("menu-scroll")
      } else {
        $(".menu-fixed").removeClass("menu-scroll")
      }
    })
  }, [])

  return (
    <React.Fragment>
      <div className={"menu-fixed menu2"}>
        <Header />
      </div>
      <ValuationPageComponent GQLPage={GQLPage} GQLModules={GQLModules} />
      <div className="valuation-steps-footer">
        <Footer
          // baseurl={"property-services/sell"}
          menulabel={GQLPage.Title}
          alias="property-valuation"
          popularSearch={GQLPage.Select_Popular_Search}
        />
      </div>
    </React.Fragment>
  )
}
export default ValuationSteps
