import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react"
import GETCTA from "./getcta"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const Landingtiles = props => {
  return (
    <React.Fragment>
      <section className="section-area landing-tiles-big">
        <div className="areaSec animate__animated">
          <Row className="align-items-lg-center">
            {props.Tiles.map((Tile, index) => {
              return (
                <>
                  <Col lg="6" className={`tile-col-wrap bigtilewrap-${index}`}>
                    <div className="tiles-big-wrapper">
                      <div className="tiles-img-wrap">
                        <Img
                          fluid={
                            Tile.Tile_Big_Image.url_sharp.childImageSharp.fluid
                          }
                          alt={
                            Tile.Tile_Big_Image.alternativeText
                              ? Tile.Tile_Big_Image.alternativeText
                              : Tile.Tile_Title +
                                " " +
                                Tile.Tile_Caption +
                                " -  Location Location"
                          }
                        />
                        <div className="overlay-tile-bg"></div>
                      </div>
                      <div className="tiles-content-inner">
                        <h1>{Tile.Tile_Title}</h1>
                        {Tile.Tile_Caption && (
                          <span className="tile-caption">
                            {Tile.Tile_Caption}
                          </span>
                        )}
                        {Tile.Tile_Phone_Number && (
                          <span className="tile-phn">
                            <a href={`tel:${Tile.Tile_Phone_Number}`}>
                              {Tile.Tile_Phone_Number}
                            </a>
                          </span>
                        )}
                        {Tile.Tile_Email_Address && (
                          <span className="tile-email">
                            <a href={`mailto:${Tile.Tile_Email_Address}`}>
                              {Tile.Tile_Email_Address}
                            </a>
                          </span>
                        )}
                        {Tile.Tile_Link ? (
                          <GETCTA
                            link={Tile.Tile_Link.Primary_URL}
                            class="btn btn-outline btntransparent"
                            Label={Tile.Tile_CTA_Label}
                          />
                        ) : (
                          <Link
                            to={Tile.Tile_Custom_Link}
                            className="btn btn-outline btntransparent"
                          >
                            {Tile.Tile_CTA_Label}
                          </Link>
                        )}
                        {/* {Tile.Tile_Intsagram_Link && (
                          <a
                            target="_blank"
                            href={Tile.Tile_Intsagram_Link}
                            className="btn btn-outline btntransparent instalink"
                          >
                            <i className="icon-social-foot icon-instagram"></i>{" "}
                            locationlocationlondon
                          </a>
                        )} */}

                        <div className="footer">
                          <div className="footer-social-icons">
                            <ul className="social-icons-list">
                              <li>
                                <a
                                  href={`${Tile.Tile_Facebook_Link}`}
                                  target="_blank"
                                >
                                  <i className="icon-social-foot icon-facebook"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="ft-sco"
                                  href={`${Tile.Tile_Twitter_Link}`}
                                  target="_blank"
                                >
                                  <i className="icon-social-foot icon-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href={`${Tile.Tile_Intsagram_Link}`}
                                  target="_blank"
                                >
                                  <i className="icon-social-foot icon-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href={`${Tile.Tile_Youtube_Link}/`}
                                  target="_blank"
                                >
                                  <i className="icon-social-foot icon-youtube"></i>
                                </a>
                              </li>

                              <li>
                                <a
                                  href={`${Tile.Tile_Linkedin_Link}`}
                                  target="_blank"
                                >
                                  <i className="icon-social-foot icon-linkedin"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href={`${Tile.Tik_Tok_Link}`}
                                  target="_blank"
                                >
                                  <i className="icon-social-foot icon-tiktok"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              )
            })}
          </Row>
        </div>
      </section>
    </React.Fragment>
  )
}
export default Landingtiles
