import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown"
import { Scrollbars } from 'react-custom-scrollbars-2';
import { isIOS, isMobile } from "react-device-detect";
const Content = (props) => {
  const [isClient, setClient] = useState(false);
    const [active, setActive] = useState(true);
    const handleAccordionClick = () => {
      if (active) {
          setActive(false)
      } else {
          setActive(true)
      }
  }
  if (props.Content && props.Content.length < 500) {
    var long_desc_first_part = props.Content;
  }
  else {
    var long_desc_first_part = props.Content ? props.Content.split(/\s+/).slice(0, 50).join(" ") : '';
  }
  useEffect(() => {
    setClient(true);
  }, [])
  return (
      <>
      {isClient && active ?
        <ReactMarkdown source={props.Content?.length > 500 ? long_desc_first_part+'...' : long_desc_first_part} allowDangerousHtml /> 
        :
        <>
        {props.isScroll ?  
        <Scrollbars  style={{ width: '', height: 245 }}>
           <div className="customscrollbar">
        <ReactMarkdown source={props.Content} allowDangerousHtml /> 
     </div>
         </Scrollbars>
        : 
        <ReactMarkdown source={props.Content} allowDangerousHtml />  
      }
        </>
        
  }
        {isClient && props.Content?.length > 500 && <a className="read-more custom-link" onClick={handleAccordionClick}>{active ? <i class="icon-plus"></i> : <i class="icon-minus"></i>} {active ? 'Read more' : 'Read less'}</a>}
        </> 
)
}

export default Content;