import React from "react"
import { Container } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import GETCTA from "../getcta"
import "./ConfidentServiceBlock.scss"
import ScrollAnimation from "react-animate-on-scroll"
import Slider from "react-slick"
import { Link } from "@StarberryUtils"

const ConfidentServiceBlock = props => {
  const Services = props.Module.Service_Block_Content

  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  }

  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
      <div className="service_block_wrapper">
        <Container>
          <div className="title_block">
            {props.Module.Title_Content
              ? HTMLReactParser(props.Module.Title_Content)
              : null}
          </div>
          <div className="service_block">
            <Slider {...settings}>
              {Services.map((item, index) => {
                return (
                  <div className="service_card">
                    <div className="service_image">
                      <img src={item.Image.url} alt="image" />
                    </div>
                    <div className="service_card_content">
                      {item.Description_Content
                        ? HTMLReactParser(item.Description_Content)
                        : null}

                      <GETCTA
                        link={item.Cta_Link.Primary_URL}
                        class="link"
                        Label={item.Cta_Label}
                      />
                      {/* <Link className="link" to="/">
                        {item.Cta_Label}
                      </Link> */}
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </Container>
      </div>
    </ScrollAnimation>
  )
}

export default ConfidentServiceBlock
