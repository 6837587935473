import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap"
import "./Steps.scss"
import CalendarBlock from "../CalendarBlock/CalendarBlock"
import userImgDesk from "../../../images/Static/stamp-img.png"
import userImgTab from "../../../images/Static/stamp-img.png"
import userImgMob from "../../../images/Static/stamp-img.png"
import { BackArrow } from "../../../Components/icons"
import loadable from "@loadable/component"
import $ from "jquery"
import AddressSearch from "react-loqate"
import ValuationMapping from "../../Valuationmapping"

const AddressSearchInput = props => {
  return (
    <div className="custom-float">
      <input
        name="address"
        placeholder="Start typing your address*"
        autocomplete="off"
        type="text"
        id="address"
        class="form-control"
        {...props}
        value={props.addressLabel}
        required="true"
      />
    </div>
  )
}

const Steps = props => {
  const valuationType = props.valuationtype
  const [valaddress, setValaddress] = useState("")
  const AddressLoquate = loadable(() => import("./address-search"))
  const Valpal = loadable(() => import("./valpal-result"))
  const [key, setKey] = useState("your-details")
  const [enqtype, setEnqtype] = useState("selling")
  const [tabdisable, settTabdisable] = useState(true)
  const [addressBuildingName, setAddressBuildingName] = useState("")
  const [addressBuildingNumber, setAddressBuildingNumber] = useState("")
  const [addressPostalCode, setAddressPostalCode] = useState("")
  const [addressSubBuilding, setAddressSubBuilding] = useState("")
  const [addressStreet, setAddressStreet] = useState("")
  const [valpalsell, setValpalSell] = useState("")
  const [valpallet, setValpalLet] = useState("")
  const [valtiming, setValTiming] = useState("")
  const [valSteps, setValSteps] = useState("")
  const [dropdown, setDropdown] = useState("Select your contact mode")
  // const myInput = useRef()

  const handleSelect = e => {
    setDropdown(e)
  }

  if (valuationType === "Instant_Valuation") {
    $(".valuationstep2 .nav-tabs .nav-item.disabled").hide()
  } else {
    $(".valuationstep2 .nav-tabs .nav-item.disabled").show()
  }

  $(".defaultvaluationintro").hide()
  const Validatestep1 = () => {
    setValSteps("step1")

    setAddressBuildingName($("input[name=addressBuildingName]").val())
    setAddressBuildingNumber($("input[name=addressBuildingNumber]").val())
    setAddressPostalCode($("input[name=addressPostalCode]").val())
    setAddressSubBuilding($("input[name=addressSubBuilding]").val())
    setAddressStreet($("input[name=addressStreet]").val())
    var valaddress = $("input[name=address]").val()
    if (valaddress == "") {
      $(".address-group").addClass("field-invalid")
      $(".steps-error").html(
        "<div class='alert-error'><p>Please enter property address</p></div>"
      )
      $("html, body").animate(
        {
          scrollTop: $(".steps-error").offset().top - 120,
        },
        1500
      )
    } else {
      setKey("your-details")
      $(".address-group").removeClass("field-invalid")
      $(".steps-error").html("")
      $(".valuationstep1").hide()
      $(".valuationstep2").show()
      $(".defaultvaluationintro").hide()
      $(".menudesktop .menubar-desktop .mainSubmenu").hide()
      //console.log("stepsheader", $(".menudesktop").has('.stepsheader').length);
      if ($(".menudesktop").has(".stepsheader").length === 0) {
        $(".valuationform .stepsheader").insertAfter(
          ".menudesktop .menubar-desktop ul"
        )
      }
      $(".stepsheader").addClass("steponegoback")
      $(".menudesktop .stepsheader").show()
      $("html, body").animate({ scrollTop: 0 }, 1500)
    }
  }
  useEffect(() => {
    $(".steponegoback .gobackVal").click(function () {
      var valaddress = $("input[name=address]").val()

      $(".valuationstep1").show()
      $(".valuationstep2").hide()
      $(".instantvaluationsection").hide()
      $(".valuationstep3").hide()
      $(".menudesktop .menubar-desktop .mainSubmenu").show()
      $(".menudesktop .stepsheader").hide()
      $(".dateandtimesectionintro").hide()
      $(".valuationstep2 .nav-tabs .nav-item.nav-link.active").show()
      $(".steps-error").html("")
      $(".address-group").removeClass("field-invalid")
      $(".valuationform form").removeClass(" was-validated")
    })
    $(".steptwogoback .gobackVal2").click(function () {
      setKey("your-details")
      $(".stepsheader").removeClass("steptwogoback")
      $(".stepsheader").addClass("steponegoback")
      $(".stepsheader .gobackVal").show()
      $(".stepsheader .gobackVal2").css("display", "none")
      $(".valuationstep2.valuationintrostep2").show()
      $(".valuationstep3.valuationintrostep3").hide()
      $(".dateandtimesectionintro").hide()
      $(".valuationstep2 .nav-tabs .nav-item.nav-link.active").show()
    })
    $(".stepinstantvaluation .gobackVal2")
      .off()
      .on("click", function () {
        if ($(".dateandtimesectionintro").is(":visible")) {
          $(".instantvaluationsection").show()
          $(".dateandtimesectionintro").hide()
          $(".valuationstep2").hide()
          return false
        } else {
          setKey("your-details")
          $(".instantvaluationsection").hide()
          $(".valuationstep2").show()
          $(".stepsheader").removeClass("stepinstantvaluation")
          $(".stepsheader").removeClass("steponegoback")
          $(".stepsheader .gobackVal").css("display", "none")
          $(".stepsheader .gobackVal2").hide()
          $(".dateandtimesectionintro").hide()
          $(".valuationstep2 .nav-tabs .nav-item.nav-link.active").show()
        }
      })
    $(".instantdateclick").click(function () {
      $(".dateandtimesection.dateandtimesectionintro").show()
      $(".valuationstep2 .nav-tabs .nav-item").hide()
      $(".valuationstep2").show()
      $(".valuationstep2.valuationintrostep2").hide()
      setKey("date-time")
      $(".instantvaluationsection").hide()
      $(".stepsheader").removeClass("stepinstantvaluation")
      $(".stepsheader").addClass("stepinstantdate")
    })
    $(".stepinstantdate .gobackVal2").click(function () {
      $(".dateandtimesectionintro").hide()
      $(".instantvaluationsection").show()
      $(".valuationstep2").hide()
      $(".stepsheader").removeClass("stepinstantdate")
      $(".stepsheader").addClass("stepinstantvaluation")
      $(".stepsheader .gobackVal").hide()
      $(".stepsheader .gobackVal2").css("display", "none")
    })
  }, [valSteps])

  const Validatestep2 = () => {
    setValSteps("step2")

    var valname = $("input[name=name]").val()
    var valemail = $("input[name=email]").val()
    var valtel = $("input[name=telephone]").val()
    var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    // var conversationType = myInput.current.value
    var telvalidation = false
    var dropdownValue = dropdown
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    var emailvalidation = false
    if (valemail.match(mailformat)) {
      emailvalidation = true
    } else {
      emailvalidation = false
    }

    if (valtel.match(phoneNum)) {
      telvalidation = true
    } else {
      telvalidation = false
    }
    if (valname && valemail && valtel && emailvalidation == true) {
      settTabdisable(false)
      var valenqname = "Sales and Rentals"
      if (enqtype == "selling") {
        valenqname = "Sales"
      }
      if (enqtype == "letting") {
        valenqname = "Rentals"
      }
      if (enqtype == "both") {
        valenqname = "Sales and Rentals"
      }
      if (valuationType === "Instant_Valuation") {
        // $(".valuationstep2").hide()
        // $(".instantvaluationsection.instantvaluationintro h4").html(
        //   "" + valenqname + " Values"
        // )
        // $(".instantvaluationsection.instantvaluationintro h1").html(
        //   "Thanks " +
        //     valname +
        //     ". Here are your property's estimated " +
        //     valenqname +
        //     " values."
        // )
        // $(".instantvaluationsection").show()
        // $(".stepsheader .gobackVal").hide()
        // $(".stepsheader .gobackVal2").css("display", "flex")
        // $(".stepsheader").addClass("stepinstantvaluation")
        // $(".stepsheader").removeClass("stepinstantdate")
        // $(".stepsheader").removeClass("steponegoback")
        // $(".steps-error").html("")
        // $(".address-group").removeClass("field-invalid")
        // $(".valuationform form").removeClass(" was-validated")
      } else {
        $(".stepsheader").removeClass("steponegoback")
        $(".stepsheader .gobackVal").hide()
        $(".stepsheader .gobackVal2").css("display", "flex")
        $(".stepsheader").addClass("steptwogoback")
        $(".valuationstep2.valuationintrostep2").hide()
        $(".valuationstep3.valuationintrostep3 h1").html(
          "Thanks " + valname + ". Now select your preferred time and date."
        )
        $(".valuationstep3.valuationintrostep3").show()
        setKey("date-time")
        $(".steps-error").html("")
      }
      $("html, body").animate({ scrollTop: 0 }, 1500)
    } else {
      $(".valuationform form").addClass(" was-validated")
      $(".steps-error").html(
        "<div class='alert-error'><p>Highlighted fields are required</p></div>"
      )
      $("html, body").animate(
        {
          scrollTop: $(".steps-error").offset().top - 120,
        },
        1500
      )
    }
  }
  const handleTabChange = k => {
    setKey(k)
    if (key === "your-details") {
      $(".valuationstep2.valuationintrostep2").hide()
      $(".valuationstep3.valuationintrostep3").show()
      $(".stepsheader .gobackVal").hide()
      $(".stepsheader .gobackVal2").css("display", "flex")
      $(".stepsheader").removeClass("steponegoback")
      $(".stepsheader").addClass("steptwogoback")
    }
    if (key === "date-time") {
      $(".valuationstep2.valuationintrostep2").show()
      $(".valuationstep3.valuationintrostep3").hide()
      $(".stepsheader .gobackVal").css("display", "flex")
      $(".stepsheader .gobackVal2").hide()
      $(".stepsheader").removeClass("steptwogoback")
      $(".stepsheader").addClass("steponegoback")
    }
  }
  const handleEnqTabChange = k => {
    setEnqtype(k)
  }
  const handleAddress = address => {
    document.getElementById("address").value = address.Label.replace(
      /\n/g,
      ", "
    )
    document.getElementById("addressBuildingName").value = address.BuildingName
    document.getElementById("addressBuildingNumber").value =
      address.BuildingNumber
    document.getElementById("addressPostalCode").value = address.PostalCode
    document.getElementById("addressSubBuilding").value = address.SubBuilding
    document.getElementById("addressStreet").value = address.Street
  }

  const handleChange = () => {
    var valname = $("input[name=name]").val()
    var vallname = $("input[name=lname]").val()
    var valemail = $("input[name=email]").val()
    var valtel = $("input[name=telephone]").val()
    var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    var telvalidation = false
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    var emailvalidation = false
    if (valemail.match(mailformat)) {
      emailvalidation = true
    } else {
      emailvalidation = false
    }

    if (valtel.match(phoneNum)) {
      telvalidation = true
    } else {
      telvalidation = false
    }
    if (valname && vallname && valemail && valtel && emailvalidation == true) {
      $(".steps-error").html("")
      $(".address-group").removeClass("field-invalid")
      $(".valuationform form").removeClass(" was-validated")
    }
  }

  return (
    <React.Fragment>
      <ValuationMapping
        enqtype={enqtype}
        addressPostalCode={addressPostalCode}
      />
      <div className="stepsheader">
        <div className="nav-links">
          <li className="gobackVal">
            <BackArrow />
            Go back
          </li>
          <li className="gobackVal2">
            <BackArrow />
            Go back
          </li>
          <li>
            <div className="user-data">
              <div className="banner-user-details">
                {/* <div className="banner-user-img">
                  <picture>
                    <source media="(min-width:992px)" srcSet={userImgDesk} />
                    <source media="(min-width:768px)" srcSet={userImgTab} />
                    <img src={userImgMob} alt="" />
                  </picture>
                </div> */}
                <div className="banner-user-content">
                  <span className="name">
                    {/* <a className="val-chat">Live Chat</a> or  */}
                    call: <a href={`tel:0207 923 9222`}>0207 923 9222</a>
                  </span>
                </div>
              </div>
            </div>
          </li>
        </div>
      </div>

      <input
        id="addressBuildingName"
        type="hidden"
        name="addressBuildingName"
      />
      <input
        id="addressBuildingNumber"
        type="hidden"
        name="addressBuildingNumber"
      />
      <input id="addressPostalCode" type="hidden" name="addressPostalCode" />
      <input id="addressSubBuilding" type="hidden" name="addressSubBuilding" />
      <input id="addressStreet" type="hidden" name="addressStreet" />

      {valuationType === "Instant_Valuation" && props.token && (
        <div className="instantvaluationsection">
          <Valpal
            enqtype={enqtype}
            addressBuildingName={addressBuildingName}
            addressBuildingNumber={addressBuildingNumber}
            addressPostalCode={addressPostalCode}
            addressSubBuilding={addressSubBuilding}
            addressStreet={addressStreet}
            valpalsell={valpalsell}
            valpallet={valpallet}
            setKey={setKey}
            token={props.token}
            handleValpalResult={props.handleValpalResult}
          />
        </div>
      )}
      <div className="valuationstep1">
        <div className="tab-box val-tab-address">
          <input type="hidden" name="enquirytype" value={enqtype} />
          <Tabs
            onSelect={k => handleEnqTabChange(k)}
            defaultActiveKey="selling"
            id="uncontrolled-tab-example"
          >
            <Tab eventKey="selling" title="Selling">
              <div className="tab-box-form address-group">
                <div className="form-input-content">
                  <Form.Group>
                    <div className="addon-box">
                      <span className="addon-tab-input">
                        <i className="icon-map-marker-form"></i>
                      </span>
                      <AddressSearch
                        locale="en_GB"
                        apiKey={process.env.GATSBY_LOQATE_API_KEY}
                        countries={["GB"]}
                        components={{ Input: AddressSearchInput }}
                        className="address-search-box"
                        inputClassname="address form-control"
                        listClassname="address-options"
                        listItemClassname="address-option"
                        onSelect={address => handleAddress(address)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Text className="d-none">
                    or <Link href="#">Enter your address manually</Link>
                  </Form.Text>
                  <Button onClick={() => Validatestep1()} variant="yellow">
                    Confirm
                  </Button>
                </div>
                <Form.Text className="d-none">
                  or <Link href="#">Enter your address manually</Link>
                </Form.Text>
              </div>
            </Tab>
            <Tab eventKey="letting" title="LETTING"></Tab>
            <Tab eventKey="both" title="Both"></Tab>
          </Tabs>
        </div>
      </div>

      <div className={`valuationstep2 ${valuationType}`}>
        <div className="tab-box tab-box2">
          <Tabs
            activeKey={key}
            onSelect={k => handleTabChange(k)}
            id="uncontrolled-tab-example"
          >
            <Tab
              eventKey="your-details"
              title="Your Details"
              disabled={tabdisable}
            >
              <div className="tab-box-form">
                <div className="tab-outline-buttons d-none">
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeIn"
                  >
                    <Link className="btn btn-outline" href="#" target="_blank">
                      <i className="icon-facebook-form"></i>{" "}
                      <span>Continue with Facebook</span>
                    </Link>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeIn"
                  >
                    <Link className="btn btn-outline" href="#" target="_blank">
                      <i className="icon-google-form"></i>{" "}
                      <span>Continue with Google</span>
                    </Link>
                  </ScrollAnimation>
                </div>
                <div className="tab-form-head d-none">
                  <span>or fill out your details</span>
                </div>
                <div className="form-w-label">
                  <Form.Group>
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="name"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="lname"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      name="email"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Telephone Number*</Form.Label>
                    <Form.Control
                      name="telephone"
                      required
                      type="number"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </Form.Group>

                  {valuationType === "Virtual_Valuation" && (
                    <>
                      <Form.Group>
                        <Form.Label>Conversation through</Form.Label>
                      </Form.Group>

                      <Form.Control
                        className="social-medias-list"
                        type="select"
                        name="conversation"
                        // ref={myInput}
                        as="select"
                        custom
                        onChange={handleSelect}
                      >
                        <option value="zoom">Zoom</option>
                        <option value="facetime">Facetime</option>
                        <option value="whatsapp">Whatsapp</option>
                        <option value="Google Meet">Google Meet</option>
                      </Form.Control>
                    </>
                  )}
                  {valuationType === "Instant_Valuation" ? (
                    <Button
                      type="submit"
                      variant="yellow"
                      onClick={() => Validatestep2()}
                      className="w-md-100 mtop-btn "
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="yellow"
                      onClick={() => Validatestep2()}
                      className="w-md-100 mtop-btn "
                    >
                      Continue
                    </Button>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="date-time" title="Date & Time" disabled={tabdisable}>
              <div className="tab-box-form calender-container">
                <CalendarBlock valuationType={valuationType} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <div className="valuationstep3">
        <div className="tab-box tab-box2 card-box">
          <div className="tab-box-form">
            <div className="cardbox-content">
              <ScrollAnimation
                animateOnce={true}
                duration={0.5}
                animateIn="fadeInDown"
              >
                <p>
                  Your request has been sent to our team and we will contact you
                  shortly to confirm your appointment and explain the valuation
                  process in more detail. Lorem ipsum dolor sit amet
                  consectetur.
                </p>
              </ScrollAnimation>
              <div className="what-to-do-text">
                <ScrollAnimation
                  animateOnce={true}
                  duration={0.5}
                  animateIn="fadeInUp"
                >
                  <h6>What to do next?</h6>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                </ScrollAnimation>
              </div>
              <div className="tab-outline-buttons btn-outline-group2">
                <ScrollAnimation
                  animateOnce={true}
                  duration={0.5}
                  animateIn="fadeInUp"
                >
                  <Link className="btn btn-outline" href="#" target="_blank">
                    <span>Find a Property</span>
                  </Link>
                </ScrollAnimation>
                <ScrollAnimation
                  animateOnce={true}
                  duration={0.5}
                  animateIn="fadeInUp"
                >
                  <Link
                    className="btn btn-outline mb-0"
                    href="#"
                    target="_blank"
                  >
                    <span>Contact Us</span>
                  </Link>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Steps
