import { Link } from "@StarberryUtils"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./HomeIntro.scss"
import ScrollAnimation from "react-animate-on-scroll"
import ReactMarkdown from "react-markdown/with-html"
import { IconMinus } from "../../icons"
import { Scrollbars } from "react-custom-scrollbars-2"
import {
  inViewOptions,
  easeFunc,
  containerVariants,
  fadeInFromRight,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const contentVariants = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
const HomeIntro = props => {
  const [active, setActive] = useState("Learn More")
  // const [long_desc_first_part, setlong_desc_first_part] = useState();
  // const [long_desc_second_part, setlong_desc_second_part] = useState();
  const handleAccordionClick = () => {
    if (active === "Learn More") {
      setActive("Learn Less")
    } else {
      setActive("Learn More")
    }
  }

  if (props.Content && props.Content.length < 450) {
    var long_desc_first_part = props.Content
  } else {
    var long_desc_first_part = props.Content
      ? props.Content.split(/\s+/).slice(0, 50).join(" ") + "..."
      : ""
  }

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="home-intro"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Container>
              {/* <ScrollAnimation offset={0} animateOnce={true} animateIn="fadeInUp"> */}
              <div className="intro-content-wrap">
                {long_desc_first_part != null && long_desc_first_part != "" && (
                  <>
                    <motion.div variants={contentVariants}>
                      <ReactMarkdown
                        source={
                          active === "Learn More"
                            ? long_desc_first_part
                            : props.Content
                        }
                        allowDangerousHtml
                      />{" "}
                    </motion.div>
                  </>
                )}
              </div>

              {/* {props.Content.length > 450 && ( */}
                <motion.div
                  className="btn-block-home-intro"
                  variants={contentVariants}
                >
                  <Link to="/purpose" className="read-more">
                    {active === "Learn More" ? (
                      <i className="icon-plus"></i>
                    ) : (
                      <div className="icon-minus">
                        <IconMinus />
                      </div>
                    )}
                    <Link to="/purpose">
                      <span class="btn-text-link">{active}</span>
                    </Link>
                  </Link>
                </motion.div>
              {/* )} */}
              {/* </ScrollAnimation> */}
            </Container>
          </motion.section>
        )}
      </InView>
    </React.Fragment>
  )
}

export default HomeIntro
