import React, {useEffect} from "react";
import $ from "jquery";
import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "react-bootstrap";
import "./HomeBanner.scss";
import ReactMarkdown from "react-markdown/with-html";
import { Link } from "@StarberryUtils";
import GETCTA from '../../getcta'
import { inViewOptions, contentItemStagger, contentItem } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
const HomeBanner = (props) => {
  // useEffect(() => {
  //   $("a.property-search.btn.btn-outline.btnproperty").click(function() {
  //     $("html, body").scrollTop(0);
  //   });
  // });
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          // className="static-banner"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
    <React.Fragment>
      <div className={`homebanner ${props.Pageclass}`}>
        <Container>
          <div className="center-bk">
          <div className="homebanner-content">
            {/* <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp" duration={0.5}> */}
              {props.Caption &&
                <>
                  <motion.h4 variants={contentItem}>{props.Caption}</motion.h4>
                </>
              }
              {props.Heading ? <motion.h1 variants={contentItem}>{props.Heading}</motion.h1>                : null}
              {props.Intro_Heading_With_Font ? <motion.div variants={contentItem}><ReactMarkdown source={props.Intro_Heading_With_Font} escapeHtml={false}/></motion.div>                : null}

              <motion.div variants={contentItem}><ReactMarkdown source={props.Content} escapeHtml={false}/></motion.div>
              {props.Ctas &&
              <motion.div variants={contentItem}>
              <div className="btn-groups">
              {props.Ctas.map((Cta) => {
                return<>
                  {Cta.Custom_Link?
                  <Link className={`${Cta.CTA_Label.replace(/\s+/g, '-').toLowerCase()} btn btn-outline btnproperty`} to={Cta.Custom_Link}>
                  {Cta.CTA_Label}
                  </Link>:
                  <GETCTA link={Cta.CTA_Link && Cta.CTA_Link.Primary_URL} class="btn btn-outline btnproperty" Label={Cta.CTA_Label}/>
                  }
                </>
              })}                
              </div>
              </motion.div>
              }
            {/* </ScrollAnimation> */}
          </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
    </motion.section>
      )}
    </InView>
  );
};

export default HomeBanner;
