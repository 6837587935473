import React, { useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import "./StaticBanner.scss"
import { Link } from "@StarberryUtils"
import GETCTA from "../../getcta"
import ReactMarkdown from "react-markdown/with-html"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const BREADCRUMB1 = gql`
  query NewsListing($URL: String!) {
    allMenus(where: { Primary_URL: $URL }) {
      Label
      Primary_URL
      Main_Parent {
        Label
        Primary_URL
      }
      Sub_Parent {
        Label
        Primary_URL
      }
    }
  }
`
const BREADCRUMB = gql`
  query NewsListing {
    allMenus {
      Label
      Primary_URL
      Main_Parent {
        Label
        Primary_URL
      }
      Sub_Parent {
        Label
        Primary_URL
      }
    }
  }
`

const StaticBanner = props => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
    function handleScroll() {
      let element = document.querySelector(".static-banner-content")
      if (element) {
        let reactElement = element.getBoundingClientRect().bottom
        if (reactElement - 100 > 0) {
          document
            .querySelector(".child-pages")
            .classList.remove("secondary-nav-fixed")
        } else {
          document
            .querySelector(".child-pages")
            .classList.add("secondary-nav-fixed")
        }
      }
    }
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  if (typeof window !== "undefined") {
    var pageURL = window.location.href.split("#")[0]
      ? window.location.href.split("#")[0]
      : ""
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1")
    var currenturl = removeSlash.substr(removeSlash.lastIndexOf("/") + 1)
  }
  const { loading, error, data } = useQuery(BREADCRUMB)
  const {
    loading: one_loading,
    error: one_error,
    data: one_data,
  } = useQuery(BREADCRUMB1, { variables: { URL: currenturl } })
  if (one_loading) return <p className="d-none">Loading ...</p>
  if (loading) return <p className="d-none">Loading ...</p>
  var menuparent = one_data?.allMenus && one_data?.allMenus[0]

  return (
    <React.Fragment>
      <section className={`static-page-Banner ${props.template.toLowerCase()}`}>
        <Container>
          <div className="static-banner-content">
            <div className="inner-container">
              <h4>{props.Caption}</h4>
              <h1>{props.Heading}</h1>
              <ReactMarkdown source={props.Content} escapeHtml={false} />
              {props.Ctas && (
                <div className="btn-groups">
                  {props.Ctas.map(Cta => {
                    return (
                      <>
                        {Cta.Custom_Link ? (
                          <Link href={Cta.Custom_Link} class="privacy outline">
                            {Cta.CTA_Label}
                          </Link>
                        ) : (
                          <GETCTA
                            link={Cta.CTA_Link && Cta.CTA_Link.Primary_URL}
                            class="privacy outline"
                            Label={Cta.CTA_Label}
                          />
                        )}
                      </>
                    )
                  })}
                </div>
              )}
              {/* {menuparent && menuparent.Main_Parent && menuparent.Sub_Parent && 
               <div className="child-pages d-none">
                               <div className="btn-groups">

                         <Container>

               <>
                             {data?.allMenus?.map((item) => {
                              if(item.Main_Parent && item.Sub_Parent && item.Main_Parent.Primary_URL === menuparent.Main_Parent.Primary_URL && item.Sub_Parent.Primary_URL === menuparent.Sub_Parent.Primary_URL && item.Primary_URL ===menuparent.Primary_URL ) {
                                return<>
                                <Link className="sub-links activemenu" >{item.Label}</Link>
                                </>
                                }
                               if(item.Main_Parent && item.Sub_Parent && item.Main_Parent.Primary_URL === menuparent.Main_Parent.Primary_URL && item.Sub_Parent.Primary_URL === menuparent.Sub_Parent.Primary_URL && item.Primary_URL !==menuparent.Primary_URL ) {
                               return<>
                               <Link className="sub-links" to={`/${item.Main_Parent.Primary_URL}/${item.Sub_Parent.Primary_URL}/${item.Primary_URL}`}>{item.Label}</Link>
                               </>                               
                               }

                             })}
              </>
              </Container>
</div>
              </div>
               } */}
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}
export default StaticBanner
