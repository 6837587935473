import React from "react"
import { Container } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import ScrollAnimation from "react-animate-on-scroll"
import "./TeamBlockSlider.scss"
import Slider from "react-slick"
import ImageTransform from "../Common/ggfx-client/module/components/image-transform"

const TeamBlockSlider = props => {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    speed: 1000,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          autoplay: false,
          infinite: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          autoplay: false,
          infinite: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          autoplay: false,
          infinite: false,
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const teamDetails = props.Module
  return (
    <Container className="team_block_container">
      <div className="content">
        {teamDetails.Content ? HTMLReactParser(teamDetails.Content) : null}
      </div>
      <div className="team_slider">
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
          <Slider {...settings}>
            {teamDetails.Teams_Collection.sort((a ,b) => (a.Nick_Name.localeCompare(b.Nick_Name))).map((team, index) => {
              let processedImages = JSON.stringify({})
              if (team?.imagetransforms?.Staff_Image_Transforms) {
                processedImages = team.imagetransforms.Staff_Image_Transforms
              }
              return (
                <div className="team_card">
                  <ImageTransform
                    imagesources={team.Staff_Image.url}
                    renderer="srcSet"
                    imagename="our-people.Staff_Image.commontile"
                    attr={{
                      alt: team.Staff_Image.alternativeText
                        ? team.Staff_Image.alternativeText
                        : team.Name +
                          " " +
                          team.Designation +
                          " -  Location Location",
                    }}
                    imagetransformresult={processedImages}
                    id={team.id}
                  />
                  <div className="card_content">
                    <p className="name">{team.Name}</p>
                    <p className="designantion">{team.Designation}</p>
                    <div className="nick_name">
                      <p>{team.Nick_Name}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider>
        </ScrollAnimation>
      </div>
    </Container>
  )
}

export default TeamBlockSlider
