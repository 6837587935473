import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
} from "react-bootstrap"
import "./Gallery.scss"
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import "pure-react-carousel/dist/react-carousel.es.css"
import {
  isMobileOnly,
  isTablet,
  // browserName
} from "react-device-detect"
import Slider from "react-slick"
const Gallery = props => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
      partialVisibilityGutter: 50,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: 50,
    },
  }

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <section className="gallery-blog grid-images-wrapper">
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
          {/* <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={props?.data?.length}
        infinite={true}
        isPlaying={true}
        visibleSlides={(isMobileOnly) ? 1.2 : (isTablet) ? 2.3 : 3.5}
        // className="home-page-slider-blk"
      >
        <Slider  className="react-multi-carousel-item img-only">
        {props.data.map((item, index) => ( 
        <Slide key={index} index={index} className="item">
                  <picture>
                    <source media="(min-width:992px)" srcSet={item.Grid_Image.url} />
                    <source media="(min-width:768px)" srcSet={item.Grid_Image.url} />
                    <img src={item.Grid_Image.url} alt="" />
                  </picture>                                  
              </Slide>
              ))}

        </Slider>
      </CarouselProvider> */}
          <Slider className="react-multi-carousel-item img-only" {...settings}>
            {props.data.map((item, index) => (
              <div className="item">
                <picture>
                  <source
                    media="(min-width:992px)"
                    srcSet={item.Grid_Image.url}
                  />
                  <source
                    media="(min-width:768px)"
                    srcSet={item.Grid_Image.url}
                  />
                  <img
                    src={item.Grid_Image.url}
                    alt="Work with us - Location Location"
                  />
                </picture>
              </div>
            ))}
          </Slider>
          {/* <Carousel
              responsive={responsive}
              showDots={true}
              draggable={true}
              autoPlay={true}
              swipeable={true}
              infinite={true}
              partialVisible={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {props.data.map((item, index) => ( <div className="item" key={index}>                  
                  <picture>
                    <source media="(min-width:992px)" srcSet={item.Grid_Image.url} />
                    <source media="(min-width:768px)" srcSet={item.Grid_Image.url} />
                    <img src={item.Grid_Image.url} alt="" />
                  </picture>                                  
              </div>
              ))}
              
               </Carousel> */}
        </ScrollAnimation>
      </section>
    </React.Fragment>
  )
}
export default Gallery
