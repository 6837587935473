import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import "./StaticBanner.scss"
import GETCTA from "../../getcta"
import { Link } from "@StarberryUtils"
import { IconArrow, DownArrow } from "../../icons"
import ReactMarkdown from "react-markdown/with-html"
import GoogleReviewImg from "../../../images/google-logo.png"
import { isMobile } from "react-device-detect"
import axios from "axios"
import wordsToNumbers from "words-to-numbers"
import logoBlack from "../../../images/loading-buffering.gif"
import Modal from "react-bootstrap/Modal"
import RegisterFormNewHome from "../../../Components/forms/register-with-new-home"
import $ from "jquery"
const StaticBanner = props => {
  const [showModal, setShowModal] = useState(false)

  const handleModal = () => {
    setShowModal(true)
  }

  const handleclosing = () => {
    setShowModal(false)
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }

  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const [testimonials, setTestimonials] = useState([])
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig) // could be from env files
      setTestimonials(data)
      setloading(false)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
      setloading(false)
    }
  }
  useEffect(() => {
    setloading(true)
    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url)
    $(".expand-child-menu").click(function () {
      $(".otherchilds.childmenus").slideToggle()
    })
    if (props.Subpages && props.Subpages.length > 0) {
      window.addEventListener("scroll", handleScroll, true)
      function handleScroll() {
        let element = document.querySelector(".search-banner-content")
        if (element) {
          let reactElement = element.getBoundingClientRect().bottom
          if (reactElement - 100 > 0) {
            document
              .querySelector(".child-pages")
              .classList.remove("secondary-nav-fixed")
          } else {
            document
              .querySelector(".child-pages")
              .classList.add("secondary-nav-fixed")
          }
        }
      }
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])
  const [loading, setloading] = useState(false)
  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count

  return (
    <React.Fragment>
      <section
        className={
          props.Subpages && props.Subpages.length > 0
            ? "search-banner landing-intro with-child-links"
            : "search-banner landing-intro"
        }
      >
        {loading && (
          <div className="reviews gql-loading-screen">
            <Container>
              <div className="gql-loading">
                <img
                  className="logo-white loader-logo"
                  src={logoBlack}
                  alt="logo"
                />
              </div>
            </Container>
          </div>
        )}
        {props.Subpages && props.Subpages.length > 0 && (
          <div className="child-pages-mobile">
            <div className="btn-groups">
              {props.Subpages.map((Subpage, key) => {
                return (
                  <>
                    <div
                      className={
                        key === 0
                          ? "childlinkfirst childmenus"
                          : "otherchilds childmenus"
                      }
                    >
                      {Subpage.Sub_Page_Custom_Link ? (
                        <Link
                          href={Subpage.Sub_Page_Custom_Link}
                          className="sub-links"
                        >
                          {Subpage.Sub_Page_Label}
                        </Link>
                      ) : (
                        <GETCTA
                          link={
                            Subpage.Sub_Page_Link &&
                            Subpage.Sub_Page_Link.Primary_URL
                          }
                          class="sub-links"
                          Label={Subpage.Sub_Page_Label}
                        />
                      )}
                      {key === 0 ? (
                        <a href="javascript:;" className="expand-child-menu">
                          <DownArrow />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        )}
        <Container>
          <div className="search-banner-content">
            <h4>{props.Caption}</h4>
            <h1>{props.Heading}</h1>
            {props.Content && (
              <ReactMarkdown source={props.Content} escapeHtml={false} />
            )}
            {props.showreview === true && (
              <div className="google-rating-wrap">
                <img src={GoogleReviewImg} alt="Reviews - Location Location" />
                {testimonials && testimonials.length > 0 && (
                  <div className="google-inner">
                    <span>
                      <strong>
                        {isMobile
                          ? parseFloat(rating_avg).toFixed(1) + "/5 Rating"
                          : "Rated " +
                            parseFloat(rating_avg).toFixed(1) +
                            "/5 from " +
                            testimonial_count +
                            " Customer Reviews"}
                      </strong>
                    </span>
                    <span>
                      {isMobile ? (
                        "from " + testimonial_count + " Reviews"
                      ) : (
                        <span className="yellow-with-font-small">
                          Move Happy
                        </span>
                      )}
                    </span>
                  </div>
                )}
              </div>
            )}
            {props.Ctas && props.Ctas.length > 0 && (
              <div className="btn-groups">
                {props.Ctas.map(Cta => {
                  return (
                    <>
                      {props.Pageclass === "newhomespage" &&
                      Cta.CTA_Label === "Book a Call" ? (
                        <a
                          href="javascript:;"
                          class="btn btn-outline thrid"
                          onClick={() => handleShow()}
                        >
                          Book a Call
                        </a>
                      ) : Cta.Custom_Link ? (
                        <Link
                          href={Cta.Custom_Link}
                          class="btn btn-outline btnproperty"
                        >
                          {Cta.CTA_Label}
                        </Link>
                      ) : (
                        <GETCTA
                          link={Cta.CTA_Link && Cta.CTA_Link.Primary_URL}
                          class="btn btn-outline btnproperty"
                          Label={Cta.CTA_Label}
                        />
                      )}
                    </>
                  )
                })}
              </div>
            )}
            {props.Subpages && props.Subpages.length > 0 && !isMobile && (
              <div className="child-pages">
                <Container>
                  <div className="btn-groups">
                    {props.Subpages.map(Subpage => {
                      return (
                        <>
                          {Subpage.Sub_Page_Custom_Link ? (
                            <Link
                              href={Subpage.Sub_Page_Custom_Link}
                              className="sub-links"
                            >
                              {Subpage.Sub_Page_Label}
                              <IconArrow />
                            </Link>
                          ) : (
                            <GETCTA
                              arrow="show"
                              link={
                                Subpage.Sub_Page_Link &&
                                Subpage.Sub_Page_Link.Primary_URL
                              }
                              class="sub-links"
                              Label={Subpage.Sub_Page_Label}
                            />
                          )}
                        </>
                      )
                    })}
                  </div>
                </Container>
              </div>
            )}
          </div>
          <Modal
            show={show}
            centered={true}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w modal-form-buy-sell-rent modal-form reviewPopup"
          >
            <Modal.Header closeButton className="contact-close-btn">
              <Modal.Title className="w-100">Contact Our Team</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p></p>
              <RegisterFormNewHome />
            </Modal.Body>
          </Modal>
        </Container>
      </section>
    </React.Fragment>
  )
}
export default StaticBanner
