import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import ReactMarkdown from "react-markdown/with-html"
import "./ContactTeam.scss"
import {
  inViewOptions,
  easeFunc,
  containerVariants,
  delayItemsFromBottom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import Modal from "react-bootstrap/Modal"
import CommunityForm from "../../forms/community-form"

//search-bottom-image
import SearchBottomImgDesktop from "../../../images/Static/buyer-report-bottom-d.jpg"
import SearchBottomImgTablet from "../../../images/Static/contact-team-bottom-t.jpg"
import SearchBottomImgMobile from "../../../images/Static/contact-team-bottom-m.jpg"
import { useStaticQuery, graphql } from "gatsby"
const contentItemText = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
const BlogCta = props => {
  const [showModal, setShowModal] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig {
          Blog_Page_Cta
          Blog_Page_Cta_Image {
            url
            alternativeText
          }
        }
      }
    }
  `)

  const handleModal = () => {
    setShowModal(true)
  }

  const handleclosing = () => {
    setShowModal(false)
  }

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="contact-team bottom-team-module full blog-cta"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <div className="container-custom">
              <div className="search-bottom-block buyers-report">
                <motion.div
                  className="search-bottom-image"
                  variants={delayItemsFromBottom}
                >
                  <div>
                    <picture>
                      <source
                        media="(min-width:992px)"
                        srcSet={
                          data?.glstrapi?.globalConfig?.Blog_Page_Cta_Image?.url
                        }
                      />
                      <source
                        media="(min-width:768px)"
                        srcSet={
                          data?.glstrapi?.globalConfig?.Blog_Page_Cta_Image?.url
                        }
                      />
                      <img
                        src={
                          data?.glstrapi?.globalConfig?.Blog_Page_Cta_Image?.url
                        }
                        alt="Take the stress out of moving home -  Location Location"
                      />
                    </picture>
                  </div>
                </motion.div>
                <div
                  // animateIn="fadeInUp"
                  // animateOnce="true"
                  className="search-bottom-content"
                >
                  {/* <motion.div variants={contentItemText}>
                    <ReactMarkdown
                      source={data?.glstrapi?.globalConfig?.Blog_Page_Cta}
                      escapeHtml={false}
                    />
                  </motion.div> */}
                  <motion.h3 variants={contentItemText}>
                    We love to support local businesses, schools, charities, and
                    people.
                  </motion.h3>
                  <motion.p variants={contentItemText}>
                    Know someone who’d benefit from our support?
                  </motion.p>

                  <motion.div variants={contentItemText}>
                    <a onClick={handleModal} className="btn btn-yellow">
                      Contact Us
                    </a>
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.section>
        )}
      </InView>

      <>
        <Modal
          show={showModal}
          centered={true}
          onHide={handleclosing}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w modal-form-buy-sell-rent modal-form reviewPopup"
        >
          <Modal.Header closeButton className="contact-close-btn">
            <Modal.Title className="w-100">Contact Our Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p></p>
            <CommunityForm />
          </Modal.Body>
        </Modal>
      </>
    </React.Fragment>
  )
}
export default BlogCta
