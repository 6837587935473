import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby'
import Header from "../Components/Header/Header";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from "react-animate-on-scroll";
import ReactMarkdown from "react-markdown/with-html";
import GETCTA from '../Components/getcta'
import { getSrc } from "gatsby-plugin-image"
import ReactFullpage from '@fullpage/react-fullpage';
import ValuesSlider from './values-slider';
import { Link } from "gatsby";
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap";
const pluginWrapper = () => {
  require('./fullpage.scrollOverflowReset.min');
};
const FormTemplate = (props) => {
 

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    },
  };
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  const GQLModules = props.GQLModules;

  return (
    <React.Fragment>
      <div className={scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"}><Header /></div>
      <main class="main">
        <div className="marketing-wrapper ourvalues-full-width-slider">

          <section className="property-details-banner">
    <ReactFullpage
    //fullpage options
    pluginWrapper={pluginWrapper}
    licenseKey={`${process.env.GATSBY_FULLPAGEJS}`}
    scrollingSpeed = {1000} /* Options here */
    css3={true}
    scrollOverflow={true}  /* Because we are using the extension */
    scrollOverflowReset={true}
    scrollOverflowResetKey={`${process.env.GATSBY_FULLPAGEJS_SCROLLOVERFLOW}`}
    onLeave={() => {}}
    verticalCentered={false}
    navigation={true}
    render={({ state }) => {
      return (
        <ReactFullpage.Wrapper>
      {GQLModules.map((Modules, i) => {
        return<>
          <ValuesSlider state={state} popularSearch={GQLModules?.Select_Popular_Search} slides={Modules && Modules.Our_Values_Slides}/>             
        </>
      })}
      </ReactFullpage.Wrapper>
);
}}
/>
          </section>
        </div>
      </main>
    </React.Fragment>
  )
};
export default FormTemplate;
