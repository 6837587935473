import { Link } from "@StarberryUtils"
import React, { useState, useEffect } from "react"
import ReactMarkdown from "react-markdown/with-html"
import { WhitePlayIcon } from "../../icons"
import { BookaValuationURL } from "../../utils"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./PropertyDetailsBanner.scss"
import PlayVideo from "../../Play/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll"
import { getSrc } from "gatsby-plugin-image"
import { isMobile, isMobileOnly } from "react-device-detect"
import $ from "jquery"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const PropertyDetailsBanner = props => {
  const [isPlay, setPlay] = useState(false)

  const slideClick = value => {
    props.handleClick()
  }

  const videoClick = value => {
    // props.handleClickVideo(value);
  }
  useEffect(() => {
    if (isMobile) {
      $(".chooseusbtn").click(function () {
        $("html, body").animate(
          {
            scrollTop: $("#section-side1").offset().top - 50,
          },
          1500
        )
      })
    }
  }, [])
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="property-details-banner marketing-banner-page"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
          <div className="property-banner-wrapper">
            {props.BannerVideo?.url ? (
              <div className="home-preview-video marketing-bgvideo">
                <video
                  data-autoplay
                  playsinline="true"
                  autoplay="true"
                  muted="true"
                  loop="true"
                  preload="auto"
                >
                  <source src={props.BannerVideo?.url} type="video/mp4" />
                </video>
              </div>
            ) : (
              <div>
                <picture>
                  <img
                    src={getSrc(props?.BannerImage?.url_sharp)}
                    alt={
                      props.BannerImage?.alternativeText
                        ? props?.BannerImage?.alternativeText
                        : props.Caption + " -  Location Location"
                    }
                  />
                </picture>
                <div className="marketing-overlay"></div>
              </div>
            )}
          </div>

          <div className="property-banner-content marketing-banner">
            <Container>
              {/* <div>
                {props.BannerYouTubeVideo && (
                  <motion.strong
                    variants={contentItem}
                    href="#"
                    className="video-button"
                    onClick={e => {
                      setPlay(true)
                      videoClick("video-open")
                    }}
                  >
                    <i className="icon-video-white"></i>
                  </motion.strong>
                )}
              </div> */}
              <motion.h4 variants={contentItem}>{props.Caption}</motion.h4>

              <motion.div variants={contentItem}>
                {" "}
                <ReactMarkdown
                  source={props.BannerContent}
                  escapeHtml={false}
                />
              </motion.div>
              <motion.div
                variants={contentItem}
                className="prop-banner-btn-group"
              >
                <Link
                  to={BookaValuationURL}
                  className="btn btn-yellow order-3-bt"
                >
                  Book a Valuation
                </Link>

                <a
                  href="javascript:;"
                  className="chooseusbtn btn btn-outline btnchoosemobile play-video order-2-bt"
                >
                  {props.Label}
                </a>
                <a
                  href={`#section-${props?.id
                    ?.replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  className="btn btn-outline btnchoosedektop play-video order-2-bt"
                >
                  {props.Label}
                </a>
                {props.BannerYouTubeVideo && (
                  <a
                    href="javascript:;"
                    onClick={e => {
                      setPlay(true)
                      videoClick("video-open")
                    }}
                    className="btn btn-outline btn-margin-left play-video order-1-bt"
                  >
                  <WhitePlayIcon/>  Play Video
                  </a>
                )}
              </motion.div>
            </Container>
          </div>
          {props.BannerYouTubeVideo && (
            <PlayVideo
              isOpen={isPlay}
              // isCloseFunction={setPlay}
              isCloseFunction={e => {
                setPlay()
                videoClick("")
              }}
              videoId={props.BannerYouTubeVideo}
              isAutoPlay={1}
            />
          )}
        </motion.section>
      )}
    </InView>
  )
}

export default PropertyDetailsBanner
