import React from "react"

import ReactMarkdown from "react-markdown/with-html"
import "./ContactTeam.scss"
import {
  inViewOptions,
  easeFunc,
  containerVariants,
  delayItemsFromBottom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
//search-bottom-image

import { useStaticQuery, graphql } from "gatsby"
const contentItemText = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
const ReportMaintenance = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig {
          Team_Module_Image {
            url
            alternativeText
          }
          Blog_Maintainance
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="contact-team bottom-team-module full blog-cta"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <div className="container-custom">
              <div className="search-bottom-block report-maintenance">
                <motion.div
                  className="search-bottom-image"
                  variants={delayItemsFromBottom}
                >
                  <div>
                    <picture>
                      <source
                        media="(min-width:992px)"
                        srcSet={
                          data?.glstrapi?.globalConfig?.Team_Module_Image?.url
                        }
                      />
                      <source
                        media="(min-width:768px)"
                        srcSet={
                          data?.glstrapi?.globalConfig?.Team_Module_Image?.url
                        }
                      />
                      <img
                        src={
                          data?.glstrapi?.globalConfig?.Team_Module_Image?.url
                        }
                        alt="Take the stress out of moving home -  Location Location"
                      />
                    </picture>
                  </div>
                </motion.div>
                <div
                  // animateIn="fadeInUp"
                  // animateOnce="true"
                  className="search-bottom-content"
                >
                  <motion.div variants={contentItemText}>
                    <ReactMarkdown
                      source={data?.glstrapi?.globalConfig?.Blog_Maintainance}
                      escapeHtml={false}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.section>
        )}
      </InView>
    </React.Fragment>
  )
}
export default ReportMaintenance
