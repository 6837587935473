import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Container, Row, Col} from "react-bootstrap";
import "./StaticBanner.scss";
import PlayVideo from '../../Play/PlayVideo';
import getVideoId from 'get-video-id';
import Img from 'gatsby-image';

const StaticBanner = (props) => {
  const [isPlay,setPlay] = useState(false);
  const videoid = props.VideoURL && getVideoId(props.VideoURL);

  return (
    <React.Fragment>      
      <section className={`${props.bannerclass} static-article-data landing-banner`}>
        <Container>
          <Row>
            <Col lg={12}>
            <ScrollAnimation animateOnce={true} duration={0.5} className="banner-animated" animateIn="fadeIn">
            <div className="search-results">
              <img  src={props.BannerImage.url} alt={props.BannerImage.alternativeText?props.BannerImage.alternativeText:props.Caption+' Banner -  Location Location'} />          
              {props.VideoURL !=null && props.VideoURL != '' &&
                <a href="javascript:;" className="video-arrow" onClick = { e => {setPlay(true)}}><i class="icon-video-white"></i></a>
              }
            </div>
            </ScrollAnimation>
            </Col>
          </Row>
          </Container>
          {props.VideoURL !=null && videoid && props.VideoURL != '' &&
          <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId={props.VideoURL}
              isAutoPlay={1}
            />
            }
      </section>
    </React.Fragment>
  );
};
export default StaticBanner;
