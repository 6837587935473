import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./AreaGuides.scss";
import "react-multi-carousel/lib/styles.css";
import { EmailIcon } from '../../icons'
import PlayVideo from '../../Play/PlayVideo';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import getVideoId from 'get-video-id';
import Modal from 'react-bootstrap/Modal'
import MemberContact from '../../forms/member-contact'
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform";
import { isIOS, isMobile } from "react-device-detect";
import ReactPlayer from 'react-player';
import {Close} from '../../../Components/icons';

const TEAM_LIST=  gql`
query TeamListing {   
  ourPeople (sort:"Sort:asc") {
    id
    Name
    Designation
    Nick_Name
    Email
    Staff_Video_URL
    Staff_Image {
      url
      alternativeText
    }
    imagetransforms
  } 
}
`;

const AreaGuides = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [teamid, setTeamid] = useState(false);
  const handleShow = (event) => {
    setTeamid(event);
    setShow(true);
  }
  const [isPlay,setPlay] = useState(false);
  const [videoID,setvideoID] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const vidRef = useRef(null);

  const { loading, error, data } = useQuery(TEAM_LIST);
  if (loading) return<p className="realtime_preloading">
  <div className="logo-loader">
    <span>Loading ...</span>
  </div>
</p>;
 
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }
  return (
    <React.Fragment>

      <section className="area-guides team-listing filter-gallery-section">
          <Container>
          <div className="area-guide-video-carousel filter-item-block">
          
              {data.ourPeople.map((team, index) => {
                var incekey =  index+1;
                var delaying = incekey+'00';  
                let processedImages = JSON.stringify({});
                if (team?.imagetransforms?.Staff_Image_Transforms) {
                processedImages = team.imagetransforms.Staff_Image_Transforms;
                }	 
                return<>
              <div className="item">
                <ScrollAnimation offset={!isMobile?1000:''} animateIn="fadeInUp" animateOnce={true}>                                  
                <div className="video-img-box">
                  <div className="video-img-wrapper inline-video-wrapper">
                  <ImageTransform imagesources={team.Staff_Image.url} renderer="srcSet" imagename="our-people.Staff_Image.commontile" attr={{ alt: team.Staff_Image.alternativeText?team.Staff_Image.alternativeText:team.Name+' '+team.Designation+' -  Location Location'}} imagetransformresult={processedImages} id={team.id}/> 
                  {isPlay && team.Staff_Video_URL &&  videoID === team.id &&
                  <div className="inline-video">
                  <div className="close-inline-video">
                    <a onClick={e => {setPlay(false);}}>
                      <Close/>
                    </a>
                  </div>
                  <ReactPlayer onPlay={trackerVideo(team.Name)}   ref={vidRef} autoplay url={team.Staff_Video_URL} controls={true} autoplay={true} muted={isMobile && isIOS ? true : false} playsinline={true} playing={play} width='100%' height='100%' onEnded={() => { setPlay(false); }} />
                  </div>
                  }
                  </div>
                  <a onClick={() => handleShow(team.id)} href="javascript:void(0);" className={team.Staff_Video_URL?'video-arrow email-team':'video-arrow'}>
                    <EmailIcon/>
                  </a>
                  {teamid && teamid == team.id &&
                    
                    <>
                        <Modal
                            show={show}
                            centered={true}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup">
                            <Modal.Header closeButton className="contact-close-btn">
                                <Modal.Title className="w-100">Contact - {team.Name}
                                </Modal.Title>                            
                            </Modal.Header>
                            <Modal.Body>
                              <p></p>
                              <MemberContact to_email_id={team.Email}/>
                            </Modal.Body>
                        </Modal>
                    </>
                  }
                  {team.Staff_Video_URL &&
                  <a href="javascript:;" className="video-arrow" onClick = { e => {setPlay(true);setvideoID(team.id);(getVideoId(team.Staff_Video_URL))}}><i class="icon-video-white"></i></a>
                  }
                </div>
                <div className="video-carouse-content">
                  <p className="name">{team.Name}</p>
                  <p className="code">{team.Designation}</p>
                  <p className="description">{team.Nick_Name}</p>
                </div> 
                </ScrollAnimation>
              </div>
              </>
            })}                                        
          </div>
            </Container>
      </section>
    </React.Fragment>
  );
};

export default AreaGuides;
