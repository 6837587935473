import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import ReactMarkdown from "react-markdown/with-html"
import { BookaValuationURL } from "../../utils"
import { ScrollDownIconWhite } from "../../icons"
import {
  ScrollingProvider,
  useScrollSections,
  Section,
} from "react-scroll-section"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import RegisterForm from "./../../../Components/forms/register-with-us"
import RegisterFormSell from "./../../../Components/forms/register-with-sell"
import RegisterFormRent from "./../../../Components/forms/register-with-rent"
import RegisterFormLandlord from "./../../../Components/forms/register-with-landlord"

import "./PropertyDetailsBanner.scss"
import PlayVideo from "../../Play/PlayVideo"
import { getSrc } from "gatsby-plugin-image"
import { isMobile, isMobileOnly } from "react-device-detect"
import GETCTA from "./../../../Components/getcta"
import $ from "jquery"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const PropertyDetailsBanner = props => {
  let image = props.BannerImage?.url_sharp
    ? getSrc(props.BannerImage.url_sharp)
    : ""
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  const [isPlay, setPlay] = useState(false)
  const videoClick = value => {
    // props.handleClickVideo(value);
  }
  const slideClick = value => {
    props.handleClick()
  }
  useEffect(() => {
    if (isMobile) {
      $(".chooseusbtn").click(function () {
        $("html, body").animate(
          {
            scrollTop: $("#section-side1").offset().top - 50,
          },
          1500
        )
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Section id={props.id}>
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.section
              className="property-details-banner fullpage"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
            >
              {props.Youtube_Video_URL && (
                <PlayVideo
                  isOpen={isPlay}
                  isCloseFunction={e => {
                    setPlay()
                    videoClick("")
                  }}
                  videoId={props.Youtube_Video_URL}
                  isAutoPlay={1}
                />
              )}
              <div className="property-banner-wrapper">
                {props.Banner_Upload_Video?.url ? (
                  <div className="home-preview-video">
                    <video
                      data-autoplay
                      playsinline="true"
                      autoplay="true"
                      muted="true"
                      loop="true"
                      preload="auto"
                    >
                      <source
                        src={props.Banner_Upload_Video?.url}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ) : isMobileOnly && props.Mobile_Banner ? (
                  <picture>
                    <img
                      src={props.Mobile_Banner.url}
                      alt={
                        props.Mobile_Banner?.alternativeText
                          ? props.Mobile_Banner.alternativeText
                          : props.Caption + " -  Location Location"
                      }
                    />
                  </picture>
                ) : (
                  <picture>
                    <img
                      src={image}
                      alt={
                        props.BannerImage?.alternativeText
                          ? props.BannerImage.alternativeText
                          : props.Caption + " -  Location Location"
                      }
                    />
                  </picture>
                )}
                <div className="marketing-overlay"></div>
              </div>
              <div className="property-banner-content">
                <Container>
                  {props.Youtube_Video_URL && (
                    <motion.strong
                      variants={contentItem}
                      href="#"
                      className="video-arrow"
                      onClick={e => {
                        setPlay(true)
                        videoClick("video-open")
                      }}
                    >
                      <i className="icon-video-white"></i>
                    </motion.strong>
                  )}

                  <motion.h4 variants={contentItem}>{props.Caption}</motion.h4>

                  <motion.div variants={contentItem}>
                    <ReactMarkdown
                      source={props.BannerContent}
                      escapeHtml={false}
                    />
                  </motion.div>
                  <motion.div variants={contentItem}>
                    {" "}
                    {props.Banner_Cta_Urls && (
                      <div className="prop-banner-btn-group ">
                        {props.Banner_Cta_Urls?.Btn_Label_1 === "Contact" &&
                        props.Banner_Cta_Urls?.Btn_Url_1?.Primary_URL ? (
                          <Link
                            to={
                              props.Page_CSS_Class === "buypage"
                                ? "/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london/"
                                : "/property/to-rent/in-stoke-newington-and-hackney-and-north-london-and-east-london/"
                            }
                            className="btn btn-yellow"
                          >
                            Find a Property
                          </Link>
                        ) : (
                          <GETCTA
                            link={props.Banner_Cta_Urls?.Btn_Url_1?.Primary_URL}
                            class="btn btn-yellow"
                            Label={props.Banner_Cta_Urls?.Btn_Label_1}
                          />
                        )}
                        {props.Banner_Cta_Urls?.Btn_Label_2 &&
                          props.Banner_Cta_Urls?.Btn_Url_2?.Primary_URL && (
                            <GETCTA
                              link={
                                props.Banner_Cta_Urls?.Btn_Url_2?.Primary_URL
                              }
                              class="btn btn-yellow"
                              Label={props.Banner_Cta_Urls?.Btn_Label_2}
                            />
                          )}
                        {props.Banner_Cta_Urls?.Btn_Label_1 &&
                          props.Banner_Cta_Urls?.Hash_Tag_1 && (
                            <>
                              <a
                                href={`#section-${props.Banner_Cta_Urls?.Hash_Tag_1?.replace(
                                  /\s+/g,
                                  "-"
                                ).toLowerCase()}`}
                                className="chooseusbtn btn btn-outline btnchoosemobile"
                              >
                                {props.Banner_Cta_Urls?.Btn_Label_1}
                              </a>
                              <a
                                href={`#section-${props.Banner_Cta_Urls?.Hash_Tag_1?.replace(
                                  /\s+/g,
                                  "-"
                                ).toLowerCase()}`}
                                className="btn btn-outline btnchoosedektop"
                              >
                                {props.Banner_Cta_Urls?.Btn_Label_1}
                              </a>
                            </>
                          )}
                        {props.Banner_Cta_Urls?.Btn_Label_2 &&
                          props.Banner_Cta_Urls?.Hash_Tag_2 && (
                            <>
                              <a
                                href={`#section-${props.Banner_Cta_Urls?.Hash_Tag_2?.replace(
                                  /\s+/g,
                                  "-"
                                ).toLowerCase()}`}
                                className="chooseusbtn btn btn-outline btnchooseTablet"
                              >
                                {props.Banner_Cta_Urls?.Btn_Label_2}
                              </a>
                              <a
                                href={`#section-${props.Banner_Cta_Urls?.Hash_Tag_2?.replace(
                                  /\s+/g,
                                  "-"
                                ).toLowerCase()}`}
                                className="btn btn-outline btnchoosedektop thrid"
                              >
                                {props.Banner_Cta_Urls?.Btn_Label_2}
                              </a>
                            </>
                          )}
                        {/* <a
                          href="javascript:;"
                          class="btn btn-outline thrid"
                          onClick={() => handleShow()}
                        >
                          Let's Talk
                        </a> */}
                        {props.Youtube_Video_URL && !isMobileOnly && (
                          <a
                            href="javascript:;"
                            class="btn btn-outline thrid"
                            onClick={e => {
                              setPlay(true)
                              videoClick("video-open")
                            }}
                          >
                            Play Video
                          </a>
                        )}
                      </div>
                    )}
                  </motion.div>
                </Container>
              </div>
              {/* <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId="JFqiSr9A-Go"
              isAutoPlay={1}
            /> */}
              <Modal
                show={show}
                centered={true}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
              >
                <Modal.Header closeButton className="contact-close-btn">
                  <Modal.Title className="w-100">Hello you...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {props.Page_CSS_Class === "buypage" ? (
                    <p>
                      Tired of scrolling through property listings?
                      <br />
                      Fill in the form below and we’ll help you find your next
                      dream home.
                    </p>
                  ) : props.Page_CSS_Class === "rentpage" ? (
                    <p>
                      Tired of scrolling through property listings?
                      <br />
                      Fill in the form below and we’ll help you find your next
                      dream home.
                    </p>
                  ) : props.Page_CSS_Class === "sellpage" ? (
                    <p>
                      Not quite sold on our services yet?
                      <br />
                      Fill in the form below and speak to one of our rockstars.
                    </p>
                  ) : props.Page_CSS_Class === "landlordpage" ? (
                    <p>
                      Need to hire a hero to protect your asset?
                      <br />
                      Fill in the form below and a member of the team will be in
                      touch to discuss your needs and requirements.
                    </p>
                  ) : null}
                  {props.Page_CSS_Class === "buypage" ? (
                    <RegisterForm Page_CSS_Class={props.Page_CSS_Class} />
                  ) : props.Page_CSS_Class === "rentpage" ? (
                    <RegisterFormRent Page_CSS_Class={props.Page_CSS_Class} />
                  ) : props.Page_CSS_Class === "sellpage" ? (
                    <RegisterFormSell Page_CSS_Class={props.Page_CSS_Class} />
                  ) : props.Page_CSS_Class === "landlordpage" ? (
                    <RegisterFormLandlord
                      Page_CSS_Class={props.Page_CSS_Class}
                    />
                  ) : null}
                </Modal.Body>
              </Modal>
              <a
                className="bouncing-mouse white"
                href={`#${props?.slide_list[1]
                  ?.replace(/\s+/g, "-")
                  .toLowerCase()}`}
                // href="javascript:;"
                // onClick={props.scrollDown}
              >
                <ScrollDownIconWhite />
              </a>
              {/* {JSON.stringify(props.slide_list)} */}
            </motion.section>
          )}
        </InView>
      </Section>
    </React.Fragment>
  )
}

export default PropertyDetailsBanner
