import React from "react"
import { Container } from "react-bootstrap"
import "./RecentStories.scss"
import HTMLReactParser from "html-react-parser"
import Slider from "react-slick"
import ScrollAnimation from "react-animate-on-scroll"

const RecentStores = props => {
  const stories = props.Module

  var settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  }

  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
      <div className={`recent_stories_wrapper ${stories.Background_Color === "dark" ? "bg_dark" : ""}`}>
      <Container>
        <div className="stores_heading">
          <p>{stories.Heading}</p>
        </div>
        <div className="stories">
          <Slider {...settings}>
            {stories.Success_Stores.map((item, index) => {
              return (
                <div className="story">
                  <img src={item.Image.url} alt="img" />
                  <p>{item.Title}</p>
                  <div className="content">
                    {item.Content ? HTMLReactParser(item.Content) : null}
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </Container>
      </div>
    </ScrollAnimation>
  )
}

export default RecentStores
