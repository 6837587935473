import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card} from "react-bootstrap";
import "./Gallery.scss";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import DateFormat from "../../format-date"
import {NewsBaseURL} from '../../utils'
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform";

const Gallery = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }; 

  const OTHER_NEWS=  gql`
  query NewsListing ($URL: String!, $newscat: String!){   
      ourNews  (sort:"News_Published_Date:desc",limit:3,where:{URL_ne: $URL,Select_News_Category:{URL:$newscat}}){
      id
      Title
      URL
      Tile_Image {
        url
        alternativeText
      }
      News_Published_Date
      Select_News_Category {
        Name
        URL
      }
       News_Banner_Image {
          url
        }
        News_Video_URL
        News_Content_Intro
        News_Mid_Image {
          url
        }
        News_Content_Full
        imagetransforms
    } 
  }
  `;

  const { loading, error, data } = useQuery(OTHER_NEWS, {variables: { URL: props.URL, newscat:props.newscat}});
  if (loading) return <p>Loading ...</p>;

  return (
      <>
    <React.Fragment>
      <section className="gallery">
        <Container>
        <Row>
          <Col lg={12}>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInDown">
            <h2>We’ve got lots of other great content for you...</h2>
            </ScrollAnimation>
          </Col>
        </Row>
        </Container>
      </section>
      <section className="gallery-blog other-news-section">

        <Container>

          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp" className="d-xl-none">
            <Carousel
              responsive={responsive}
              showDots={true}
              draggable={true}
              autoPlay={false}
              swipeable={true}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {data?.ourNews?.map((news, index) => {
                let processedImages = JSON.stringify({});
                if (news?.imagetransforms?.Tile_Image_Transforms) {
                processedImages = news.imagetransforms.Tile_Image_Transforms;
                }	
              return<>
              <div className="item" key={index}>
                <Link to={`/${NewsBaseURL}/${news.Select_News_Category.URL}/${news.URL}`}>   
                                                 
                <ImageTransform imagesources={news.Tile_Image.url} renderer="srcSet" imagename="our-news.Tile_Image.commontile" attr={{ alt: news.Tile_Image.alternativeText?news.Tile_Image.alternativeText:news.Title+' -  Location Location'}} imagetransformresult={processedImages} id={news.id}/>      
            
                </Link>
                <div class="cat-name">
                <span>{news.Select_News_Category.Name}</span>
                </div>
                <div className="blog-content">
                <p className="blog-date"><DateFormat date={news.News_Published_Date}/></p>
                  <p className="blog-description">
                    <Link to={`/${NewsBaseURL}/${news.Select_News_Category.URL}/${news.URL}`}>                                    
                    {news.Title}
                    </Link>
                  </p>
                </div>
              </div>
              </>
            })}
              
               </Carousel>
           </ScrollAnimation>
          </Container>
      </section>
      <section className="filter-gallery-section">
      <Container className="d-none d-xl-block">
      <div className="filter-item-block">
            {data?.ourNews?.map((news, index) => {
              let processedImages = JSON.stringify({});
              if (news?.imagetransforms?.Tile_Image_Transforms) {
              processedImages = news.imagetransforms.Tile_Image_Transforms;
              }	
              return<>
              <div className="item"><ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                <Link to={`/${NewsBaseURL}/${news.Select_News_Category.URL}/${news.URL}`}>                                    
                <div className="filter-img-block">
                  <ImageTransform imagesources={news.Tile_Image.url} renderer="srcSet" imagename="our-news.Tile_Image.commontile" attr={{ alt: news.Tile_Image.alternativeText?news.Tile_Image.alternativeText:news.Title+' -  Location Location'}} imagetransformresult={processedImages} id={news.id}/>      
                </div>
                </Link>
                <div class="cat-name">
                  <span>{news.Select_News_Category.Name}</span>
                </div>
                <div className="blog-content">
                <p className="blog-date"><DateFormat date={news.News_Published_Date}/></p>
                  <p className="blog-description">
                  <Link to={`/${NewsBaseURL}/${news.Select_News_Category.URL}/${news.URL}`}>                                    
                    {news.Title}
                    </Link>
                  </p>
                </div>
                </ScrollAnimation>
              </div>
              </>
              })}
            
          </div>
      </Container>
      
        
      </section>
    </React.Fragment>
    </>
  );
};
export default Gallery;
