import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card} from "react-bootstrap";
import "./Gallery.scss";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import DateFormat from "../../format-date"
import {CaseStudyBaseURL} from '../../utils'

const Gallery = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }; 

  const OTHER_NEWS=  gql`
  query caseStuidesListing{   
    caseStuides  (sort:"Case_Study_Date:desc",limit:3){
      Title
      URL
      Tile_Image {
        url
      }
      Case_Study_Date
     
    } 
  }
  `;

  const { loading, error, data } = useQuery(OTHER_NEWS);
  if (loading) return <p>Loading ...</p>;

  return (
      <>
    <React.Fragment>
    <div className="bg-gray">
      <section className="gallery case-studies-wrapper">
        <Container>
        <Row>
          <Col lg={12}>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInDown">
            <span class="small-heading">Case Studies</span>
            <h2 class="sub-heading">View some of our case studies below</h2>
            </ScrollAnimation>
          </Col>
        </Row>
        </Container>
      </section>
      <section className="gallery-blog other-news-section">

        <Container>

          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp" className="d-xl-none">
            <Carousel
              responsive={responsive}
              showDots={true}
              draggable={true}
              autoPlay={false}
              swipeable={true}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {data.caseStuides.map((news, index) => ( <div className="item" key={index}>
                <Link to={`/${CaseStudyBaseURL}/${news.URL}`}>                                    
                  <picture>
                    <source media="(min-width:992px)" srcSet={news.Tile_Image.url} />
                    <source media="(min-width:768px)" srcSet={news.Tile_Image.url} />
                    <img src={news.Tile_Image.url} alt="" />
                  </picture>                  
                </Link>
                <div class="cat-name">
                <span>Case Study</span>
                </div>
                <div className="blog-content">
                <p className="blog-date"><DateFormat date={news.Case_Study_Date}/></p>
                  <p className="blog-description">
                    <Link to={`/${CaseStudyBaseURL}/${news.URL}`}>                                    
                    {news.Title}
                    </Link>
                  </p>
                </div>
              </div>
              ))}
              
               </Carousel>
           </ScrollAnimation>
          </Container>
      </section>
      <section className="filter-gallery-section">
      <Container className="d-none d-xl-block">
      <div className="filter-item-block">
            {data.caseStuides.map((news, index) => ( <div className="item"><ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                <Link to={`/${CaseStudyBaseURL}/${news.URL}`}>                                    
                <div className="filter-img-block">
                <picture>
                    <source media="(min-width:992px)" srcSet={news.Tile_Image.url} />
                    <source media="(min-width:768px)" srcSet={news.Tile_Image.url} />
                    <img src={news.Tile_Image.url} alt={news.Title+' -  Location Location'} />
                </picture>
                  </div>
                </Link>
                <div class="cat-name">
                <span>Case Study</span>
                </div>
                <div className="blog-content">
                <p className="blog-date"><DateFormat date={news.Case_Study_Date}/></p>
                  <p className="blog-description">
                  <Link to={`/${CaseStudyBaseURL}/${news.URL}`}>                                    
                    {news.Title}
                    </Link>
                  </p>
                </div>
                </ScrollAnimation>
              </div>
              
              ))}
            
          </div>
      </Container>      
      </section>
      </div>
    </React.Fragment>
    </>
  );
};
export default Gallery;
