import { Link } from "gatsby"
import HTMLReactParser from "html-react-parser"
import React from "react"
import { Container } from "react-bootstrap"
import bgImg from "../../images/triangle-card.svg"
import "./ShapeBlocks.scss"
import Slider from "react-slick"
import { scrollToElement } from "../utils"

const ShapeBlocks = ({ Module }) => {
  if (!Module) return null
  const { title, description, blocks } = Module

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="shape-blocks-wrap">
      <Container className="shape-blocks-container">
        <div className="content-section">
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
        </div>
        <div className="blocks-section">
          <Slider {...sliderSettings}>
            {blocks.map((block, index) => (
              <div className="shape-block-wrap" id="shape-block-wrap">
                <div className="shape-bg">
                  <div className="shape-fill"></div>
                  <img src={bgImg} alt="shape" />
                </div>
                <div className="shape-block" key={index}>
                  <p className="title">{block.title}</p>
                  {block.description && (
                    <div className="description">
                      {HTMLReactParser(block.description)}
                    </div>
                  )}
                  {block.cta_custom?.[0] === "#" ? (
                    <button
                      className="block-btn btn btn-yellow"
                      onClick={() => {
                        const element = document.getElementById(
                          block.cta_custom.slice(1)
                        )

                        if (element) {
                          scrollToElement(element)
                        }
                      }}
                    >
                      {block.cta_label}
                    </button>
                  ) : (
                    <Link
                      to={block.cta_custom}
                      className="block-btn btn btn-yellow"
                    >
                      {block.cta_label}
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default ShapeBlocks
