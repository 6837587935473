import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import OfficeBanner from "../Components/ValuationSteps/ValuationStepsBanner/OfficeBanner"
import OfficeDetails from "../Components/ValuationSteps/CompanyInformation/OfficeDetails"
import GoogleReviewValuationStep from "../Components/ValuationSteps/GoogleReviewValuationStep/GoogleReviewValuationStep"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap"
import ContactForm2 from "../Components/ValuationSteps/Steps/ContactForm"
import MortgageCalculator from "../Components/calculators/mortgagecalc/index"
import ContactForm from "../Components/forms/contact"
import BuyersReportForm from "../Components/forms/buyers-report"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import axios from "axios"

import BuyersReportVideo from "../Components/PropertyManagement/AreaGuides/BuyersReportVideo"
const GET_OFFICE = gql`
  query officedetails($ID: String!) {
    ourOffices(where: { id: $ID }) {
      Office_Email
      Office_Name
    }
  }
`

const FormTemplate = props => {
  const location = useLocation()
  const searchParams = parse(location.search) // => {init: "true"}
  const propertyid = searchParams.pid
  var officeid = searchParams.oid
  var listid = searchParams.listid
  var type = searchParams.type
  const propertyaddress = searchParams.paddress
  const [reportdata, setReportdata] = useState("")
  const token = process.env.GATSBY_STRAPI_FORM_TOKEN

  useEffect(() => {
    getPropertyData()

    window.scrollTo(0, 0)
    //SPRIFT RESULTS
    if (propertyaddress != "") {
      //document.getElementById('validationaddress').value = property_address
      var postData = {
        address: propertyaddress,
      }

      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      axios
        .post(
          "https://locationl-strapi.q.starberry.com/property-report/generate/url",
          postData,
          axiosConfig
        )
        .then(res => {
          console.log("RESPONSE RECEIVED: ", res)
          if (res.statusText === "OK") {
            //document.getElementById('buyers_report').value = res.data.url
            setReportdata(res.data.url)
          }
        })
        .catch(err => {
          console.log("AXIOS ERROR: ", err)
        })
    }
    //SPRIFT RESULTS
  }, [])

  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  const getPropertyData = async () => {
    const property_details = await axios
      .get(`${process.env.GATSBY_STRAPI_SRC}/properties/${propertyid}`,strapiconfig)
      .then(result => {
        if (result.data) {
          //
          setBuyerData(result.data)
          sessionStorage.setItem("data", JSON.stringify(result.data))
          const url =
            typeof window !== "undefined" ? window.location.hostname : ""
          var pagepath =
            result.data?.search_type === "sales"
              ? "/property-for-sale/"
              : result.data?.search_type === "lettings"
              ? "/property-to-rent/"
              : ""
          var page_url =
            url + pagepath + result.data?.slug + "-" + result.data?._id
          sessionStorage.setItem("property_url", page_url)
          sessionStorage.setItem("spriftlink", result.data?.Spriftlink)
          sessionStorage.setItem("toemail", result.data?.office_details?.email)
          sessionStorage.setItem("area", result.data?.area)
        }
      })
      .catch(err => {})
  }
  const [scroll, setScroll] = useState(false)
  const [videoOpen, setVideoOpen] = useState("")
  const [buyerData, setBuyerData] = useState("")
  const handleClick = value => setVideoOpen(value)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
    const data = JSON.parse(sessionStorage.getItem("data"))
    setBuyerData(data)
  }, [])
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules
  const FormBanner = GQLModules.filter(
    GQLModules => GQLModules.Banner_Image != null
  ).map(({ Banner_Image }) => ({ Banner_Image }))
  const FormName = GQLModules.filter(
    GQLModules => GQLModules.Select_Form != null
  ).map(({ Select_Form }) => ({ Select_Form }))
  const VideoURL = GQLModules.filter(
    GQLModules => GQLModules.Youtube_Video_URL != null
  ).map(({ Youtube_Video_URL }) => ({ Youtube_Video_URL }))

  //console.log('GQLModules',FormName)
  var calculatorclass = ""
  if (FormName.length > 0 && FormName[0].Select_Form == "Mortgage_Calculator") {
    calculatorclass = "calculate-stamp-duty"
  }
  var officeEmail = ""
  var officeName = ""

  const { loading, error, data } = useQuery(GET_OFFICE, {
    variables: { ID: officeid },
  })
  officeEmail = data && data.ourOffices[0].Office_Email
  officeName = data && data.ourOffices[0].Office_Name

  return (
    <React.Fragment>
      <div
        className={scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"}
      >
        <Header />
      </div>
      <div
        className={`office-details-wrapper form-page-wrapper ${
          type === "lettings"
            ? "report-page"
            : type === "sales"
            ? "report-page"
            : null
        }`}
      >
        <section
          className={`valuation-steps-banner office-banner  ${calculatorclass}`}
          style={{
            backgroundImage: type
              ? "none"
              : `url(${FormBanner[0].Banner_Image.url})`,
            backgroundColor: "#000",
          }}
        >
          <div className={`office-inner-wrapper ${videoOpen}`}>
            {GQLModules.map((Modules, i) => {
              return (
                <>
                  {Modules.__typename ===
                    "GLSTRAPI_ComponentComponentsIntro" && (
                    <OfficeBanner
                      caption={GQLPage.Page_Caption}
                      title={Modules.Intro_Heading}
                      VideoURL={VideoURL}
                      description={Modules.Intro_Content}
                      youtubeVideo={
                        type === "lettings"
                          ? "https://youtu.be/1YqthouGZdk"
                          : type === "sales"
                          ? "https://youtu.be/RFpJjtTiVnw"
                          : null
                      }
                    />
                  )}
                </>
              )
            })}
            {FormName.length > 0 && FormName[0].Select_Form == "Contact_Form" && (
              <Container>
                <Row>
                  <Col xl={8} className="tab-column">
                    <div className="tab-box tab-box2">
                      <div className="tab-content">
                        <div className="tab-box-form">
                          <ContactForm
                            to_email_id={officeid ? officeEmail : ""}
                            officeName={officeName ? officeName : ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-md-none">
                      <GoogleReviewValuationStep />
                    </div>
                  </Col>
                  <Col xl={4} className="company-detail-column">
                    <OfficeDetails />
                  </Col>
                </Row>
                <Row className="d-none d-md-block">
                  <Col xl={12}>
                    <GoogleReviewValuationStep />
                  </Col>
                </Row>
              </Container>
            )}
            {FormName.length > 0 && FormName[0].Select_Form == "Buyers_Report" && (
              <Container>
                <Row className="report-section-wrap">
                  <Col xl={7} className="tab-column pr-0">
                    <div className="tab-box tab-box2">
                      <div className="tab-content">
                        <div className="tab-box-form">
                          <BuyersReportForm
                            reportdata={reportdata}
                            propertyaddress={buyerData?.display_address}
                            pid={propertyid && propertyid}
                            listid={listid && listid.replace("/", "")}
                            type={type && type}
                            to_email_id={buyerData?.office_details?.email}
                            phone_number={buyerData?.office_details?.telephone}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-md-none">
                      <GoogleReviewValuationStep />
                    </div>
                  </Col>
                  <Col xl={5} className="pl-0">
                    <BuyersReportVideo
                      VideoURL={VideoURL}
                      handleClick={handleClick}
                      FormBanner={FormBanner}
                      type={type && type}
                    />
                  </Col>
                </Row>
                <Row className="d-none d-md-block">
                  <Col xl={12}>
                    <GoogleReviewValuationStep />
                  </Col>
                </Row>
              </Container>
            )}
            {FormName.length > 0 &&
              FormName[0].Select_Form == "Mortgage_Calculator" && (
                <>
                  <div class="calc-wrapper">
                    <MortgageCalculator />
                    <Container>
                      <Row className="d-none d-md-block">
                        <GoogleReviewValuationStep />
                      </Row>
                    </Container>
                  </div>
                </>
              )}
          </div>
          <div class="overlay-office-bg"></div>
        </section>
      </div>
      <div className="valuation-steps-footer">
        <Footer
          Title="Home"
          popularSearch={props.GQLPage.Select_Popular_Search}
        />
      </div>
    </React.Fragment>
  )
}
export default FormTemplate
