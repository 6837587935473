import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import RadioField from "./elements/radio"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { postFormData } from "./api/Api"
import SpriftResults from "./sprift-results"
import axios from "axios"
import * as qs from "query-string"

function MySimpleForm(props) {
  let pageUrl = ""
  let reportType = ""
  if (props?.type === "lettings") {
    pageUrl = `${process.env.GATSBY_SITE_URL}/property-services/rent/`
    reportType = "Resident Report"
  } else {
    pageUrl = `${process.env.GATSBY_SITE_URL}/property-services/buy/`
    reportType = "Buyer Report"
  }

  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)
  const [spirift, setSpirift] = useState(false)
  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()
  const fields = [
    {
      element: "config",
      formname: "Buyers Report",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text:
        "Thank you for submitting buyers report form. You will receive the downloadable pdf link in your inbox shortly.",
      email_temp_user: "buyers_report_user",
      email_temp_admin: "buyers_report_admin",
      email_subject_user:
        props.type === "sales"
          ? "Unlock Your New Home - Your Buyer Report"
          : " Unlock Your New Home - Your Resident Report",
      email_subject_admin:
        props.type === "sales" ? "Buyers Report" : "Residents Report",
      email_server_func: "buyers_report",
      event_tracking: "buyers_report",
      page_url: "/general-enquiry",
    },
    {
      grpmd: "12",
      label: "First Name",
      placeholder: "",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Last Name",
      placeholder: "",
      name: "lname",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Telephone Number",
      placeholder: "",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",
    },
    // {
    //   grpmd: "12",
    //   label: "Address",
    //   placeholder: "",
    //   name: "address",
    //   type: "text",
    //   value: props.propertyaddress,
    //   element: "input",
    //   required: true,
    //   labelClass: "content_b-18",
    //   disabled: true,
    //   visible: "hidden"
    // },
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Write any information here...",
      name: "message",
      element: "textarea",
      class: "mb-4",
      rows: "3",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      class: "w-md-100 mtop-btn  btn btn-yellow",
      labelClass: "content_b-18",
    },
    {
      text:
        'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms & Conditions</a> and <a href="/privacy-and-cookie-policy/">Privacy &amp; Cookie Policy</a>',
      element: "html",
      class: "terms",
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== "") {
      const processFromData = async () => {
        formvalues["g-recaptcha-response"] = token

        let formData = new FormData()
        formvalues["extra"] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name,
        })
        formvalues["name"] = formvalues.name
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin
        formvalues["enquirytype"] = sessionStorage.getItem("spriftlink")
        formvalues["email_id"] = props.to_email_id
        formvalues["phone_number"] = props.phone_number
        formvalues["address"] = props.propertyaddress
        formvalues["page_link"] = pageUrl
        formvalues["report_type"] = reportType
        formvalues["book_viewing"] = sessionStorage.getItem("property_url")

        formData.append("data", JSON.stringify(formvalues))

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })

          // lets send mail
          // const axiosOptions_email = {
          //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_email)
          //   .then(response => {
          //     console.log('mail sent!')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );
        })

        // formvalues['g-recaptcha-response'] = token;

        // const axiosOptions_netlify = {
        //   url: fields[0].page_url,
        //   method: "post",
        //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //   data: qs.stringify(formvalues),
        // }

        // axios(axiosOptions_netlify)
        //   .then(response => {
        //     console.log('data stored')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );

        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + fields[0].event_tracking,
          formId: "form-" + fields[0].event_tracking,
          formName: fields[0].formname,
          formLabel: fields[0].formname,
        })

        setShowerror(false)
        setThankyou(true)

        myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      event.preventDefault()

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token
      // json["spriftlink"] = sessionStorage.getItem('spriftlink')

      setFormvalues(json)
      const payload = {
        oid: "00D0Y000001lDcH",
        retURL: "https://thankyoupage.com",
        "00N4G000006tS36": props.listid,
        "00N4G000006tS32":
          sessionStorage.getItem("toemail") === "sn@locationlocation.com"
            ? "Hackney"
            : sessionStorage.getItem("toemail") ===
              "andrew@locationlocation.com"
            ? "Norfolk"
            : "Hackney",
        "00N4G000006tS33":
          props.type === "sales" ? "Buyers Report" : "Tenant Report",
        debugEmail: "salesforce@locationlocation.com",
        lead_source: "Website",
        first_name: json.name,
        last_name: json.lname,
        email: json.email,
        phone: json.telephone,
        "00N4G000006tS37": json.address,
        description: json.message,
      }
      window
        .fetch(
          `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`,
          {
            method: `POST`,
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(payload),
          }
        )
        .then(response => {
          console.log(response)
        })
        .catch(err => console.log(err))
      // fetch({
      //   method: 'post',
      //   url: 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
      //   data: payload, // you are sending body instead
      //   headers: {
      //    // 'Authorization': `bearer ${token}`,
      //   'Content-Type': 'application/json'
      //   },
      // }).then(response => {
      //   console.log(response)
      // })
      // .catch(err => console.log(err))

      recaptchaRef.current.execute()

      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
      // document.getElementById("first_name").value = json.name
      // document.getElementById("email").value = json.email
      // document.getElementById("phone").value = json.telephone
      // document.getElementById("address").value = json.message
      // document.getElementById("appt_form").submit();
    }
  }
  // useEffect(() => {
  //   document.getElementById("validationaddress").disabled = true
  //   if (props.pid) {
  //     setSpirift(true)
  //   }
  // })
  return (
    <div className="form stbform" id="BuyersReportForm">
      <div ref={myRef} />
      {showerror && (
        <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}
      {showthankyou && (
        <div className="alert-success">
          <p>{fields[0].success_text}</p>
        </div>
      )}
      {/* {props.propertyaddress} */}
      {/* {props.pid &&
      <SpriftResults id={props.pid} propertyaddress={props.propertyaddress}/>
      } */}
      ​
      {/* <form id="appt_form" 
style={{display: 'none'}}
 action="https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
​
​
    <input type="hidden" name="oid" value="00D25000000HGCi" />
    <input type="hidden" name="retURL" value="https://www.thankyou.com" />
    <input type="hidden" name="debugEmail" value="salesforce@locationlocation.com" />
    <input type="hidden" name="lead_source" value="Website" />
    <input id="00N2500000BScGl" type="hidden" maxlength="18" name="00N2500000BScGl" size="20" type="text" value="<ListingId>" />
    <input id="00N2500000BSdbY" type="hidden" name="00N2500000BSdbY" title="Enquiry Type" value="Buyers Report" />
​
​
    <label for="first_name">First Name</label><input id="first_name" maxlength="40" name="first_name" size="20" type="text" /><br />
    <label for="last_name">Last Name</label><input id="last_name" maxlength="80" name="last_name" size="20" type="text" /><br />
    <label for="email">Email</label><input id="email" maxlength="80" name="email" size="20" type="text" /><br />
    <label for="phone">Phone</label><input id="phone" maxlength="40" name="phone" size="20" type="text" /><br /> Address
    <input id="00N2500000BSdbT" maxlength="255" name="00N2500000BSdbT" size="20" type="text" /><br />
    <label for="description">Message</label><textarea id="message" name="description"></textarea><br />
    <input id="salesforseClick" type="submit" name="submit" />
​
</form> */}
      <Form
        className="contact-form form-w-label"
        name={fields[0].formname}
        action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        {/* {sessionStorage.getItem('toemail') === "sn@locationlocation.com" ? "Hackney" : sessionStorage.getItem('toemail') === "thetford@locationlocation.com" ? "Thetford" : "Hackney"} */}
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="address" value={props.propertyaddress} />

        {/* <input type="hidden" name="to_email_id" value={props.to_email_id} /> */}
        {/* <input type="hidden" name="Spriftlink" value={sessionStorage.getItem('Spriftlink') !== undefined ? sessionStorage.getItem('Spriftlink') : ""} /> */}

        <input
          type="hidden"
          name="buyers_report"
          id="buyers_report"
          value={props.reportdata}
        />
        <input type="hidden" name="bot-field" />
        {fields.map((field, index) => {
          if ("input" === field.element) {
            return (
              <InputField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                type={field.type}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                label={field.label}
                labelClass={field.labelClass}
                required={field.required}
                key={`${field.element}~${index}`}
                pattern={field.patternchk}
                handlechange={handlechange}
              />
            )
          }
          if ("select" === field.element) {
            return (
              <SelectField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                required={field.required}
                fieldClass={field.class}
                placeholder={field.placeholder}
                values={field.values}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
                componentprops={props}
              />
            )
          }
          if ("textarea" === field.element) {
            return (
              <TextAreaField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                rows={field.rows}
                fieldClass={field.class}
                label={field.label}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("checkbox" === field.element) {
            return (
              <CheckboxField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("radio" === field.element) {
            return (
              <RadioField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                checked={field.checked}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
                lastchild={field.lastchild}
              />
            )
          }
          if ("html" === field.element) {
            return (
              <HtmlBox
                text={field.text}
                fieldClass={field.class}
                key={`${field.element}~${index}`}
              />
            )
          }
          if ("captcha" === field.element) {
            return (
              <ReCaptchaBox
                fieldClass={field.class}
                captRef={field.captchaRef}
                key={`${field.element}~${index}`}
                handleonVerify={handleonVerify}
              />
            )
          }
          if ("button" === field.element) {
            return (
              <ButtonField
                name={field.name}
                fieldClass={field.class}
                type={field.type}
                value={field.value}
                key={`${field.element}~${index}`}
              />
            )
          }
        })}
      </Form>
    </div>
  )
}

const BuyersReportForm = props => (
  <MySimpleForm
    reportdata={props.reportdata}
    pid={props.pid}
    propertyaddress={props.propertyaddress}
    to_email_id={props.to_email_id}
    listid={props.listid}
    type={props.type}
    branch={props.branch}
    phone_number={props.phone_number}
  />
)

export default BuyersReportForm
