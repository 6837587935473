import React, { useState, useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import Header from "../Components/Header/Header"
import HomeBanner from "../Components/Home/HomeBanner/HomeBanner"
import LandingIntro from "../Components/Static/StaticBanner/LandingIntro"
import StaticIntro from "../Components/Static/StaticBanner/StaticIntro"
import ListingIntro from "../Components/Static/StaticBanner/ListingIntro"
import SectionTwoColumn from "../Components/PropertyManagement/PropertyManagementSection/SectionTwoColumn"
import FeaturedProperties from "../Components/PropertyManagement/RelatedPropertyManagement/Featured-Properties"
import FeaturedPropertiesLanding from "../Components/PropertyManagement/RelatedPropertyManagement/Featured-Properties-Landing"
import $ from "jquery"
import LandingBanner from "../Components/Static/StaticBanner/LandingBanner"
import StaticBanner from "../Components/Static/StaticBanner/StaticTemplateBanner"
import BottomTeamModule from "../Components/Static/ContactTeam/ContactTeam"
import BlogCta from "../Components/Static/ContactTeam/BlogCta"

import HomeVideo from "../Components/Home/HomeVideo/HomeVideo"
import HomeIntro from "../Components/Home/HomeIntro/HomeIntro"
import StaticContent from "../Components/Static/StaticBanner/StaticContent"
import FAQ from "../Components/Static/FrequentlyAskedQuestions/FrequentlyAskedQuestions"
import HomeBlog from "../Components/Home/HomeBlog/HomeBlog"
import HomeGoogleReview from "../Components/Home/HomeGoogleReview/HomeGoogleReview"
import Footer from "../Components/Footer/Footer"
import NewsListing from "../Components/Static/Gallery/NewsListing"
import CommunitiesListing from "../Components/Static/Gallery/CommuntiesListing"
import CasestudyListing from "../Components/Static/Gallery/CasestudyListing"
import NewsFilter from "../Components/Static/Gallery/NewsFilter"
import CommunityFilter from "../Components/Static/Gallery/CommunityFilter"
import TeamListing from "../Components/PropertyManagement/AreaGuides/PeopleListing"
import AreaListing from "../Components/PropertyManagement/AreaGuides/AreaListing"
import OfficeListing from "../Components/PropertyManagement/AreaGuides/OfficeListing"
import LandingTiles from "../Components/landing-tiles-big"
import TransparentHeaderBanner from "../Components/PropertyManagement/PropertyManagementBanner/PropertyManagementBanner"
import Plans from "../Components/PropertyManagement/PropertyManagementSection/Plans"
import ServiceBlocks from "../Components/PropertyManagement/WhyUse/ServiceBlocks"
import GoogleReviewsSlider from "../Components/PropertyManagement/PropertyManagementGoogleReview/PropertyManagementGoogleReview"
import OurClients from "../Components/PropertyManagement/AreaGuides/OurClients"
import CasestuidesSlider from "../Components/Static/Gallery/CasestudySlider"
import GridSlider from "../Components/Static/Gallery/GridSlider"
import QuizQuestions from "../Components/PropertyManagement/QuizQuestions/QuizQuestions"
import GooglereviewsList from "../Components/PropertyManagement/PropertyManagementGoogleReview/ReviewsListing"
import GooglereviewsList2 from "../Components/PropertyManagement/PropertyManagementGoogleReview/ReviewsListing2"
import ReportMaintenance from "../Components/Static/ContactTeam/ReportMaintenance"
import Tailblock from "../Components/TailBlock/Tailblock"
import LeadingPortals from "../Components/LeadingPortals/LeadingPortals"
import { CalculatorSlider } from "../Components/CalculatorSlider/CalculatorSlider"
import RecentStores from "../Components/RecentStores/RecentStores"
import TeamBlockSlider from "../Components/TeamBlock/TeamBlockSlider"
import GetStarted from "../Components/GetStartedBlock/GetStarted"
import OurPackages from "../Components/OurPackages/OurPackages"
import PackageBlock from "../Components/PackageBlocks/PackageBlock"
import ConfidentServiceBlock from "../Components/ConfidentServiceBlock/ConfidentServiceBlock"
import InternalServiceBlock from "../Components/InternalServiceBlock/InternalServiceBlock"
import TileBlockv2 from "../Components/TileBlockv2/TileBlockv2"
import ShapeBlocks from "../Components/ShapeBlocks/ShapeBlocks"
import SimplySwitch from "../Components/SimplySwitch/SimplySwitch"
import ContentSlider from "../Components/ContentSlider/ContentSlider"

const Home = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [ClientWidth, setClientWidth] = useState(false)
  const [LargeMonitor, setLargeMonitor] = useState(false)
  const [LargeBigMonitor, setLargeBigMonitor] = useState(false)
  useEffect(() => {
    if ($(window).width() > 1700) {
      setLargeBigMonitor(true)
    } else if ($(window).width() > 1500) {
      setClientWidth(true)
    } else if ($(window).width() > 1200 && $(window).width() < 1500) {
      setLargeMonitor(true)
    } else {
      setLargeMonitor(false)
    }
  })
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])

  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules
  const GQLAreaguides = props.GQLAreaguides
  const GQLOffices = props.GQLOffices
  const GQLClients = props.GQLClients
  const NewsCat = props.ifnewscat
  const CommunityCat = props.ifcommunitycat
  //console.log('aaaaaa',CommunityCat)

  return (
    <React.Fragment>
      {GQLPage.Select_Template == "Landing_Template" ||
      GQLPage.Header_Style == "Dark" ? (
        <div className={scroll ? "header-search menu-scroll" : "header-search"}>
          <Header />
        </div>
      ) : GQLPage.Select_Template == "Transparent_Header" ? (
        <div
          className={
            scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"
          }
        >
          <Header />
        </div>
      ) : (
        <Header />
      )}
      {/* ======= Loop Through Modules ====== */}
      <div
        className={`pagewrapper ${GQLPage.Page_CSS_Class} ${
          GQLPage.Title
            ? "pagename-" + GQLPage.Title.toLowerCase().replace(/\s/g, "-")
            : ""
        }`}
      >
        {GQLModules.map((Modules, i) => {
          return (
            <>
              {/* Heading with CTA Module */}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsIntro" &&
                GQLPage.Select_Template != "Landing_Template" &&
                GQLPage.Select_Template != "Static_Template" &&
                GQLPage.Select_Template != "Listing_Template" && (
                  <HomeBanner
                    Pageclass={GQLPage.Page_CSS_Class}
                    Intro_Heading_With_Font={Modules.Intro_Heading_With_Font}
                    Heading={Modules.Intro_Heading}
                    Content={Modules.Intro_Content}
                    Ctas={Modules.Intro_CTA}
                    Caption={GQLPage.Page_Caption}
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsIntro" &&
                GQLPage.Select_Template == "Landing_Template" && (
                  <LandingIntro
                    Caption={GQLPage.Page_Caption}
                    Heading={Modules.Intro_Heading}
                    Subpages={Modules.SubPages}
                    showreview={Modules.Show_Reviews}
                    Content={Modules.Intro_Content}
                    Ctas={Modules.Intro_CTA}
                    Pageclass={GQLPage.Page_CSS_Class}
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsIntro" &&
                GQLPage.Select_Template == "Static_Template" && (
                  <StaticIntro
                    Caption={GQLPage.Page_Caption}
                    Content={Modules.Intro_Content}
                    Heading={Modules.Intro_Heading}
                    Ctas={Modules.Intro_CTA}
                    template={GQLPage.Select_Template}
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsIntro" &&
                GQLPage.Select_Template == "Listing_Template" && (
                  <ListingIntro
                    Caption={GQLPage.Page_Caption}
                    Heading={Modules.Intro_Heading}
                    Content={Modules.Intro_Content}
                    Ctas={Modules.Intro_CTA}
                    template={GQLPage.Select_Template}
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsBanner" &&
                Modules.Banner_Image &&
                GQLPage.Select_Template != "Landing_Template" &&
                GQLPage.Select_Template != "Static_Template" &&
                GQLPage.Select_Template != "Transparent_Header" && (
                  <HomeVideo
                    BannerImage={Modules.Banner_Image}
                    BannerContent={Modules.Banner_Content}
                    VideoURL={Modules.Youtube_Video_URL}
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsBanner" &&
                GQLPage.Select_Template == "Transparent_Header" && (
                  <TransparentHeaderBanner
                    Title={GQLPage.Title}
                    BannerImage={Modules.Banner_Image}
                    bannerContent={Modules.Banner_Content}
                    bannerCta={Modules.Banner_Cta_Urls?.Btn_Label_1}
                    VideoURL={Modules.Youtube_Video_URL}
                    bannerclass={
                      Modules.Banner_Class ? Modules.Banner_Class : ""
                    }
                    playtext={Modules.Banner_Play_Text}
                    Mobile_Banner={Modules.Mobile_Banner}
                  />
                )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsStaticBanner" &&
                (Modules.layout === "tile_block" ? (
                  <TileBlockv2 Module={Modules} />
                ) : Modules.layout === "simply_switch" ? (
                  <SimplySwitch Module={Modules} />
                ) : (
                  <Tailblock Module={Modules} />
                ))}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsContentSlider" && (
                <ContentSlider Module={Modules} />
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsShapeBlocks" && (
                <ShapeBlocks Module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsDynamicContents" && (
                <OurPackages Module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsBlockSlider" && (
                <CalculatorSlider Module={Modules} />
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsStores" && (
                <RecentStores Module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsLeadingPortals" && (
                <LeadingPortals Module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGetStartedBlock" && (
                <GetStarted Module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsTeamBlock" && (
                <TeamBlockSlider Module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsInternalServiceBlock" && (
                <InternalServiceBlock Module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsPackageBlocks" && (
                <PackageBlock Module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsConfidentServiceBlock" && (
                <ConfidentServiceBlock Module={Modules} />
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsBanner" &&
                Modules.Banner_Image &&
                GQLPage.Select_Template == "Landing_Template" && (
                  <LandingBanner
                    Caption={GQLPage.Page_Caption}
                    bannerclass={
                      Modules.Banner_Class ? Modules.Banner_Class : ""
                    }
                    BannerImage={Modules.Banner_Image}
                    VideoURL={Modules.Youtube_Video_URL}
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsBanner" &&
                Modules.Banner_Image &&
                GQLPage.Select_Template == "Static_Template" && (
                  <StaticBanner
                    Title={GQLPage.Title}
                    BannerImage={Modules.Banner_Image}
                    VideoURL={Modules.Youtube_Video_URL}
                    template={GQLPage.Select_Template}
                  />
                )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsTwoCloumnContent" && (
                <SectionTwoColumn
                  Leftcontent={Modules.Left_Column_Content}
                  Rightcontent={Modules.Right_Column_Content}
                  template={GQLPage.Select_Template}
                />
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsBlackBoxContent" && (
                <HomeIntro Content={Modules.Black_Box_Description} />
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsStaticContent" && (
                <StaticContent
                  IntroContent={Modules.Static_Content_Intro}
                  FullContent={Modules.Static_Content_Full}
                />
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Featured_Properties_Module" &&
                GQLPage.Select_Template != "Landing_Template" && (
                  <FeaturedProperties type={GQLPage.Properties_Type} />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Featured_Properties_Module" &&
                GQLPage.Select_Template == "Landing_Template" && (
                  <FeaturedPropertiesLanding type={GQLPage.Properties_Type} />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Latest_News_Module" && (
                  <HomeBlog
                    LargeMonitor={LargeMonitor}
                    LargeBigMonitor={LargeBigMonitor}
                    ClientWidth={ClientWidth}
                    isHomepage={GQLPage?.Title === "Home" ? true : false}
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Google_Reviews_Module" && (
                  <HomeGoogleReview
                    class={
                      GQLPage.Select_Template == "Landing_Template"
                        ? "landing-reviews"
                        : ""
                    }
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsFaq" && (
                <FAQ
                  Heading={Modules.FAQ_Module_Heading}
                  Description={Modules.FAQ_Module_Caption}
                  FAQListing={Modules.FAQ_Listing}
                />
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "CaseStudy_Listing" && (
                  <CasestudyListing />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "News_Listing" &&
                NewsCat === "" && <NewsListing />}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "News_Listing" &&
                NewsCat != null &&
                NewsCat != "" && <NewsFilter NewsCategoryPage={NewsCat} />}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Communities_Listing" &&
                CommunityCat === "" && <CommunitiesListing />}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Communities_Listing" &&
                CommunityCat != null &&
                CommunityCat != "" && (
                  <CommunityFilter NewsCategoryPage={CommunityCat} />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Our_People_Listing" && (
                  <TeamListing />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Areaguides_Listing" && (
                  <AreaListing data={GQLAreaguides} location="London" />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Areaguides_Listing_Norfolk" && (
                  <AreaListing data={GQLAreaguides} location="Norfolk" />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Office_Listing" && (
                  <OfficeListing data={GQLOffices} location="London" />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Office_Listing_Norfolk" && (
                  <OfficeListing data={GQLOffices} location="Norfolk" />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Google_Reviews_Slider" && (
                  <GoogleReviewsSlider
                    ReviewTitle={Modules.Google_Review_Title}
                  />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Team_Bottom_Module" &&
                GQLPage.Title != "Report Maintenance request" &&
                GQLPage.Title != "Stories" && (
                  <BottomTeamModule pageClass={GQLPage.Page_CSS_Class} />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Team_Bottom_Module" &&
                GQLPage.Title == "Stories" && <BlogCta />}

              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Team_Bottom_Module" &&
                GQLPage.Title == "Report Maintenance request" && (
                  <ReportMaintenance />
                )}

              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Client_Logo_Module" && (
                  <OurClients data={GQLClients} />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Case_Study_Slider" && (
                  <CasestuidesSlider />
                )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
                Modules.Select_Module === "Reviews_Listing" && (
                  <>
                    <GooglereviewsList />
                    <GooglereviewsList2 />
                  </>
                )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsLandingTilesBig" && (
                <LandingTiles Tiles={Modules.Landing_Tiles} />
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsPlans" && (
                <Plans
                  data={Modules.Plan_Details}
                  template={GQLPage.Select_Template}
                />
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGridImageSlider" && (
                <GridSlider data={Modules.Grid_Images} />
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsServicesBlocks" && (
                <ServiceBlocks
                  topheading={Modules.Services_Top_Heading}
                  title={Modules.Services_Title}
                  data={Modules.Services_Details}
                  template={GQLPage.Select_Template}
                />
              )}
            </>
          )
        })}
      </div>
      {GQLPage.Page_CSS_Class === "career-landing" && <QuizQuestions />}
      {GQLPage.Select_Footer === "Dark_Footer" ? (
        <div className="valuation-steps-footer">
          <Footer
            Title={GQLPage.Title}
            popularSearch={GQLPage.Select_Popular_Search}
          />
        </div>
      ) : (
        <Footer
          Title={GQLPage.Title}
          popularSearch={GQLPage.Select_Popular_Search}
          isServicePage={GQLPage.Page_CSS_Class?.includes("lettingpage")}
        />
      )}
    </React.Fragment>
  )
}

export default Home
