import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import MarketingBanner from "../Components/PropertyDetails/PropertyDetailsBanner/MarketingBanner"
import MarketingSlides from "../Components/PropertyDetails/KeyFeatures/MarketingSlides"
import {
  isIOS,
  isMobile,
  withOrientationChange,
  isTablet,
  isMobileOnly,
} from "react-device-detect"
import ReactFullpage from "@fullpage/react-fullpage"
import MarketingHeader from "../Components/PropertyDetails/KeyFeatures/marketingheader"
import $ from "jquery"
const pluginWrapper = () => {
  require('./fullpage.scrollOverflowReset.min');
};
const FormTemplate = props => {
  const { isLandscape, isPortrait } = props
  const [IpadLandscape, setIpadLandscape] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [Slide1, setSlide1] = useState("DefaultMarketing")
  const [firstSlide, setFirstSlide] = useState(false)
  const [ClientWidth, setClientWidth] = useState(false)
  const [videoOpen, setVideoOpen] = useState("")
  const handleClickVideo = value => setVideoOpen(value)
  const [isLandscapeMode, setisLandscapeMode] = useState(false)

  useEffect(() => {
    $("body").addClass("switchtofadein")
    if (isLandscape && isTablet && $(window).width() > 1200) {
      setIpadLandscape(true)
    } else {
      setIpadLandscape(false)
    }
    if($(window).width() === 1366 &&  $(window).height() === 1024) {
      setisLandscapeMode(true);
  } else {
    setisLandscapeMode(false);
  }
    //console.log('aaaaaaaaaaa',isLandscape,IpadLandscape);
    if ($(window).width() > 1600) {
      setClientWidth(true)
    }
  })

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules

  const handleClick = value => {
    setSlide1(value)
  }

  const closeClick = value => {
    setSlide1(value)
  }
  const MarketingSlideTitles = GQLModules.filter(
    GQLModules => GQLModules.Marketing_Slider != null
  ).map(({ Marketing_Slider }) => ({ Marketing_Slider }))

  var slide_list = ["section-banner"]
  MarketingSlideTitles &&
    MarketingSlideTitles[0]?.Marketing_Slider.map(marketslide =>
      slide_list.push(
        "section-" +
          marketslide.Marketing_Slide_Title.replace(/\s+/g, "-").toLowerCase()
      )
    )
    slide_list.push("section-footer")
  //console.log('aaaaaaaaa',slide_list)
  return (
    <React.Fragment>
      <div
        className={`page-marketing-main ${
          GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ""
        }`}
      >
        {(firstSlide && !isMobile) || (firstSlide && IpadLandscape) ? (
          <MarketingHeader GQLModules={GQLModules} />
        ) : (
          <div
            className={
              scroll ? "menu-fixed menu-scroll " + Slide1 + "" : "menu-fixed"
            }
          >
            <Header />
          </div>
        )}
        <div className={`marketing-wrapper ${videoOpen}`}>
          <ReactFullpage
            //fullpage options
            pluginWrapper={pluginWrapper}
            licenseKey={`${process.env.GATSBY_FULLPAGEJS}`}
            menu={"#marketingmenu"}
            anchors={slide_list}
            lockAnchors={isMobileOnly ? true : false}
            scrollingSpeed={1000} /* Options here */
            normalScrollElements={".keyfetures .customscrollbar"}
            normalScrollElementTouchThreshold={250}
            fadingEffect={true}
            autoScrolling={true}
            setResponsive={1000}
            scrollOverflow={true}  /* Because we are using the extension */
            scrollOverflowReset={true}
            scrollOverflowResetKey={`${process.env.GATSBY_FULLPAGEJS_SCROLLOVERFLOW}`}
            responsiveWidth={1200}
            onLeave={(origin, destination) => {
              // setTimeout(function(){
              //     window.location.hash = '';
              //     window.history.pushState("", document.title, window.location.pathname);
              // }, 200);
              if (destination.isFirst === false) {
                setTimeout(function () {
                  setFirstSlide(true)
                }, 100)
                setTimeout(function () {
                  $("body").addClass("switchtofadein")
                }, 500)
              } else {
                setFirstSlide(false)
                //$('body').removeClass('switchtofadein');
              }
            }}
            render={({ state }) => {
              return (
                <ReactFullpage.Wrapper>
                  {GQLModules.map((Modules, i) => {
                    const first_sliderTitle =
                      Modules.Marketing_Slider?.[0]?.Marketing_Slide_Title

                    return (
                      <>
                        <>
                          {Modules.__typename ===
                            "GLSTRAPI_ComponentComponentsBanner" && (
                            <div class="section banner-full-page-js">
                              <MarketingBanner
                                id={"Reasons"}
                                Label={"Why choose us?"}
                                Caption={GQLPage.Page_Caption}
                                handleClick={handleClick}
                                BannerImage={Modules.Banner_Image}
                                BannerVideo={Modules.Banner_Upload_Video}
                                BannerContent={Modules.Banner_Content}
                                BannerYouTubeVideo={Modules.Youtube_Video_URL}
                                
                              />
                            </div>
                          )}
                        </>
                        {Modules.__typename ===
                          "GLSTRAPI_ComponentComponentsMarketingSlides" && (
                          <MarketingSlides
                            handleClickVideo={handleClickVideo}
                            ClientWidth={ClientWidth}
                            closeClick={closeClick}
                            slides={Modules.Marketing_Slider}
                            Slide1={Slide1}
                            isLandscapeMode={isLandscapeMode}
                          />
                        )}
                      </>
                    )
                  })}
                </ReactFullpage.Wrapper>
              )
            }}
          />
        </div>
        {isMobile && (
          <div className="prop-details-footer">
            <Footer popularSearch="Popular_Search_Static_Common" />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
//App = withOrientationChange(App)

export default withOrientationChange(FormTemplate)
