import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./PropertyManagementSection.scss"
import Modal from "react-bootstrap/Modal"
import Plans from "../../forms/plans"

import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  easeFunc,
  delayItemsFromBottom,
  containerVariants,
  fadeInFromRight,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const PropertyManagementSection = props => {
  const [show, setShow] = useState(false)
  const [plans, setPlans] = useState("")

  const handleModal = event => {
    setPlans(event)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }
  const mail_id = "info@locationlocation.com"
  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className={`property-management-plans ${props.template
              ?.toLowerCase()
              .replace(/\s/g, "")}`}
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
          >
            <div className="pricing-container">
              {/* <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInUp' offset={0}> */}
              <div className="price-box">
                {props.data?.map((plan, index) => {
                  return (
                    <>
                      <motion.div
                        variants={delayItemsFromBottom}
                        key={index}
                        custom={index}
                        className={`card-item-price price${index + 1}`}
                      >
                        <div className={`plan-block  plan${index + 1} plans`}>
                          {plan.Plan_Highlight_Tag && (
                            <span className="recommend-tag">
                              {plan.Plan_Highlight_Tag}
                            </span>
                          )}
                          <h3>{plan.Plan_Title}</h3>
                          {/* <p className="small-intro">{plan.Plan_Caption}</p> */}
                          <p className="tax-block">{plan.Plan_Percentage}</p>
                          <button
                            className="btn btn-outline"
                            onClick={() => handleModal(plan.Plan_Title)}
                          >
                            {plan.Plan_Cta_Label}
                          </button>
                          {plan.Plan_Title === "Managed" ? (
                            <p>Everything Tenant Find includes</p>
                          ) : null}
                          <ul className="plan-details">
                            {plan.Plan_List.map(planlist => {
                              return (
                                <>
                                  <li>
                                    <i className="icon-check-small"></i>
                                    {planlist.Plan_Name}
                                  </li>
                                </>
                              )
                            })}
                          </ul>
                        </div>
                      </motion.div>
                    </>
                  )
                })}
              </div>
              {/* </ScrollAnimation>   */}
            </div>

            <>
              <Modal
                show={show}
                centered={true}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
              >
                <Modal.Header closeButton className="contact-close-btn">
                  <Modal.Title className="w-100">{plans}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p></p>
                  <Plans to_email_id={mail_id} plan={plans} />
                </Modal.Body>
              </Modal>
            </>
          </motion.section>
        )}
      </InView>
    </React.Fragment>
  )
}

export default PropertyManagementSection
