import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Container, Row, Col,} from "react-bootstrap";
import "./StaticBanner.scss";
import PlayVideo from '../../Play/PlayVideo';
import getVideoId from 'get-video-id';
import Img from 'gatsby-image';

const StaticBanner = (props) => {
  const videoid = props.VideoURL && getVideoId(props.VideoURL);
  const [isPlay,setPlay] = useState(false);

  return (
    <React.Fragment>
      
      <section className="static-article-data">
        <Container>
          <Row>
            <Col lg={12}>
            <ScrollAnimation animateOnce={true} duration={0.5} className="banner-animated" animateIn="fadeIn">
            <div className="video-banner">
            <Img fluid={props.BannerImage.url_sharp.childImageSharp.fluid} alt={props.BannerImage.alternativeText?props.BannerImage.alternativeText:props.Title+' Banner -  Location Location'} />          

              {props.VideoURL !=null && props.VideoURL != '' &&
                <a href="javascript:;" className="video-arrow" onClick = { e => {setPlay(true)}}><i class="icon-video-white"></i></a>
              }
              </div>
            </ScrollAnimation>
            </Col>
          </Row>
          </Container>
          {props.VideoURL !=null && videoid && props.VideoURL != '' &&
          <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId={props?.VideoURL}
              isAutoPlay={1}
            />
            }
      </section>
    </React.Fragment>
  );
};
export default StaticBanner;
