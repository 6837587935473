import { Link } from "@StarberryUtils"
import React, { useState } from "react"
import "react-multi-carousel/lib/styles.css"
import ScrollAnimation from "react-animate-on-scroll"
import "./Gallery.scss"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { NewsBaseURL } from "../../utils"
import { useMatch } from "@reach/router"
import { isMobile } from "react-device-detect"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
} from "react-bootstrap"
import { WhiteMinus } from "../../icons"
const NEWS_CAT = gql`
  query NewsCat {
    newsCategories {
      Name
      URL
    }
  }
`
const Gallery = props => {
  const [newsdropdown, Setnewsdropdown] = useState(false)
  const newscat = useMatch("/blog/:item/")
  var newsurl = ""
  newsurl = newscat && newscat.item

  const { loading, error, data } = useQuery(NEWS_CAT)
  if (loading) return <p>Loading ...</p>
  const HandleClick = () => {
    Setnewsdropdown(!newsdropdown)
  }

  return (
    <React.Fragment>
      <ScrollAnimation offset={0} animateOnce={true} animateIn="fadeInDown">
        {!isMobile && (
          <section className="gallery-blog">
            <Container>
              <div className="filters-block d-xl-flex">
                <ul>
                  <li className={newsurl == null ? "active-filter-tab" : ""}>
                    <Link to={`/${NewsBaseURL}`}>All Stories</Link>
                  </li>
                  {data.newsCategories.map((newscat, index) => {
                    return (
                      <>
                        <li
                          className={
                            newsurl == newscat.URL ? "active-filter-tab" : ""
                          }
                        >
                          <Link to={`/${NewsBaseURL}/${newscat.URL}`}>
                            {newscat.Name}
                          </Link>
                        </li>
                      </>
                    )
                  })}
                </ul>
              </div>
            </Container>
          </section>
        )}
        {isMobile && (
          <section className="gallery news-cat-wrap">
            <Container>
              <Row>
                <Col xl={12}>
                  <div className="button-filter">
                    <ScrollAnimation
                      animateOnce={true}
                      duration={0.5}
                      animateIn="fadeInDown"
                    >
                      <div className="news-cat-head">
                        {data.newsCategories.map((newscat, index) => {
                          return (
                            <>
                              {newsurl == newscat.URL && (
                                <Link to="#" className="d-xl-none">
                                  {newscat.Name}
                                </Link>
                              )}
                            </>
                          )
                        })}
                        {!newsurl && (
                          <Link
                            to={`/${NewsBaseURL}`}
                            className="d-xl-none all-stories"
                          >
                            All Stories
                          </Link>
                        )}
                        {newsdropdown ? (
                          <i
                            href="javscript:;"
                            onClick={() => HandleClick()}
                            className="icon-minus-white"
                          >
                            &#8722;
                          </i>
                        ) : (
                          <i
                            href="javscript:;"
                            onClick={() => HandleClick()}
                            className="icon-minus-white"
                          >
                            +
                          </i>
                        )}
                      </div>
                      {newsdropdown && (
                        <ScrollAnimation
                          animateOnce={true}
                          animateIn="slideInDown"
                        >
                          <div className="news-cat-dropdown">
                            <ul>
                              {newsurl && (
                                <li>
                                  <Link
                                    to={`/${NewsBaseURL}`}
                                    className="d-xl-none"
                                  >
                                    All Stories
                                  </Link>
                                </li>
                              )}
                              {data.newsCategories.map((newscat, index) => {
                                return (
                                  <>
                                    {newsurl != newscat.URL && (
                                      <li
                                        className={
                                          newsurl == newscat.URL
                                            ? "active-filter-tab"
                                            : ""
                                        }
                                      >
                                        <Link
                                          to={`/${NewsBaseURL}/${newscat.URL}`}
                                        >
                                          {newscat.Name}
                                        </Link>
                                      </li>
                                    )}
                                  </>
                                )
                              })}
                            </ul>
                          </div>
                        </ScrollAnimation>
                      )}
                    </ScrollAnimation>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </ScrollAnimation>
    </React.Fragment>
  )
}
export default Gallery
