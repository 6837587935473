import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./AreaGuides.scss"
import "react-multi-carousel/lib/styles.css"
import PlayVideo from "../../Play/PlayVideo"
import getVideoId from "get-video-id"
import { AreaBaseURL } from "../../utils"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"
import { isIOS, isMobile } from "react-device-detect"
import ReactPlayer from "react-player"
import { Close } from "../../icons"
const AreaGuides = props => {
  const [isPlay, setPlay] = useState(true)
  const [videoID, SetvideoID] = useState(false)
  const [isPlaying, setPlaying] = useState(false)
  const [play, setPlayvideo] = useState(true)
  const vidRef = useRef(null)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }
  const trackerVideo = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Video Play Btn",
      formType: event,
      formId: "Video Play",
      formName: "Video",
      formLabel: "Video",
    })
  }

  return (
    <React.Fragment>
      <section className="area-guides  areaguide-listing-wrapper area-lists">
        <div className="area-guide-video-carousel">
          <Container>
            <Row>
              {props.data.map((guide, index) => {
                var incekey = index + 1
                var delaying = incekey + "00"
                let processedImages = JSON.stringify({})
                if (guide?.imagetransforms?.Tile_Image_Transforms) {
                  processedImages = guide.imagetransforms.Tile_Image_Transforms
                }
                return (
                  <>
                    {props.location === guide.Select_Areaguide_Location && (
                      <Col xl={4} md={6} xs={12} className="mbot" key={index}>
                        <div className="item">
                          <ScrollAnimation
                            offset={!isMobile ? 1000 : ""}
                            duration={0.5}
                            animateIn="fadeInUp"
                            animateOnce={true}
                          >
                            <div className="video-img-box area">
                              <div className="video-img-wrapper inline-video-wrapper">
                                <Link
                                  to={`${AreaBaseURL}/${guide.Select_Areaguide_Location.toLowerCase()}-area-guides/${
                                    guide.URL
                                  }`}
                                >
                                  <ImageTransform
                                    imagesources={guide.Tile_Image.url}
                                    renderer="srcSet"
                                    imagename="areaguides.Tile_Image.commontile"
                                    attr={{
                                      alt: guide.Tile_Image.alternativeText
                                        ? guide.Tile_Image.alternativeText
                                        : guide.Name +
                                          " Areaguide -  Location Location",
                                    }}
                                    imagetransformresult={processedImages}
                                    id={guide.id}
                                  />
                                </Link>
                                {isPlay &&
                                  guide.Youtube_Video_URL &&
                                  videoID === guide.id && (
                                    <div className="inline-video">
                                      <div className="close-inline-video">
                                        <a
                                          onClick={e => {
                                            setPlay(false)
                                          }}
                                        >
                                          <Close />
                                        </a>
                                      </div>
                                      <ReactPlayer
                                        onPlay={trackerVideo(guide.Name)}
                                        ref={vidRef}
                                        autoplay
                                        url={guide.Youtube_Video_URL}
                                        controls={true}
                                        autoplay={true}
                                        muted={isMobile && isIOS ? true : false}
                                        playsinline={true}
                                        playing={play}
                                        width="100%"
                                        height="100%"
                                        onEnded={() => {
                                          setPlay(false)
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>

                              {guide.Youtube_Video_URL && (
                                <a
                                  href="javascript:;"
                                  className="video-arrow"
                                  onClick={e => {
                                    setPlay(true)
                                    SetvideoID(guide.id)
                                    getVideoId(guide.Youtube_Video_URL)
                                  }}
                                >
                                  <i class="icon-video-white"></i>
                                </a>
                              )}
                            </div>
                            <div className="video-carouse-content">
                              <p className="name">{guide.Name}</p>
                              <p className="code">{guide.Area_Postcode}</p>
                              <p className="description">
                                {guide.Area_Caption}
                              </p>
                            </div>
                          </ScrollAnimation>
                        </div>
                      </Col>
                    )}
                  </>
                )
              })}
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  )
}

export default AreaGuides
