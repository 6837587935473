import HTMLReactParser from "html-react-parser"
import React from "react"
import { Container } from "react-bootstrap"
import GetCTA from "../getcta"
import "./SimplySwitch.scss"

const SimplySwitch = ({ Module }) => {
  if (!Module) return null

  const { Title, Content, Cta_Link, Tile_Image, Cta_Label } = Module
  return (
    <div className="SimplySwitch_wrapper" id="simply-switch">
      <Container className="SimplySwitch_container">
        <div className="left-section">
          <h2 className="title">{Title}</h2>
          {Content && <div className="content">{HTMLReactParser(Content)}</div>}
          <GetCTA
            link={Cta_Link?.Primary_URL}
            class={`btn btn-outline`}
            Label={Cta_Label}
          />
        </div>
        <div className="right-section">
          <div className="tile-image">
            <img src={Tile_Image.url} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default SimplySwitch
