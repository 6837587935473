import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import AddressSearch from "react-loqate"
import InputField from "./elements/input"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import RadioField from "./elements/radio"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { postFormData } from "./api/Api"
import $ from "jquery"
import axios from "axios"
import * as qs from "query-string"
const AddressSearchInput = props => {
  return (
    <div className="custom-float">
      <label>Address*</label>
      <input
        name="address"
        placeholder=""
        autocomplete="off"
        type="text"
        id="address"
        class="form-control"
        {...props}
        value={props.addressLabel}
        required="true"
      />
    </div>
  )
}
function MySimpleForm(props) {
  const [validated, setValidated] = useState(false)
  const [enqtype, setenqtype] = useState()
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)
  const [radio, setRadio] = useState("I need to sell or let my property to buy")
  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()

  const fields = [
    {
      element: "config",
      formname: "Register With Us",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text:
        "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "simple_user_buy",
      email_temp_admin: "simple_admin_buy",
      email_subject_user: "Let’s talk",
      email_subject_admin: "Let’s talk",
      email_server_func: "enquiry",
      event_tracking: "contact",
      page_url: "/general-enquiry",
    },
    {
      grpmd: "12",
      label: "First Name",
      placeholder: "",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Last Name",
      placeholder: "",
      name: "lname",
      type: "text",
      element: "input",
      required: true,
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Telephone Number",
      placeholder: "",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Address",
      placeholder: "Address",
      name: "address",
      type: "text",
      element: "address",
      required: true,
      // patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",
    },
    //   {
    //     grpmd: "12",
    //     label: "Message",
    //     placeholder: "Write your message here...",
    //     name: "message",
    //     element: "textarea",
    //     class: "mb-4",
    //     rows:"3",
    //     labelClass: "content_b-18"
    //   },
    {
      grpmd: "12",
      title: "Which of these best describes your current position?",
      label: "I need to sell or let my property to buy",
      placeholder: "I need to sell or let my property to buy",
      name: "enquirytype",
      value: "I need to sell or let my property to buy",
      type: "radio",
      element: "radio",
      checked: "checked",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Rady to go, nothing to sell or let",
      placeholder: "Ready to go, nothing to sell or let",
      value: "Ready to go, nothing to sell or let",
      name: "enquirytype",
      type: "radio",
      element: "radio",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
      lastchild: "last-child",
    },
    {
      grpmd: "12",
      name: "Let’s Talk",
      type: "submit",
      element: "button",
      value: "submit",
      class: "w-md-100 mtop-btn  btn btn-yellow btn",
      labelClass: "content_b-18",
    },
    {
      text:
        'By clicking the above button I agree to the <a href="/terms-and-conditions/" className="content-link">Terms & Conditions</a>',
      element: "html",
      class: "frmbtmtext terms",
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }
  const getRadio = event => {
    event.target.value = event.target.value.trimStart()
    setRadio(event.target.value)
    setenqtype(event.target.value)
  }
  useEffect(() => {
    $("#enquirytypechecked").attr("checked", true)

    if (token !== "") {
      const processFromData = async () => {
        formvalues["g-recaptcha-response"] = token
        let formData = new FormData()
        formvalues["extra"] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name,
        })

        formvalues["name"] = formvalues.name
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin
        formData.append("data", JSON.stringify(formvalues))

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
          // lets send mail
          //   const axiosOptions_email = {
          //     url: '/.netlify/functions/server/'+fields[0].email_server_func,
          //     method: "post",
          //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //     data: qs.stringify(formvalues),
          //   }

          //   axios(axiosOptions_email)
          //     .then(response => {
          //       console.log('mail sent!')
          //     })
          //     .catch(err =>
          //       console.log(err)
          //     );
          //   });
        })
        //   formvalues['g-recaptcha-response'] = token;

        //   const axiosOptions_netlify = {
        //     url: fields[0].page_url,
        //     method: "post",
        //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //     data: qs.stringify(formvalues),
        //   }

        //   axios(axiosOptions_netlify)
        //     .then(response => {
        //       console.log('data stored')
        //     })
        //     .catch(err =>
        //       console.log(err)
        //     );
        const url = typeof window !== "undefined" ? window.location.href : ""
        if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
          fields[0].formname = "Get mortgage help"
        }
        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + fields[0].event_tracking,
          formId: "form-" + fields[0].event_tracking,
          formName: fields[0].formname,
          formLabel: radio,
        })

        setShowerror(false)
        setThankyou(true)
        myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleAddress = address => {
    document.getElementById("address").value = address.Label.replace(
      /\n/g,
      ", "
    )
    document.getElementById("addressBuildingName").value = address.BuildingName
    document.getElementById("addressBuildingNumber").value =
      address.BuildingNumber
    document.getElementById("addressPostalCode").value = address.PostalCode
    document.getElementById("addressSubBuilding").value = address.SubBuilding
    document.getElementById("addressStreet").value = address.Street
    $(".address-group").removeClass("field-invalid")
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    var selectedaddress = $("input[name=address]").val()
    $("input[name=selected_address]").val(selectedaddress)

    setenqtype($("input[name=enquirytype]:checked").val())
    $("input[name=enquirytype]").val("I need to sell or let my property to buy")
    if (enqtype) {
      $("input[name=enquirytype]").val(enqtype)
    }
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      event.preventDefault()

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? enqtype
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token

      setFormvalues(json)

      recaptchaRef.current.execute()

      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
    }
  }

  return (
    <div className="form stbform">
      <div ref={myRef} />
      <input
        id="addressBuildingName"
        type="hidden"
        name="addressBuildingName"
      />
      <input
        id="addressBuildingNumber"
        type="hidden"
        name="addressBuildingNumber"
      />
      <input id="addressPostalCode" type="hidden" name="addressPostalCode" />
      <input id="addressSubBuilding" type="hidden" name="addressSubBuilding" />
      <input id="addressStreet" type="hidden" name="addressStreet" />
      {showerror && (
        <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}

      {showthankyou && (
        <div className="alert-success">
          <p>{fields[0].success_text}</p>
        </div>
      )}

      <Form
        className="contact-form"
        name={fields[0].formname}
        action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input
          type="hidden"
          name="apfEmail"
          value={process.env.GATSBY_SN_SALES}
        />
        <input type="hidden" name="bot-field" />
        {fields.map((field, index) => {
          if ("input" === field.element) {
            return (
              <InputField
                name={field.name}
                grpmd={field.grpmd}
                label={field.label}
                ref={field.ref}
                type={field.type}
                fieldClass={field.class}
                placeholder={field.placeholder}
                labelClass={field.labelClass}
                required={field.required}
                key={`${field.element}~${index}`}
                pattern={field.patternchk}
                handlechange={handlechange}
              />
            )
          }
          if ("select" === field.element) {
            return (
              <SelectField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                required={field.required}
                fieldClass={field.class}
                placeholder={field.placeholder}
                values={field.values}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
                componentprops={props}
              />
            )
          }
          if ("textarea" === field.element) {
            return (
              <TextAreaField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                rows={field.rows}
                fieldClass={field.class}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("checkbox" === field.element) {
            return (
              <CheckboxField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("address" === field.element) {
            return (
              <Form.Group className="adress-book-field">
                <div className="addon-box">
                  <AddressSearch
                    locale="en_GB"
                    apiKey={process.env.GATSBY_LOQATE_API_KEY}
                    countries={["GB"]}
                    components={{ Input: AddressSearchInput }}
                    className="address-search-box"
                    inputClassname="address form-control"
                    listClassname="address-options"
                    listItemClassname="address-option"
                    onSelect={address => handleAddress(address)}
                  />
                </div>
              </Form.Group>
            )
          }
          if ("radio" === field.element) {
            return (
              <RadioField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                checked={field.checked}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={getRadio}
                lastchild={field.lastchild}
                title={field.title}
              />
            )
          }
          if ("html" === field.element) {
            return (
              <HtmlBox
                text={field.text}
                fieldClass={field.class}
                key={`${field.element}~${index}`}
              />
            )
          }
          if ("captcha" === field.element) {
            return (
              <ReCaptchaBox
                fieldClass={field.class}
                captRef={field.captchaRef}
                key={`${field.element}~${index}`}
                handleonVerify={handleonVerify}
              />
            )
          }
          if ("button" === field.element) {
            return (
              <ButtonField
                name={field.name}
                fieldClass={field.class}
                type={field.type}
                value={field.value}
                key={`${field.element}~${index}`}
              />
            )
          }
        })}
      </Form>
    </div>
  )
}

const MySimpleFormPage = props => (
  <MySimpleForm
    to_email_id={props.to_email_id}
    Page_CSS_Class={props.Page_CSS_Class}
  />
)

export default MySimpleFormPage
