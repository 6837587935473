import HTMLReactParser from "html-react-parser"
import React from "react"
import { Container } from "react-bootstrap"
import { useLocation } from "@reach/router"
import GetCTA from "../getcta"
import "./TileBlockv2.scss"

const TileBlockv2 = ({ Module }) => {
  const location = useLocation()
  if (!Module) return null

  const { Title, Content, Cta_Link, Tile_Image, Cta_Label } = Module
  return (
    <div className="TileBlockv2_wrapper" id="health-check">
      <Container className="TileBlockv2_container">
        <div className="left-section">
          <h2 className="title">{Title}</h2>
          {Content && <div className="content">{HTMLReactParser(Content)}</div>}
          {(Cta_Link !== null) ?
            <GetCTA
              link={Cta_Link?.Primary_URL}
              class={`btn btn-yellow`}
              Label={Cta_Label}
            />
            :
            (location?.pathname === "/property-services/landlords/" &&
              <a class={`btn btn-yellow`} href='https://calendly.com/lljazz/book-a-property-health-check' target="_blank">{Cta_Label}</a>
            )
          }
        </div>
        <div className="right-section">
          <div className="tile-image">
            <img src={Tile_Image.url} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TileBlockv2
