import { Link } from 'gatsby';
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card, Tabs, Tab, } from "react-bootstrap";
import "./CompanyInformation.scss";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import ReactMarkdown from "react-markdown/with-html";
import {OfficeBaseURL} from '../../utils'
import $ from 'jquery'

const CompanyInformation = (props) => {
  useEffect(() => {
    $('.scrollform').click(function(){
      var element = document.getElementById("scrollbox");
      element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    });
  });

    const GET_OFFICE=  gql`
    query officedetails ($ID: String!){   
        ourOffices(where:{id: $ID}){
            URL
            Office_Location
            Office_Phone
            Office_Email
            Office_Address
            Opening_Hours       
      } 
    }
    `;
 const location = useLocation();
 const searchParams = parse(location.search) // => {init: "true"}
 var officeid = searchParams.oid
 const { loading, error, data } = useQuery(GET_OFFICE, {variables: { ID: officeid}});

 if (loading) return <p className="d-none">Loading ...</p>;

 

  return (
    <React.Fragment>
    <div id="scrollbox" />
    {officeid && data.ourOffices.map((office) => {
        //$('.office-details-wrapper #contactform input[name="to_email_id"]').val(office.Office_Email);
        return<>
      <div className="company-info">
        <div className="contact-details">
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
        <div className="info-block">
          <div className="icon-block">
            <i className="icon-phone-yellow-m"></i>
          </div>
          <div className="info-block-dt">
            <span className="info-block-head">Telephone</span>
            <span className="info-content">
                <a href={`tel:${office.Office_Phone}`}className="btn-text-link">{office.Office_Phone}</a>
                </span>
          </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
        <div className="info-block">
          <div className="icon-block">
            <i className="icon-email-yellow-m"></i>
          </div>
          <div className="info-block-dt">
            <span className="info-block-head">Email</span>
            <span className="info-content"><a href="javascript:;" className="scrollform btn-text-link">{office.Office_Email}</a></span>
          </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
        <div className="info-block mbot-xs">
          <div className="icon-block">
            <i className="icon-map-marker-yellow-m"></i>
          </div>
          <div className="info-block-dt address">
            <span className="info-block-head">Address</span>
            <span className="info-content">{office.Office_Address}</span>
            <span className="info-content"><Link to={`${OfficeBaseURL}/${office.URL}#officesinglemap`} className=" border-bottom-yellow">View on a Map</Link></span>
          </div>
        </div>
        </ScrollAnimation>
        </div>
        <div className="company-timing-block">
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
          <div className="info-block">
          <div className="icon-block">
            <i className="icon-clock-yellow-m"></i>
          </div>
          <div className="info-block-dt address">
            <span className="info-block-head">Opening Hours</span>
            <ReactMarkdown source={office.Opening_Hours} escapeHtml={false}/>
          </div>
        </div>
        </ScrollAnimation>
        
        </div>
      </div>
      </>
    })}
    </React.Fragment>
  );
};
export default CompanyInformation;
