import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import RadioField from "./elements/radio"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { postFormData } from "./api/Api"
import ValuationSteps from "../../Components/ValuationSteps/Steps/Valuation-Steps"
import axios from "axios"
import * as qs from "query-string"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "@StarberryUtils"
import $ from "jquery"
import { navigate } from "gatsby"
function MySimpleForm(props) {
  const [instValStep, setinstValStep] = useState(false)

  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)

  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()

  const fields = [
    {
      element: "config",
      formname: "Valuation",
      form_type: "valuation",
      error_text: "Highlighted fields are required",
      success_text:
        "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "valuation_user",
      email_temp_admin: "valuation_admin",
      email_subject_user: props.valuationtype.replaceAll("_", " ") + " ",
      email_subject_admin: props.valuationtype.replaceAll("_", " ") + " ",
      email_server_func: "valuation",
      event_tracking: "valuation",
      page_url: "/general-enquiry",
    },
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      class: "w-md-100 mtop-btn  btn btn-yellow d-none",
      labelClass: "content_b-18",
    },
    {
      text:
        'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms & Conditions</a> and <a href="/privacy-and-cookie-policy/">Privacy &amp; Cookie Policy</a>',
      element: "html",
      class: "terms d-none",
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  let valuation_type = ""
  if (props.valuationtype === "Home_Visit_Valuation") {
    valuation_type = "Home Visit Valuation"
  } else if (props.valuationtype === "Instant_Valuation") {
    valuation_type = "Instant Valuation"
  } else {
    valuation_type = "Virtual Valuation"
  }

  useEffect(() => {
    if (token !== "") {
      const processFromData = async () => {
        setinstValStep(true)
        formvalues["g-recaptcha-response"] = token
        let formData = new FormData()
        formvalues["extra"] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name,
          conversation: formvalues.conversation,
        })

        formvalues["valuation_type"] = valuation_type
        formvalues["conversation"] = formvalues.conversation
        formvalues["name"] = formvalues.name
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin
        formData.append("data", JSON.stringify(formvalues));
        navigate("thankyou")
        setTimeout(() => {
          formvalues["sales_min_val"] = localStorage.getItem("sales_min_val");
          formvalues["sales_avg_val"] = localStorage.getItem("sales_avg_val");
          formvalues["sales_max_val"] = localStorage.getItem("sales_max_val");

          formvalues["rental_min_val"] = localStorage.getItem("rental_min_val");
          formvalues["rental_avg_val"] = localStorage.getItem("rental_avg_val");
          formvalues["rental_max_val"] = localStorage.getItem("rental_max_val");
          console.log("formvalues",formvalues);
          localStorage.setItem('sales_min_val', "");
          localStorage.setItem('sales_avg_val', "");
          localStorage.setItem('sales_max_val', "");

          localStorage.setItem('rental_min_val', "");
          localStorage.setItem('rental_avg_val', "");
          localStorage.setItem('rental_max_val', "");
          postFormData(formData).then(async apiRes => {
            window.grecaptcha.reset()
            await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
              method: `POST`,
              mode: "no-cors",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: qs.stringify(formvalues),
            })

            // lets send mail
            // const axiosOptions_email = {
            //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
            //   method: "post",
            //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
            //   data: qs.stringify(formvalues),
            // }

            // axios(axiosOptions_email)
            //   .then(response => {
            //     console.log('mail sent!')
            //   })
            //   .catch(err =>
            //     console.log(err)
            //   );
          })
        }, "7000")
        // formvalues['g-recaptcha-response'] = token;

        // const axiosOptions_netlify = {
        //   url: fields[0].page_url,
        //   method: "post",
        //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //   data: qs.stringify(formvalues),
        // }

        // axios(axiosOptions_netlify)
        //   .then(response => {
        //     console.log('data stored')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );

        var typesearch =
          formvalues.enquirytype === "selling"
            ? "Selling"
            : formvalues.enquirytype === "letting"
            ? "Letting"
            : "Selling and Letting"
        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + fields[0].event_tracking,
          formId: "form-" + fields[0].event_tracking,
          formName: props.valuationtype.replaceAll("_", " "),
          formLabel:
            props.valuationtype.replaceAll("_", " ") + " " + typesearch,
        })

        setShowerror(false)
        setThankyou(true)
        $(".valuationstep2").hide()
        $(".valuationintrostep3").hide()
        $(".dateandtimesection.dateandtimesectionintro").hide()
        if (props.valuationtype === "Instant_Valuation") {
          $(".valuationstep2").hide()
          if (formvalues.enquirytype === "letting") {
            $(".instantvaluationsection.instantvaluationintro h4").html(
              "Rental Values"
            )
          } else if (formvalues.enquirytype === "selling") {
            $(".instantvaluationsection.instantvaluationintro h4").html(
              "Selling Values"
            )
          } else {
            $(".instantvaluationsection.instantvaluationintro h4").html(
              "" + formvalues.enquirytype + " Values"
            )
          }

          $(".instantvaluationsection.instantvaluationintro h1").html(
            "Thanks " +
              formvalues.name +
              ". Here are your property's estimated " +
              formvalues.enquirytype +
              " values."
          )
          $(".instantvaluationsection").show()
          $(".stepsheader .gobackVal").hide()
          $(".stepsheader .gobackVal2").css("display", "flex")
          $(".stepsheader").addClass("stepinstantvaluation")
          $(".stepsheader").removeClass("stepinstantdate")
          $(".stepsheader").removeClass("steponegoback")
          $(".steps-error").html("")
          $(".address-group").removeClass("field-invalid")
          $(".valuationform form").removeClass(" was-validated")

          $(".valuationthankyou.valuationintrothankyou").hide()
        } else {
          $(".valuationthankyou.valuationintrothankyou").show()
        }

        $(".menudesktop .menubar-desktop .mainSubmenu").show()
        $(".menudesktop .stepsheader").hide()
        myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    var selectedaddress = $("input[name=address]").val()
    $("input[name=selected_address]").val(selectedaddress)
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      event.preventDefault()

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token

      setFormvalues(json)

      recaptchaRef.current.execute()

      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
    }
  }

  return (
    <div className={`form stbform ${props.valuationtype}`} id="contactform">
      <div ref={myRef} />
      <div className="steps-error"></div>
      {showerror && (
        <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}

      {showthankyou && props.valuationtype !== "Instant_Valuation" && (
        <div className="valuation-success">
          <div className="tab-box tab-box2 card-box">
            <div className="tab-box-form">
              <div className="cardbox-content cardbox">
                <ScrollAnimation
                  animateOnce={true}
                  duration={0.5}
                  animateIn="fadeInDown"
                >
                  <p>
                    Your request has been sent to our team and we will contact
                    you shortly to confirm your appointment and explain the
                    valuation process in more detail.
                  </p>
                </ScrollAnimation>
                <div className="what-to-do-text">
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeInUp"
                  >
                    <h6>What to do next?</h6>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                  </ScrollAnimation>
                </div>
                <div className="tab-outline-buttons btn-outline-group2">
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeInUp"
                  >
                    <Link
                      className="btn btn-outline"
                      to="/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london"
                    >
                      <span>Find a Property</span>
                    </Link>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeInUp"
                  >
                    <Link className="btn btn-outline mb-0" to="/contact">
                      <span>Contact Us</span>
                    </Link>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Form
        className="contact-form form-w-label"
        name={fields[0].formname}
        action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input
          type="hidden"
          name="valuation_type"
          value={props.valuationtype.replaceAll("_", " ")}
        />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="to_email_cc" value={props.to_email_cc} />
        <input type="hidden" name="apfEmail" value="" />
        <input type="hidden" name="bot-field" />

        <input type="hidden" name="sales_min_val" id="sales_min_val" value="" />
        <input type="hidden" name="sales_avg_val" id="sales_avg_val" value="" />
        <input type="hidden" name="sales_max_val" id="sales_max_val" value="" />

        <input
          type="hidden"
          name="rental_min_val"
          id="rental_min_val"
          value=""
        />
        <input
          type="hidden"
          name="rental_avg_val"
          id="rental_avg_val"
          value=""
        />
        <input
          type="hidden"
          name="rental_max_val"
          id="rental_max_val"
          value=""
        />
        <ValuationSteps
          token={token}
          instValStep={instValStep}
          setinstValStep={setinstValStep}
          valuationtype={props.valuationtype}
        />
        {fields.map((field, index) => {
          if ("input" === field.element) {
            return (
              <InputField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                type={field.type}
                fieldClass={field.class}
                placeholder={field.placeholder}
                label={field.label}
                labelClass={field.labelClass}
                required={field.required}
                key={`${field.element}~${index}`}
                pattern={field.patternchk}
                handlechange={handlechange}
              />
            )
          }
          if ("select" === field.element) {
            return (
              <SelectField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                required={field.required}
                fieldClass={field.class}
                placeholder={field.placeholder}
                values={field.values}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
                componentprops={props}
              />
            )
          }
          if ("textarea" === field.element) {
            return (
              <TextAreaField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                rows={field.rows}
                fieldClass={field.class}
                label={field.label}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("checkbox" === field.element) {
            return (
              <CheckboxField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("radio" === field.element) {
            return (
              <RadioField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                checked={field.checked}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
                lastchild={field.lastchild}
              />
            )
          }
          if ("html" === field.element) {
            return (
              <HtmlBox
                text={field.text}
                fieldClass={field.class}
                key={`${field.element}~${index}`}
              />
            )
          }
          if ("captcha" === field.element) {
            return (
              <ReCaptchaBox
                fieldClass={field.class}
                captRef={field.captchaRef}
                key={`${field.element}~${index}`}
                handleonVerify={handleonVerify}
              />
            )
          }
          if ("button" === field.element) {
            return (
              <ButtonField
                name={field.name}
                fieldClass={field.class}
                type={field.type}
                value={field.value}
                key={`${field.element}~${index}`}
              />
            )
          }
        })}
      </Form>
    </div>
  )
}

const ContactForm = props => (
  <MySimpleForm
    to_email_id={props.to_email_id}
    to_email_cc={props.to_email_cc}
    valuationtype={props.valuationtype}
  />
)

export default ContactForm