import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import CommonTemplate from "./common-template"
import NewsDetails from "./news-details"
import CaseStudyDetails from "./casestudy-details"
import CommunityDetails from "./community-details"
import { useMatch } from "@reach/router"
import FormTemplate from "./form-template"
import MarketingTemplate from "./marketing-template"
import FullpageTemplate from "./fullpage-template"
import OurValuesTemplate from "./ourvalues-template"
import ValuationPage from "../Components/ValuationSteps/ValuationPage"
import SEO from "../Components/Seo/seo"
import UserContext from "../Components/Usercontext"

const DefaultTemplate = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [valpalValues, setValpalValues] = useState("")
  const newscat = useMatch("/blog/:item/")
  const newsdetail = useMatch("/blog/:item/:slug")
  const communitycat = useMatch("/purpose/communities/:item/")
  const communitydetail = useMatch("/purpose/communities/:item/:slug")
  const casestudydetail = useMatch(
    "/property-services/new-homes/case-studies/:item/"
  )
  const GQLPage = props.data.glstrapi?.article
  const GQLModules = props.data.glstrapi?.article?.Modules
  const GQLAreaguides = props.data.glstrapi?.areaguides
  const GQLOffices = props.data.glstrapi?.ourOffices
  const GQLClients = props.data.glstrapi?.ourClients
  if (GQLPage.Select_Template === "Marketing_Page") {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
        />
        <MarketingTemplate GQLPage={GQLPage} GQLModules={GQLModules} />
      </>
    )
  }
  if (GQLPage.Select_Template === "Fullpage_Template") {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
        />
        <FullpageTemplate
          GQLPage={GQLPage}
          GQLModules={GQLModules}
          path={props.path}
        />
      </>
    )
  }
  if (GQLPage.Select_Template === "OurValues_Page") {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
        />
        <OurValuesTemplate GQLModules={GQLModules} />
      </>
    )
  }
  if (GQLPage.Select_Template === "Valuation_Page") {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
        />
        <UserContext.Provider value={{ valpalValues, setValpalValues }}>
          <ValuationPage GQLPage={GQLPage} GQLModules={GQLModules} />
        </UserContext.Provider>
      </>
    )
  }
  if (communitydetail != null) {
    return (
      <>
        <CommunityDetails
          URL={communitydetail.slug}
          newsCat={communitydetail.item}
        />
      </>
    )
  }
  if (newsdetail != null) {
    return (
      <>
        <NewsDetails URL={newsdetail.slug} newsCat={newsdetail.item} />
      </>
    )
  } else if (casestudydetail != null) {
    return (
      <>
        <CaseStudyDetails URL={casestudydetail.item} />
      </>
    )
  } else if (GQLPage.Select_Template === "Form_Template") {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
        />
        <FormTemplate
          GQLPage={GQLPage}
          GQLModules={GQLModules}
          path={props.path}
        />
      </>
    )
  } else {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
        />
        <CommonTemplate
          GQLPage={GQLPage}
          GQLAreaguides={GQLAreaguides}
          GQLOffices={GQLOffices}
          GQLClients={GQLClients}
          GQLModules={GQLModules}
          ifnewscat={newscat != null ? newscat.item : ""}
          ifcommunitycat={communitycat != null ? communitycat.item : ""}
        />
      </>
    )
  }
}

export default DefaultTemplate

export const pageQuery = graphql`
  query DefaultQuery($articleId: ID!) {
    glstrapi {
      ourClients {
        Client_Name
        Client_URL
        Client_Logo {
          url
        }
      }
      areaguides(sort: "Sort:ASC") {
        id
        Name
        URL
        Area_Caption
        Area_Postcode
        Youtube_Video_URL
        Select_Areaguide_Location
        Tile_Image {
          alternativeText
          url
        }
        imagetransforms
        Sort
      }
      ourOffices {
        URL
        id
        Name
        Office_Phone
        Office_Location
        Youtube_Video_URL
        Office_Address
        Office_Email
        Office_Tile_Image {
          url
          alternativeText
        }
        imagetransforms
      }
      article(id: $articleId, publicationState: LIVE) {
        Title
        Page_Caption
        Select_Template
        Meta_Title
        Meta_Description
        Page_CSS_Class
        Select_Popular_Search
        Header_Style
        Select_Footer
        Properties_Type
        Modules {
          ... on GLSTRAPI_ComponentComponentsFullpageSlider {
            __typename
            id
            Fullpage_Slide {
              Grid_Type
              Tails {
                Content
                Title
                Video_Url
                Image {
                  alternativeText
                  url
                }
              }
              Slide_Label
              Content
              Cta_Label
              Cta_Url {
                Primary_URL
              }
              Small_Heading
              Title
              Type
              Video_Url
              Upload_Video {
                url
              }
              Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    gatsbyImageData(
                      formats: AUTO
                      width: 1200
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsIntro {
            __typename
            id
            Intro_Heading
            Intro_Heading_With_Font
            Intro_Content
            Intro_CTA {
              CTA_Label
              CTA_Link {
                Primary_URL
              }
              Custom_Link
            }
            Show_Reviews
            SubPages {
              Sub_Page_Custom_Link
              Sub_Page_Label
              Sub_Page_Link {
                Primary_URL
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsBanner {
            __typename
            id
            Banner_Class
            Banner_Play_Text
            Banner_Upload_Video {
              url
            }
            Mobile_Banner {
              url
              alternativeText
            }
            Banner_Cta_Urls {
              Btn_Label_2
              Btn_Label_1
              Hash_Tag_2
              Hash_Tag_1
              Btn_Url_2 {
                Primary_URL
              }
              Btn_Url_1 {
                Primary_URL
              }
            }
            Banner_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    width: 1800
                    placeholder: BLURRED
                    quality: 100
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                  fluid(quality: 90, maxWidth: 1800, maxHeight: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Banner_Content
            Youtube_Video_URL
          }
          ... on GLSTRAPI_ComponentComponentsBlackBoxContent {
            __typename
            id
            Black_Box_Description
          }
          ... on GLSTRAPI_ComponentComponentsModules {
            id
            __typename
            Select_Module
            Google_Review_Title
          }
          ... on GLSTRAPI_ComponentComponentsForms {
            id
            Select_Form
          }
          ... on GLSTRAPI_ComponentComponentsTwoCloumnContent {
            __typename
            Left_Column_Content
            Right_Column_Content
          }
          ... on GLSTRAPI_ComponentComponentsFaq {
            __typename
            FAQ_Module_Heading
            FAQ_Module_Caption
            FAQ_Listing {
              id
              FAQ_Title
              FAQ_Description
            }
          }
          ... on GLSTRAPI_ComponentComponentsStaticBanner {
            __typename
            id
            Title
            Content
            Cta_Link {
              Primary_URL
            }
            Cta_Label
            Tile_Image {
              url
            }
            Video_Url
            Show_Review
            Media_Position
            Bg_color
            layout
          }
          ... on GLSTRAPI_ComponentComponentsContentSlider {
            __typename
            id
            title
            description
            blocks {
              title
              id
              description
              cta_link {
                Label
                Link_Type
                Secondary_URL
                Primary_URL
              }
              cta_label
              cta_custom
            }
          }
          ... on GLSTRAPI_ComponentComponentsShapeBlocks {
            __typename
            id
            title
            description
            blocks {
              id
              title
              description
              cta_label
              cta_link {
                Primary_URL
              }
              cta_custom
            }
          }
          ... on GLSTRAPI_ComponentComponentsInternalServiceBlock {
            id
            __typename
            Title_Content
            Title
            service_body_color
            Internal_Services {
              Block_Title
              Block_Content
              CTA_Label
              CTA_Link {
                Primary_URL
              }
              Tile_Image {
                url
                alternativeText
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsPackageBlocks {
            id
            __typename
            Title_Content
            Package_Block_Content {
              Percentage
              Title
              recommended
              Package_Content
              CTA_Label
              CTA_Link {
                Primary_URL
              }
            }
            Bottom_Text
            Bottom_CTA_Label
            Bottom_CTA_Link {
              Primary_URL
            }
          }
          ... on GLSTRAPI_ComponentComponentsBlockSlider {
            id
            Bg_Color
            __typename
            CTA_Label
            CTA_Link {
              Primary_URL
            }
            Calculator_Content
            Calculator_Term_Condition
            Content
            Multiplier
          }
          ... on GLSTRAPI_ComponentComponentsStores {
            id
            __typename
            Heading
            Background_Color
            Success_Stores {
              Content
              Sort
              Title
              id
              Image {
                url
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsDynamicContents {
            id
            __typename
            Tite_Content
            List_Component {
              Percentage
              Title
            }
          }
          ... on GLSTRAPI_ComponentComponentsLeadingPortals {
            id
            __typename
            Portals {
              Name
              Image {
                url
              }
            }
            Title_Content
          }

          ... on GLSTRAPI_ComponentComponentsGetStartedBlock {
            id
            __typename
            Content
            Cta_Label
            Cta_Link {
              Primary_URL
            }
          }

          ... on GLSTRAPI_ComponentComponentsConfidentServiceBlock {
            id
            __typename
            Title_Content
            Service_Block_Content {
              Cta_Label
              Description_Content
              Image {
                url
              }
              Cta_Link {
                Primary_URL
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsTeamBlock {
            id
            __typename
            Content
            Teams_Collection {
              id
              Name
              Designation
              Nick_Name
              Staff_Image {
                url
                alternativeText
              }
              imagetransforms
            }
          }
          ... on GLSTRAPI_ComponentComponentsStaticContent {
            __typename
            Static_Content_Intro
            Static_Content_Full
          }
          ... on GLSTRAPI_ComponentComponentsLandingTilesBig {
            __typename
            id
            Landing_Tiles {
              Tile_CTA_Label
              Tile_Caption
              Tile_Title
              Tile_Phone_Number
              Tile_Email_Address
              Tile_Custom_Link
              Tile_Intsagram_Link
              Tile_Twitter_Link
              Tile_Facebook_Link
              Tile_Youtube_Link
              Tile_Linkedin_Link
              Tik_Tok_Link
              Tile_Link {
                Primary_URL
              }
              Tile_Big_Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsPlans {
            __typename
            id
            Plan_Details {
              Plan_Caption
              Plan_Cta_Label
              Plan_Highlight_Tag
              Plan_Percentage
              Plan_Title
              Plan_List {
                Plan_Name
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsServicesBlocks {
            __typename
            id
            Services_Title
            Services_Top_Heading
            Services_Details {
              Services_Title
              Services_Content
            }
          }
          ... on GLSTRAPI_ComponentComponentsGridImageSlider {
            id
            __typename
            Grid_Images {
              Grid_Image {
                url
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsMarketingSlides {
            __typename
            Marketing_Slider {
              Marketing_Slide_Caption
              Marketing_Slide_Content
              Marketing_Slide_Subheading
              Marketing_Slide_Title
              Marketing_Slide_Video_URL
              Next_Slide_Heading
              Bg_Video {
                url
              }
              Prev_Slide_Heading
              Marketing_Slide_Listing {
                Marketing_Slide_List_Title
              }
              Marketing_Slide_Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    gatsbyImageData(
                      formats: AUTO
                      width: 1200
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsValuationTileBlocks {
            id
            __typename
            Add_Valuation_Tile {
              Title
              Description
              CTA_Label
              Valuation_Type
            }
          }
          ... on GLSTRAPI_ComponentComponentsLeadingPortals {
            id
            __typename
            Portals {
              Name
              Image {
                url
              }
            }
            Title_Content
          }
          ... on GLSTRAPI_ComponentComponentsBlockSlider {
            id
            Bg_Color
            __typename
            CTA_Label
            CTA_Link {
              Primary_URL
            }
            Calculator_Content
            Calculator_Term_Condition
            Content
            Multiplier
          }
          ... on GLSTRAPI_ComponentComponentsStores {
            id
            __typename
            Heading
            Background_Color
            Success_Stores {
              Content
              Sort
              Title
              id
              Image {
                url
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsTeamBlock {
            id
            __typename
            Content
            Teams_Collection {
              id
              Name
              Designation
              Nick_Name
              Staff_Image {
                url
                alternativeText
              }
              imagetransforms
            }
          }
          ... on GLSTRAPI_ComponentComponentsGetStartedBlock {
            id
            __typename
            Content
            Cta_Label
            Cta_Link {
              Primary_URL
            }
          }
          ... on GLSTRAPI_ComponentComponentsOurValuesSlider {
            id
            Our_Values_Slides {
              Our_Values_CTA1_Label
              Our_Values_CTA1_Link {
                Primary_URL
              }
              Our_Values_CTA2_Label
              Our_Values_CTA2_Link {
                Primary_URL
              }
              Our_Values_Slide_Caption
              Our_Values_Slide_Description
              Our_Values_Slide_Title
              Our_Values_Slide_Mobile_Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      width: 1200
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
              Our_Values_Slide_Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      width: 1200
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
