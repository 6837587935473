import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import "./CalculatorSlider.scss"
import Slider from "react-rangeslider"
import ScrollAnimation from "react-animate-on-scroll"
import "react-rangeslider/lib/index.css"
import GetCTA from "../getcta"
import Minus from "../../images/minus.png"
import Plus from "../../images/plus.png"
import { useLocation } from "@reach/router"
import RadioField from "../forms/elements/radio"
import $ from "jquery";
import GreenCheckMark from "../../images/check-green.svg"
import XImage from "../../images/X.png"

export const CalculatorSlider = props => {
  const location = useLocation()
  const [propertyValue, SetPropertyValue] = useState(2482000)
  const [radio, setRadio] = useState()
  const [selectNum, setSelectNum] = useState()
  const handleChangePropertyValue = value => {
    SetPropertyValue(value)
  }

  const handleMinus = () => {
    if (propertyValue > 0) {
      SetPropertyValue(propertyValue - 1000)
    }
  }

  const handlePlus = () => {
    if (propertyValue < 5000000) {
      SetPropertyValue(propertyValue + 1000)
    }
  }


  var resultValue = propertyValue * props.Module.Multiplier - propertyValue
  const getRadio = event => {
    event.target.value = event.target.value.trimStart()
    setRadio(event.target.value)
    setSelectNum($("input[name=selectnumber]:checked").val())
    $("input[name=selectnumber]").val("25")
    if (selectNum) {
      $("input[name=selectnumber]").val(selectNum)
    }
  }
  const fields = [
    {
      grpmd: "12",
      label: "25",
      placeholder: "25",
      value: "25",
      type: "radio",
      element: "radio",
      name: "selectnumber",
      checked: "checked",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    }, {
      grpmd: "12",
      label: "170",
      placeholder: "170",
      value: "170",
      type: "radio",
      element: "radio",
      name: "selectnumber",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    }, {
      grpmd: "12",
      label: "300+",
      placeholder: "300+",
      value: "300+",
      type: "radio",
      element: "radio",
      name: "selectnumber",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
      lastchild: "last-child",
    },
  ]
  return (
    <div className={`calculator_slider_wrapper ${props.Module.Bg_Color === "light" ? "bgLight" : ""}`}>
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
        <Container className="calculator_slider_container">
          <div className="calculator_slider_content">
            <div className="content">
              {props.Module.Content
                ? HTMLReactParser(props.Module.Content)
                : null}
            </div>
            <div className="slider_calculator_btn">
              <GetCTA
                link={
                  props.Module.CTA_Link && props.Module.CTA_Link.Primary_URL
                }
                class="btn btn-outline"
                Label={props.Module.CTA_Label}
              />
            </div>
          </div>
          <div className="slider_calculator">
            <div className="calculator_content">
              {props.Module.Calculator_Content
                ? HTMLReactParser(props.Module.Calculator_Content)
                : null}
            </div>
            {(location.pathname === "/property-services/selling/" || location.pathname === "/property-services/sell/") ?
              <>
                <div className="calculator mortgage-slider-custom">
                <div className="slide-price">{`£${propertyValue.toLocaleString()}`}</div>
                  <div className="calculator_Block">
                    <img src={Minus} alt="minusImage" onClick={handleMinus} />                   
                      <Slider
                        min={0}
                        max={5000000}
                        value={propertyValue}
                        step={1000}
                        // handleLabel={`£${propertyValue.toLocaleString()}`}
                        onChange={handleChangePropertyValue}
                      />                   
                    <img src={Plus} alt="plusImage" onClick={handlePlus} />
                  </div>
                </div>
                <div>
                  <h1 className="calc_result">
                    £{resultValue.toLocaleString()}*
                  </h1>
                  <p className="terms-contitions">

                    {props.Module.Calculator_Term_Condition}
                  </p>
                </div>
              </>
              :
              <div className="select-checkbox-container">
                {fields.map((field, index) => {
                  if ("radio" === field.element) {
                    return (
                      <RadioField
                        name={field.name}
                        ref={field.ref}
                        value={field.value}
                        fieldClass={field.class}
                        checked={field.checked}
                        placeholder={field.placeholder}
                        required={field.required}
                        key={`${field.name}~${index}`}
                        handlechange={getRadio}
                        lastchild={field.lastchild}
                      />
                    )
                  }
                })}
                <div className="ans-text">
                  {radio != undefined ?
                    <>
                      {radio == "300+" ?
                        <>
                          <img src={GreenCheckMark} alt="tickImage" />
                          <p><strong>Correct!</strong> We're here to help you navigate all of them.</p>
                        </>
                        : radio == "170" ? 
                        <>
                        <img src={XImage} alt="tickImage" className="wrong-img" />
                        <p><strong>Almost,</strong> that’s just the ones you can be fined for.</p>
                      </> :
                        <>
                          <img src={XImage} alt="tickImage" className="wrong-img" />
                          <p><strong>This is not correct!</strong> Please try again!</p>
                        </>
                      }
                    </>
                    : ""}
                </div>
              </div>
            }
          </div>
        </Container>
      </ScrollAnimation>
    </div>
  )
}
